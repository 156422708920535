import {connect} from 'react-redux'
import {profileFirstLastNameSelector, profileFirstNameSelector} from 'data/redux/selectors/userSelector';
import FullName from 'gui/components/fullName';

const mapStateToProps = (state, ownProps) => {
  const fullName = ownProps.firstName ? profileFirstNameSelector(state) : profileFirstLastNameSelector(state);
  return {
    fullName
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const FullNameContainer = connect(mapStateToProps, mapDispatchToProps)(FullName);

export default FullNameContainer;
