import React from "react";
import './LoggedOut.css';
import DealAndGoNavButtons
  from 'gui/pages/mainRouter/components/navigation/components/dealAndGoNavButtons/DealAndGoNavButtons';


interface ILoggedOutProps {

}

const LoggedOut = (props: ILoggedOutProps) => (
  <DealAndGoNavButtons/>
);
LoggedOut.defaultProps = {};
export default React.memo(LoggedOut);
