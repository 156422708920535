import Primus from 'primus-client';
import feathers from '@feathersjs/feathers';
import primusClient from '@feathersjs/primus-client';
import restClient from '@feathersjs/rest-client';

import reduxifyServices from 'feathers-redux';
import reduxifyAuthentication from 'feathers-reduxify-authentication-v4';
import auth from '@feathersjs/authentication-client'
import objectArrayTools from 'object-arrarify';
import {
  authenticationInitialized,
  authenticationUnInitialize,
  getForceOfflineLogout,
  getSignOutActionCreator,
  setEmailIsVerifiedState
} from 'data/redux/actions/authenticationActions';
import {completeTransaction} from 'data/redux/actions/transactionsActions';
import {setUserDetails} from 'data/redux/actions/usersActions';
import {userIdSelector} from 'data/redux/selectors/userSelector';
import {config} from 'data/utils/constants';

const isSearchEngine = () => /bot|googlebot|crawler|spider|robot|bingbot|yahoobot|yandexbot|crawling/i.test(navigator.userAgent);

const isWebsocketSupported = () => {
  const ws: any = (((window as any).WebSocket || (window as any).MozWebSocket));
  return !!ws;
};

const isLocalHost = ['localhost', '127.0.0.1'].includes(window.location.hostname);
const webSocketProtocol = `ws${window.location.protocol === 'https:' ? (isLocalHost ? '' : 's') : ''}:`;
const httpProtocol = window.location.protocol;
const webSocketConnectionString = isLocalHost ? `${webSocketProtocol}//${config.api_server.hostname || `${window.location.hostname}:3030`}` : `/api`;
export const httpConnectionString = isLocalHost ? `${httpProtocol}//${config.api_server.hostname || `${window.location.hostname}:3030`}` : `/api`;


// can be used with feathers (optional)
//Initialize the primary websocket client using primus
const app = feathers()
  .configure(auth({
    storage: window.localStorage, // store the token in localStorage and initially sign in with that
    storageKey: 'access-token',
    path: '/authentication',
  }))
  .hooks({
    error(context) {
      
      // context.error = null;
      return Promise.resolve();
    }
  });
let socket;
if (isWebsocketSupported() && !isSearchEngine()) {
  socket = new Primus(webSocketConnectionString, {
    protocol: webSocketProtocol,
    reconnect: {
      max: 10000 // Number: The max delay before we try to reconnect.
      , min: 500 // Number: The minimum delay before we try reconnect.
      , retries: 10000 // Number: How many times we should try to reconnect.
    }
  });
  app.configure(primusClient(socket, {
      timeout: 60000,
    })
  );
} else {
  app.configure(restClient(httpConnectionString).fetch(window.fetch));
}


export const feathersAuthentication = reduxifyAuthentication(app);

export const services = reduxifyServices(app, [
  'areas',
  'users',
  'airports',
  'airlines',
  'brandsoftheworld',
  'products',
  'orders',
  'places-autocomplete',
  'banks-autocomplete',
  'places-details',
  'companies-autocomplete',
  'companies',
  'transactions',
  'transaction-types',
  'prices',
  'product-types',
  'product-areas',
  'documents',
  'authManagement',
  'ip-2-location-country',
  'support',
  'message-editor',
  'email',
  'sms'
]);


const feathersReducers: any = objectArrayTools(services)
  .mapKeys((service, key) => key + "Service")
  .map((service, key) => service.reducer)
  .toNormalObject();

interface IListeningServiceConfig {
  name: string;
  types: ("created" | "updated" | "patched" | "removed")[];
}

/**
 * Attach auto listeners and dispatchers to a service
 * @param servicesToListen
 * @param store
 */
export const attachEventListeners = (servicesToListen: IListeningServiceConfig[], store) => {
  for (const serviceToListen of servicesToListen) {
    for (const type of serviceToListen.types) {
      app.service(serviceToListen.name).on(type, data => {
        const capitalizedServiceType = type.slice(0, 1).toUpperCase() + type.slice(1);
        store.dispatch(services[serviceToListen.name]["on" + capitalizedServiceType](data));
      });
    }
  }
  for (const type of ['created', 'removed']) {
    app.service('products').on(type, data => {
      // store.dispatch(getLoadUserProductsActionCreator(null));
    });
  }

  app.service('transactions').on('patched', data => {
    store.dispatch(completeTransaction(data));
  });
  app.service('users').on('patched', data => {
    if (data) {
      try {
        data.allowAdvertisementEmails = !!data.allowAdvertisementEmails;
        data.createdAt = new Date(data.createdAt);
        data.updatedAt = new Date(data.updatedAt);
      } catch (e) {
      }
      store.dispatch(setUserDetails(data));
    }
  });

  app.service('users').on('removed', data => {
    if (userIdSelector(store.getState()) === data.id) {
      store.dispatch(getForceOfflineLogout());
      window.location.reload();
    }
  });
  app.service('authManagement').on('created', data => {
    if (userIdSelector(store.getState()) === data.id && (!!data.isVerified) === true) {
      store.dispatch(setEmailIsVerifiedState({
        email: data.email,
        verified: true,
      }));
    }
  });


  /*
    app.service('prices').find({
      query: {
        $groupByAndCount: 'askingPrice',
      }
    })
      .then((data) => {

      });
   */
}

export const attachAuthenticationEvents = (store) => {
  const tryToAuthenticate = () => {
    // Sign in with the JWT currently in localStorage
    if (localStorage['access-token']) {
      store.dispatch(feathersAuthentication.authenticate())
        // .then(s => store.dispatch(feathersAuthentication2.authenticate()))
        .then(s => {
          store.dispatch(authenticationInitialized());
        })
        .catch(err => {
          
          store.dispatch(getSignOutActionCreator());
          store.dispatch(authenticationInitialized());
          
          return err;
        });
    } else {
      store.dispatch(authenticationInitialized());
    }
  };
  if (socket) {
    socket.on("connection", tryToAuthenticate);
    socket.on("open", tryToAuthenticate);
    socket.on("reconnected", () => {
      if (localStorage['access-token']) {
        app.reAuthenticate(true);
      }
    });
    socket.on("disconnection", () => {
      store.dispatch(authenticationUnInitialize());
    });

    socket.on("close", () => {
      store.dispatch(authenticationUnInitialize());
    });
  } else {
    tryToAuthenticate();
  }

}

export default feathersReducers;
