/**
 * Sends error handling middleware.
 */

const errorHandlerMiddleWare = store => next => action => {
  next(action);
  /*
  if (action && action.type && action.type.startsWith("SERVICES_")) {
    return next({type: action.type, payload: {}});
  } else if (action.payload && action.payload.promise) {
    return action.payload.promise
      .then(r => {
        next(action);
      })
      .catch(({type, name, message, code, className, data, hook, errors}) => {
        next(action);
      })
  } else return next(action)
*/

}

export default errorHandlerMiddleWare;

