import {roundTo} from 'round-to';
import percentage from 'calculate-percentages'
import XXH from 'xxhashjs';
import moment from 'moment';
import uniqWith from 'uniq-with';
import {formatTitle} from 'data/utils/shortcuts';

const getMiddleBetweenTwoNumbers = (min, max) => {
  return (min + ((max - min) / 2));
};

export const minPrice = 0;
export const maxPrice = 9999999;

export const moneyTransferCostPercent = 0;
export const commissionCostPercent = 12;

export const randomNumberBetween = (m1, m2) => {
  const min = Math.min(m1, m2);
  const max = Math.max(m1, m2) + 1;
  return Math.floor(Math.random() * (max - min) + min);
}

export const randomArrayValue = <T>(...choices: T[]): T => {
  if (choices.length > 0)
    return choices[randomNumberBetween(0, choices.length - 1)];
  return null;
}

export const calculateMinimum = (nameChange = 1) => {
  let backWards = Math.ceil((nameChange * 100) / (100 - (moneyTransferCostPercent + commissionCostPercent)));
  while (backWards - (nameChange + roundTo(percentage.of(moneyTransferCostPercent, backWards), 0) + roundTo(percentage.of(commissionCostPercent, backWards), 0)) > 0) {
    backWards--;
  }
  return backWards;
}

export const twoDigitsOfYear = (date = new Date()) => date.getFullYear().toString().substr(-2);

export const calculateMiddleAskingPrice = (askingPrice, minPossiblePrice) => Math.floor(getMiddleBetweenTwoNumbers(minPossiblePrice, askingPrice));
export const calculatePercentageOffAskingPrice = (askingPrice, p) => Math.floor(percentage.of(p, askingPrice));
export const calculateMoneyTransferCost = (askingPrice) => Math.max(roundTo(percentage.of(moneyTransferCostPercent, askingPrice), 0), 0);
export const calculateCommission = (askingPrice, _commissionCostPercent = commissionCostPercent) => Math.max(roundTo(percentage.of(_commissionCostPercent, askingPrice), 0), 0);
export const calculateProfit = (askingPrice, ...deductions) => roundTo(askingPrice - (deductions.reduce((a, b) => a + b, 0)), 0);

const H = XXH.h32(0xABCDEEEEFAB)
export const xxHash = (input: string) => H.update(input).digest().toString(16);
export const blobToBase64 = (blob: Blob) => new Promise(resolve => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    resolve(reader.result);
  }
});
export const xxHashBlob = (blob: Blob) => blobToBase64(blob).then(xxHash);

export const getMilliSeconds = (input1, input2) => {
  const startMoment = input1;
  const endMoment = input2;
  return moment.duration(moment(endMoment).diff(moment(startMoment)));
};

export const getHours = (input1, input2) => {
  const duration = getMilliSeconds(input1, input2);
  const years = Math.max(duration.years(), 0);
  const months = Math.max(duration.months(), 0);
  const days = Math.max(duration.days(), 0);
  const hours = Math.max(duration.hours(), 0);
  const t = [`${years}y`, `${months}m`, `${days}d`, `${hours}h`].filter(s => s.length > 1 && s.replace(/\D/g, '') !== '0');
  return t.join(" ");
};

export const getHoursAndMinutes = (input1, input2) => {
  const duration = getMilliSeconds(input1, input2);
  const years = Math.max(duration.years(), 0);
  const months = Math.max(duration.months(), 0);
  const days = Math.max(duration.days(), 0);
  const hours = Math.max(duration.hours(), 0);
  const minutes = Math.max(duration.minutes(), 0);

  const t = [`${years}y`, `${months}m`, `${days}d`, `${hours}h`, `${minutes}min`].filter(s => s.length > 1 && s.replace(/\D/g, '') !== '0');
  return t.join(" ");
};

export const zeroTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
/**
 * discard objects using a function pattern
 * iterates over the object, iterating over the patterns, if any pattern function returns true over the key/value the key is discarded from the object
 * @param inputObject
 * @param patterns
 */
export const discardObjectKeys = (inputObject, ...patterns) => {
  const outputObject = {...inputObject};
  for (const key in inputObject) {
    const value = inputObject[key];
    for (const pattern of patterns) {
      if (typeof pattern === 'function' && pattern(key, value, inputObject)) {
        delete outputObject[key];
      } else if ((typeof pattern === 'string' || typeof pattern === 'number') && value === pattern) {
        delete outputObject[key];
      }
    }
  }
  return outputObject;
}

export const isObjectWithKeys = (inputObject) => (typeof inputObject === 'object' && Object.keys(inputObject).length);
/**
 * Auto trim number to the borders,  eg if min=0, max=9  any number passing the borders will be trimmed back to 0 or 9 ( -1 and below becomes 0, 10 and above becomes 9)
 * @param input
 * @param min
 * @param max
 */
export const trimNumberMinMax = (input = 0, min = 0, max = 999999) => Math.min(Math.max(input, min), max);

export const removeStringDuplicates = (inputArray) => uniqWith((s1, s2) => s1 === s2, inputArray.map(s => s && s.trim()).filter(s => s));

export const generateNameWithDelimeter = (delimeter: string = ", ", ...nameComponents: string[]) => removeStringDuplicates(
  nameComponents
    .map(s => s.split(","))
    .flat()
    .map(formatTitle)
)
  .reverse()
  .join(delimeter)

export const getDatePlusDays = (days = 1, date = new Date()) => {
  if (typeof date === 'number') {
    date = new Date(date);
  }
  date.setDate(date.getDate() + days);
  return date;
}

export const getDatePlusYears = (years, date = new Date()) => {
  date.setFullYear(date.getFullYear() + years);
  return date;
}

export const getPageHeight = () => {
  // Full height, including the scroll part
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );
};
