import React from "react";
import styles from "./FullName.module.scss";

interface IFullNameProps {
  fullName: string;
}

const FullName = (props: IFullNameProps) => {
  return (
    <span className={`fullName ${styles.fullName}`}>
      {props.fullName.slice(0, 40).toLowerCase()}
      </span>
  );
};

FullName.defaultProps = {
  fullName: '',
};

export default React.memo(FullName);
