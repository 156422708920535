import {createSelector} from 'redux-starter-kit';
import {IProduct} from 'data/interfaces/products';
import {fetchElementsFromState} from 'data/utils/shortcuts';
import {IOrder} from 'data/interfaces/orders';

export const getAllOrders = (state): IOrder[] => fetchElementsFromState(state, [], 'orders', 'order');


export const orderSelector = ((product) => createSelector([`orders.myOrders.orders`, `orders.productOrders.orders`], (myOrders, productOrders) => [...myOrders, ...productOrders].find(order => order.product_id === product.id)));

export const ordersByMeSelector = (() => createSelector(["orders.myOrders.orders"]))();

export const ordersByMeStatusCountSelector = (() => createSelector(["orders.myOrders.statusCount"]))();

export const ordersAllOrdersSelector = (() => createSelector(["orders.allOrders.orders"]))();

export const totalOrdersByMeSelector = (() => createSelector(["orders.myOrders.serviceRequestProperties.total"]))();

export const isOrderOwnerSelector = (() => createSelector(["orders.myOrders.orders", "auth.user.id"], (orders, userId) => !!(orders && userId && orders.find(({user_id}) => user_id === userId))))();

export const isProductOrderOwnerSelector = (product: IProduct) => (() => createSelector(["orders.myOrders.orders", "orders.productOrders.orders", "auth.user.id"], (orders1, orders2, userId) => {
  if (userId) {
    const orders = [
      ...(orders1 ? orders1 : []),
      ...(orders2 ? orders2 : []),
    ];
    return !!orders.find(({
                            user_id,
                            product_id
                          }) => product.id === product_id && user_id === userId);
  }
  return false;

}))();
