import {createSelector} from 'redux-starter-kit';
import {IUser} from 'data/redux/reducers/authenticationReducer';
import {fetchElementsFromState, parseUrlPath} from 'data/utils/shortcuts';
import {
  hotelDetails,
  identifyProductCategoryType,
  productAssociatedStates,
  ticketDetails,
  vacationDetails,
  voucherDetails
} from 'data/redux/constants/productsConstants';
import {IBankDetails, IProduct} from 'data/interfaces/products';
import {RESULT, validateBankAccount} from 'israeli-bank-validation';
import {IBankQueryItem} from 'data/redux/reducers/guiReducer';
import {OWNER_PERMISSIONS} from 'data/redux/constants/permissionsConstants';
import {IHotel} from 'gui/components/editors/hotelEditor/components/hotelDetailsForm/components/offerDetails';
import {eVoucherTypes, voucherTypeToLabel} from 'data/enums/products';
import {cloneDeep} from "lodash";
import {eCurrency} from 'gui/components/formikCurrencySelector/FormikCurrencySelector';
import {productTypeKey} from "../../utils/products";


export const getAllProducts = (state): IProduct[] => fetchElementsFromState(state, [], 'products', 'product');

export const getDocuments = (state, criteria?: (product: IProduct) => boolean) => {
  const allProducts = getAllProducts(state);
  const documents = [];
  for (const product of allProducts) {
    if ('document' in product) {
      if (!documents.includes(product.document) && (criteria ? criteria(product) : true)) {
        documents.push(product.document)
      }
    }
  }
  return documents;
};
export const getDocumentsFiles = state => {
  return getDocuments(state)
    .filter(document => document.files && document.files.length)
    .map(document => document.files)
    .flat();
};

const filterTimedOutProducts = (products: IProduct[], initializationTime: number) => products.filter(product => product.retrievedAt >= initializationTime);

export const productsByMeSelector = (() => createSelector(["products.myProducts.products", "auth.user", "auth.initializationTime"], (products: IProduct[], user: IUser, initializationTime: number) => {
  if (user) {
    const productsByUser = filterTimedOutProducts(products, initializationTime)
      .filter(product => product.permissions
        .find(permission => permission.user_id === user.id));
    return productsByUser;
  }
  return [];
}))();

export const productsByMeStatusCountSelector = (() => createSelector(["products.myProducts.statusCount"]))();

export const allProductsSelector = (() => createSelector(["products.allProducts.products", "auth.initializationTime"], filterTimedOutProducts))();

export const allProductsLimitSelector = (() => createSelector(["products.allProducts.serviceRequestProperties.limit"]))();

export const allProductsTotalSelector = (() => createSelector(["products.allProducts.serviceRequestProperties.total"]))();

export const totalProductsByMeSelector = (() => createSelector(["products.myProducts.serviceRequestProperties.total"]))();

export const adPageUrlSelector = (() => createSelector(["router.location.pathname"], (pathName) => {
  return parseUrlPath(pathName, ["ad", "product", "edit", "admin", "control"], "id", "slug");
}))();

export const voucherPageUrlSelector = (() => createSelector(["router.location.pathname"], (pathName) => {
  return parseUrlPath(pathName, ["voucher"], "id", "slug");
}))();

export const adProductSelector: (state: any) => IProduct = (() => createSelector(["products.ad.product", "auth.initializationTime"], (product: IProduct, initializationTime) => {
  return product.retrievedAt >= initializationTime ? product : {} as any;
}))();

export const isProductOwnerSelector: (state: any) => boolean = (() => createSelector(["products.ad.product.permissions", "auth.user.id"], (permissions, userId) => {
  return !!(permissions && userId && permissions.find(({
                                                         user_id,
                                                         permission
                                                       }) => user_id === userId && OWNER_PERMISSIONS.includes(permission)));
}))();

const autoSetBankAndBranch = (product: IProduct, banks: IBankQueryItem[] = [], branches: IBankQueryItem[] = []) => {
  /*
    if (product.bankDetails) {
      const bankDetails: any = product.bankDetails || {};
      const bankCode = ((typeof bankDetails.bank === 'object') ? bankDetails.bank.bankCode : bankDetails.bank) || 0;
      const branchCode = parseInt(((typeof bankDetails.branch === 'object') ? bankDetails.branch.branchCode : bankDetails.branch) || 0);
      if (banks.length > 0) {
      bank is read only in firefox???
        product.bankDetails.bank = banks.find((bank) => bank.bankCode === bankCode) as any;
      }
      if (branches.length > 0) {
        product.bankDetails.branch = branches.find((branch) => parseInt(branch.branchCode) === branchCode) as any;
      }
    }

   */
  return product;
};

export const adProductBankDetailsSelector: (state: any) => IBankDetails = (() => createSelector(["products.ad.product"
  , "auth.initializationTime"
  , "gui.banks"
  , "gui.branches"
  , "auth.user.firstName"
  , "auth.user.lastName"], (_product: IProduct, initializationTime, banks: IBankQueryItem[], branches: IBankQueryItem[], firstName: string, lastName: string) => {
  const product = _product.retrievedAt >= initializationTime ? _product : {} as any;
  return autoSetBankAndBranch(product, banks, branches).bankDetails || {owner: [firstName, lastName].join(' ')};
}))();

export const isProductValidBankSelector: (state: any) => boolean = (() => createSelector(["products.ad.product", "auth.initializationTime"], (_product: IProduct, initializationTime) => {
  const product = _product.retrievedAt >= initializationTime ? _product : {} as any;
  if (!product.bankDetails || typeof product.bankDetails !== 'object')
    return false;
  const bankCode = ((typeof product.bankDetails.bank === 'object') ? product.bankDetails.bank.bankCode : product.bankDetails.bank) || 0;
  const branchCode = ((typeof product.bankDetails.branch === 'object') ? product.bankDetails.branch.branchCode : product.bankDetails.branch) || 0;
  return !!(product.bankDetails && (validateBankAccount(`${bankCode}`, `${branchCode}`, `${product.bankDetails.account || 0}`) === RESULT.VALID) && product.bankDetails.owner.length > 0);
}))();

export const typeProductSelector = ((type) => createSelector([`products.${productTypeKey(type)}.products`], (products) => products || []));

export const typeProductTotalSelector = ((type) => createSelector([`products.${productTypeKey(type)}.serviceRequestProperties.total`], (total) => total || 0));

export const urlOrTypeProductPageSelector = (type) => (() => createSelector([`products.${productTypeKey(type)}.serviceRequestProperties.page`, 'router.location.query.page'], (storePage, urlPage) => parseInt(storePage || urlPage || 1, 10)))();

export const urlOrTypeProductPagesSelector = (type, _limit = undefined) => (() => createSelector([`products.${productTypeKey(type)}.serviceRequestProperties.limit`, `products.${productTypeKey(type)}.serviceRequestProperties.total`], (limit, total) => {

  return Math.floor((total || 1) / (_limit || limit || 40)) + 1;
}))();

export const urlOrTypeProductLimitSelector = (type) => (() => createSelector([`products.${productTypeKey(type)}.serviceRequestProperties.limit`, 'router.location.query.limit'], (limit, urlLimit) => parseInt(limit || urlLimit || 40, 10)))();

export const urlOrTypeProductSkipSelector = (type) => (() => createSelector([`products.${productTypeKey(type)}.serviceRequestProperties.skip`, 'router.location.query.skip'], (skip, urlSkip) => parseInt(skip || urlSkip || 0, 10)))();

export const typeProductHashSelector = ((type) => createSelector([`products.${productTypeKey(type)}.hash`]));

export const typeProductTimeSelector = ((type) => createSelector([`products.${productTypeKey(type)}.hash`], (products) => products || []));

export const extractProductOriginalPrice: (product: IProduct) => number = (product: IProduct) => {
  switch (identifyProductCategoryType(product)) {
    case vacationDetails:
    case ticketDetails:
      return product.ticketDetails.currentAirlineAllTicketsPrice;
    case hotelDetails:
      return product.hotelDetails.totalHotelPaidPrice;
    case voucherDetails:
      return product.voucherDetails.voucherBalance;
  }
  return 0;
}

export const getProductOfStatusTypes: (product: IProduct) => string[] = (product) => {
  const types = [];
  for (const statusType in productAssociatedStates) {
    for (const status of productAssociatedStates[statusType]) {
      if (status === product.status) {
        if (!types.includes(statusType)) {
          types.push(statusType);
        }
        break;
      }
    }
  }
  return types;
}

export const isProductOfStatusType: (product: IProduct, ...typesToCheck: string[]) => boolean = (product, ...typesToCheck) => {
  return !!typesToCheck.find(type => getProductOfStatusTypes(product).includes(type));
}

/**
 * Get ending of this product
 */
export const productEndingSelector = (() => createSelector<IProduct, number>(
  ["ticketDetails.originTimeOfTakeOff",
    "hotelDetails.checkOutDate",
    "voucherDetails.expiration",
  ], function () {
    return [...arguments as any].find(arg => isNaN(arg) === false) || 0;
  }))();


export const productLatitudeSelector = (() => createSelector<IProduct, number>(
  ["hotelDetails.hotel.geometry.location.lat",
    "ticketDetails.originAirport.x",
    "ticketDetails.destinationAirport.x"], function () {
    return [...arguments as any].find(arg => isNaN(arg) === false) || 0;
  }))();

export const productLongitudeSelector = (() => createSelector<IProduct, number>(
  ["hotelDetails.hotel.geometry.location.lng",
    "ticketDetails.originAirport.y",
    "ticketDetails.destinationAirport.y"
  ], function () {
    return [...arguments as any].find(arg => isNaN(arg) === false) || 0;
  }))();


/**
 * Get the hotel of this product if exist
 */
export const productHotelSelector = (() => createSelector<IProduct, IHotel>(
  ["hotelDetails.hotel",
    "voucherDetails.placeDetails"
  ], function () {
    return [...arguments as any].find(arg => isNaN(arg) === false) || 0;
  }))();

/**
 * Get the company name of this product
 */
export const productCompanyNameSelector = (() => createSelector<IProduct, string>(
  ["ticketDetails.originFlightCompany.name",
    "voucherDetails.flightCompanyDetails.name",
    "voucherDetails.placeDetails.name",
    "voucherDetails.companyDetails.name",
    "hotelDetails.hotel.name",
  ], function () {
    return [...arguments as any].find(arg => arg) || '';
  }))();


export const productDocumentFilesSelector = (() => createSelector(["products.ad.product.document.files"], (files) => {
  return files || [];
}))();

export const fileMimeSelector = (file_id: string) => (() => createSelector(["products"],
  (products) => {
    const file = getDocumentsFiles(products).find(file => file.id === file_id);
    if (file) {
      return file.mimetype;
    }
    return '';
  }))();

export const fileNameSelector = (file_id: string) => (() => createSelector(["products"],
  (products) => {
    const file = getDocumentsFiles(products).find(file => file.id === file_id);
    if (file) {
      return file.name;
    }
    return '';
  }))();

export const fileThumbnailSelector = (file_id: string) => (() => createSelector(["products"],
  (products) => {
    const file = getDocumentsFiles(products).find(file => file.id === file_id);
    if (file) {
      return file.thumbnail;
    }
    return null;
  }))();

export const fileIsLoadingSelector = (file_id: string) => (() => createSelector(["products"],
  (products) => {
    const file = getDocumentsFiles(products).find(file => file.id === file_id);
    if (file) {
      return file.loading;
    }
    return false;
  }))();

export const fileProductsSelector = (file_id: string) => (() => createSelector(["products"],
  (products) => getAllProducts(products)
    .filter(product => product.document)
    .filter(product => product.document.files.find(file => file.id === file_id))))();

export const getVoucherName = (product: Partial<IProduct>) => {
  try {
    switch (product.voucherDetails.type) {
      case eVoucherTypes.flightCompanies:
        return product.voucherDetails.flightCompanyDetails.name;
      case eVoucherTypes.cruise:
      case eVoucherTypes.agencies:
        return product.voucherDetails.companyDetails.name;
      default:
        const placeDetailsName = getPlaceDetailsName(product);
        return placeDetailsName || product.voucherDetails.companyDetails.name;
      //case eVoucherTypes.hotels:
      //  return (product.voucherDetails.placeDetails as any).data.result.name;
    }
    return product.voucherDetails.companyDetails.name;

  } catch (e) {
    // console.log(11111111,e);
  }
  return '';
};

export const getProductTypeIdSelector = (() => createSelector(["product-typesService.queryResult.data[0].id"]))();


export const voucherTypeText = (product: IProduct) => {
  try {
    const voucherType = product.voucherDetails.type || (product.typeDetails && product.typeDetails.subType);
    if (voucherTypeToLabel[voucherType]) {
      return voucherTypeToLabel[voucherType];
    }
  } catch (e) {
    console.log("No name found", e);
  }
  return "";
};


export const getProductCurrencyRateSelector = (() => createSelector(["saleDetails.currencyDetails.rate"], rate => rate || 1))();


const performOnProductDetailsWithOriginalDetails = (product, perform = details => details) => {
  if (product) {
    const clonedProduct = cloneDeep(product);
    for (const detailsKey of Object.keys(clonedProduct)) {
      const details = clonedProduct[detailsKey];
      if (details && typeof details === 'object' && details.originalDetails && typeof details.originalDetails === 'object') {
        perform(details);
      }
    }
    return clonedProduct;
  }
  return product;
};


/**
 * convert dollars/euro to shekels automatically and keep original values as a sub object
 * @param details
 * @param currencyDetails
 * @param key
 */
const autoConvertDetailToShekels = (details, currencyDetails, key) => {
  if (details.currency !== eCurrency.SHEKEL && currencyDetails) {
    //convert to shekels
    details.originalDetails = {
      ...(details.originalDetails || {}),
      currency: details.currency,
      [key]: details[key]
    };
    details.currency = currencyDetails.toCode;
    details[key] = details[key] * currencyDetails.rate;
  }
}

const autoConvertDetailsToShekels = (details, currencyDetails, ...keys) => {
  for (const key of keys.flat()) {
    autoConvertDetailToShekels(details, currencyDetails, key);
  }
}


/**
 * use originalDetails for editors etc
 * @param product
 */
export const restoreOriginalDetails = product => {
  return performOnProductDetailsWithOriginalDetails(product, details => {
    for (const originalDetailKey of Object.keys(details.originalDetails)) {
      details[originalDetailKey] = details.originalDetails[originalDetailKey];
    }
  });
};

/**
 * Delete originalDetails for things like posting
 * @param product
 */
export const deleteOriginalDetails = product => {
  return performOnProductDetailsWithOriginalDetails(product, details => {
    delete details.originalDetails;
  });
};

export const convertProductToShekels = product => {
  try {
    return performOnProductDetailsWithOriginalDetails(product, details => {
      return autoConvertDetailsToShekels(details, product.saleDetails.currencyDetails, Object.keys(details.originalDetails));
    });
  } catch (e) {
    console.log("error: failed to auto convert product to shekels.", e);
    return product;
  }
};

const placeDetailsSelector = createSelector(['placeDetails[0]', 'hotelDetails.hotel']);
export const getPlaceDetails = (product: IProduct) => placeDetailsSelector(product);


const placeDetailsNameSelector = createSelector(['placeDetails[0].name', 'hotelDetails.hotel.name'], name => name || '');
export const getPlaceDetailsName = (product: Partial<IProduct>) => placeDetailsNameSelector(product);

export const isVoucherWithPlace = (product: Partial<IProduct>) => {
  switch (product.voucherDetails.type) {
    case eVoucherTypes.flightCompanies:
    case eVoucherTypes.cruise:
    case eVoucherTypes.agencies:
      return false;
    default:
      return true;
  }
}
