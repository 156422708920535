import {connect} from 'react-redux'

import {removeAssociatedStateFromPath} from 'data/redux/actions/productsActions';
import {mapDispatchToProps as linkBlockMapDispatchToProps} from '../linkBlockContainer/LinkBlockContainer'
import {userIsAllowed} from 'data/redux/selectors/userSelector';
import NavigateLink from 'gui/pages/mainRouter/components/navigation/components/navigateLink/NavigateLink';
import {getPathName} from 'data/redux/selectors/routerSelector';

const mapStateToProps = (state, ownProps) => {
  return {
    currentPathName: removeAssociatedStateFromPath(getPathName(state)),
    allowed: userIsAllowed(ownProps.userType)(state),
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...linkBlockMapDispatchToProps(dispatch)
  };
};

const NavigateLinkContainer = connect(mapStateToProps, mapDispatchToProps)(NavigateLink);

export default NavigateLinkContainer;
