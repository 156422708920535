import {connect} from 'react-redux'
import Main from 'gui/pages/mainRouter/components/main';
import {isLoggedInSelector} from 'data/redux/selectors/authenticationSelector';
import {getPathName} from 'data/redux/selectors/routerSelector';


const mapStateToProps = (state) => {
  return {
    pathName: getPathName(state),
    signedIn: isLoggedInSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const MainContainer = connect(mapStateToProps, mapDispatchToProps)(Main);

export default MainContainer;
