import uniqWith from 'uniq-with';

export const ORDER_STATUS_UNKNOWN = 0;
export const ORDER_STATUS_WAITING_FOR_APPROVAL = 1;
export const ORDER_STATUS_DISAPPROVED = 2;
export const ORDER_STATUS_PUBLISHED = 100;
export const ORDER_STATUS_CANCELED = 102;
export const ORDER_STATUS_TIMED_OUT = 103;
export const ORDER_STATUS_ORDER = 200;
export const ORDER_STATUS_ORDER_APPROVED_WAITING_TRANSFER = 201;
export const ORDER_STATUS_ORDER_APPROVED_WAITING_TRANSFER_CHARGING_BUYER = 202;
export const ORDER_STATUS_ORDER_APPROVED_WAITING_TRANSFER_APPROVAL_BY_BUYER = 203;
export const ORDER_STATUS_APPROVED_TRANSFER = 204;
export const ORDER_STATUS_ORDER_APPROVED_WAITING_PAYMENT = 205;
export const ORDER_STATUS_ORDER_SOLD = 300;
export const ORDER_STATUS_ORDER_DISPUTE = 400;

export const ORDER_STATUS_TYPE_OPEN = 'open';
export const ORDER_STATUS_TYPE_SOLD = 'sold';
export const ORDER_STATUS_TYPE_CANCELED = 'canceled';

export const orderAssociatedStates = {
  [ORDER_STATUS_TYPE_OPEN]: [
    ORDER_STATUS_WAITING_FOR_APPROVAL,
    ORDER_STATUS_PUBLISHED,
    ORDER_STATUS_ORDER,
    ORDER_STATUS_ORDER_APPROVED_WAITING_TRANSFER,
    ORDER_STATUS_APPROVED_TRANSFER,
    ORDER_STATUS_ORDER_APPROVED_WAITING_PAYMENT,
    ORDER_STATUS_ORDER_APPROVED_WAITING_TRANSFER_APPROVAL_BY_BUYER,
    ORDER_STATUS_ORDER_DISPUTE,
  ],
  [ORDER_STATUS_TYPE_SOLD]: [ORDER_STATUS_ORDER_SOLD],
  [ORDER_STATUS_TYPE_CANCELED]: [ORDER_STATUS_TIMED_OUT, ORDER_STATUS_DISAPPROVED, ORDER_STATUS_CANCELED],
};

export const allOrderStates = uniqWith(((a, b) => a === b), Object.keys(orderAssociatedStates).map(type => orderAssociatedStates[type]).flat());
