import React from "react";
import styles from './FormikCurrencySelector.module.scss';
import FormikSearchSelector from 'gui/components/formikSearchSelector/FormikSearchSelector';
import ObjectArrarify from 'object-arrarify';
import {SelectOption} from 'react-select-material-ui';
import FormikSelectInput from "../formikSelectInput/FormikSelectInput";

export enum eCurrency {
  DOLLAR = 1,
  SHEKEL = 2,
  EURO = 3,
}

export const currencyValueToLabel = {
  [eCurrency.DOLLAR]: "$",
  [eCurrency.SHEKEL]: "₪",
  [eCurrency.EURO]: "€",
};

export const currencyValueToTextLabel = {
  [eCurrency.DOLLAR]: "דולר",
  [eCurrency.SHEKEL]: "ש\"ח",
  [eCurrency.EURO]: "יורו",
};

export const currencyValueToISO = {
  [eCurrency.DOLLAR]: "USD",
  [eCurrency.SHEKEL]: "ILS",
  [eCurrency.EURO]: "EUR",
};


interface IFormikCurrencySelectorProps {
  label: string;
  currencies: string[] | SelectOption[];
}

const FormikCurrencySelector = (props: IFormikCurrencySelectorProps) => {
  return (
    <FormikSearchSelector
      className={styles.container}
      options={props.currencies}
      isSearchable={false}
      isClearable={false}
      {...props}
    />
  );
};


const FormikCurrencySelector2 = (props: IFormikCurrencySelectorProps) => {
  return (
    <FormikSelectInput
      options={props.currencies}
      {...props as any}
    />
  );
};


const defaultCurrencies = ObjectArrarify(currencyValueToLabel).mapToArray((value, key) => ({
  label: value,
  value: parseInt(key)
}));

FormikCurrencySelector.defaultProps = {
  currencies: defaultCurrencies
};

export default React.memo(FormikCurrencySelector);
