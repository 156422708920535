import {createReducer} from 'redux-starter-kit';
import {clearErrorNotification, setEventNotification} from 'data/redux/actions/notificationActions';

const initialNavigationReducerState = {
  events: [],
};

const notificationReducer = createReducer(initialNavigationReducerState, {
  [setEventNotification.type]: (state, action) => {
    state.events.push(action.payload);
  },
  [clearErrorNotification.type]: (state, action) => {
    state.events = state.events.filter(event => event.type !== action.payload.type);
  },
});
export default notificationReducer;
