import React from "react";
import LinkBlockContainer from 'data/redux/containers/linkBlockContainer';
import {linksMap} from 'data/utils/constants';
import styles from './PublishButton.module.scss';
import Editor from 'gui/components/editors/commons/editor';

interface IPublishButtonProps {
  className?: string;
}

const PublishButton = (props: IPublishButtonProps) => (
  <span className={[props.className, styles.publishadbutton].join(" ").trim()}>
         <LinkBlockContainer to={linksMap.createProduct}
                             className={styles.hrikmMsrp}>
           פרסם מכירה
         </LinkBlockContainer>
      </span>
);
PublishButton.defaultProps = {
  className: '',
};
export default React.memo(PublishButton);
