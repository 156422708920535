import React from "react";
import styles from './LoggedInAccountButton.module.scss';
import {Button, Menu} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {MDBNavItem} from 'mdbreact';
import AvatarContainer from 'data/redux/containers/avatarContainer/AvatarContainer';
import PublishButton from '../../../dealAndGoNavButtons/components/publishButton/PublishButton';
import PredefinedMenuList from './components/menuList/PredefinedMenuList';
import FullNameContainer from 'data/redux/containers/fullNameContainer';
import {linksMap} from 'data/utils/constants';

interface IILoggedInAccountButtonProps {
  menuOpen: boolean;
  pathName: string;
  openMenu: () => void;
  closeMenu: () => void;

  onMenuSelect: (route: string) => void;

  logout: () => void;
}

const StyledMenu = withStyles({
  paper: {
    backgroundColor: "#05BEC0"
  },
})((props: any) => (
  <>
    <Menu
      disableScrollLock={true}
      elevation={0}
      anchorEl={props.anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  </>
));


const LoggedInAccountButton = (props: IILoggedInAccountButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  return (
    <>
      <ul
        className={`navbar-nav controlMenuContainer mobileLoggedInAccountContainer ${styles.controlMenuContainer} ${styles.mobileLoggedInAccountContainer}`}>
        <AvatarContainer/>
        <FullNameContainer/>
        <PredefinedMenuList menuListFilter={menuList => {
          menuList = [...menuList];
          const index = menuList.indexOf(menuList.find(({path}) => path === linksMap.createProduct)) || 0;
          menuList.splice(index, 0, ...[
            {
              label: "דף בית",
              path: linksMap.root,
              className: styles.homeLink
            },
            {
              label: "תיירות",
              path: linksMap.tourism,
            },
            {
              label: "שאלות ותשובות",
              path: linksMap.faq,
            }
          ]);
          return menuList;
        }}/>
      </ul>

      <ul style={{direction: "ltr"}}
          className={`navbar-nav loggedInAccountContainer ${styles.loggedInAccountContainer}`}>
        <MDBNavItem><PublishButton/></MDBNavItem>
        <MDBNavItem>
          <Button className={`myAccountMenu ${styles.myAccountMenu}` + (props.menuOpen ? " menuOpen" : "")}
                  aria-controls="account menu"
                  aria-haspopup="true" onClick={(event) => {
            setAnchorEl(event.currentTarget);
            props.openMenu();
          }}>
            <AvatarContainer/>
            <FullNameContainer firstName/>
          </Button>
          <StyledMenu
            className={`loggedInAccountMenuContainer  ${styles.loggedInAccountMenuContainer}`}
            anchorEl={anchorEl}
            open={props.menuOpen}
            onClose={props.closeMenu}
          >
            <PredefinedMenuList/>
          </StyledMenu>
        </MDBNavItem>
      </ul>
    </>
  );
};
LoggedInAccountButton.defaultProps = {
  menuOpen: false,
  pathName: "",
  openMenu: () => null,
  closeMenu: () => null,

  onMenuSelect: () => null,

  logout: () => null,
};
export default LoggedInAccountButton;
