import React from "react";
import LinkBlockContainer from 'data/redux/containers/linkBlockContainer';
import {linksMap} from 'data/utils/constants';
import styles from './RegisterButton.module.scss';

interface IPublishButtonProps {
  className?: string;
}

const RegisterButton = (props: IPublishButtonProps) => (
  <span className={`${props.className} ${styles.btn}`.trim()}>
          <LinkBlockContainer to={linksMap.register} className={styles.tvprtch}>הצטרפות</LinkBlockContainer>
      </span>
);
RegisterButton.defaultProps = {
  className: '',
};
export default React.memo(RegisterButton);
