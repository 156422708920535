import {createReducer} from 'redux-starter-kit';
import {
  setLoadingAction,
  setLoggedInButtonMenuClose,
  setLoggedInButtonMenuOpen,
  setMessageEditors,
  setPageGuiAction
} from 'data/redux/actions/guiActions';
import {IBrowser} from 'redux-responsive/types';
import {isSameMessageEditor} from 'data/redux/selectors/guiSelector';


export interface IBankQueryItem {
  bankName: string;
  bankCode: number;
  branchName: string;
  branchCode: string;
};

interface IMessageEditor {
  type,
  category,
  status,
  subject: string;
  subTitle: number;
  content: any;
};

export interface IGuiReducerState extends Partial<IBrowser> {
  loading: boolean;
  loggedInButtonMenuOpen: boolean;
  banks: IBankQueryItem[];
  branches: IBankQueryItem[];
  pages: any;
  messageEditors: IMessageEditor[];
}


const initialGuiReducerState: IGuiReducerState = {
  loading: false,
  loggedInButtonMenuOpen: false,
  banks: [],
  branches: [],
  pages: {},
  messageEditors: [],
};


const guiReducer = createReducer(initialGuiReducerState, {
  [setLoadingAction.type]: (state, {payload}) => {
    state.loading = payload.state;
  },
  [setLoggedInButtonMenuOpen.type]: (state, action) => {
    state.loggedInButtonMenuOpen = true;
  },
  [setLoggedInButtonMenuClose.type]: (state, action) => {
    state.loggedInButtonMenuOpen = false;
  },
  'SERVICES_BANKS-AUTOCOMPLETE_GET_FULFILLED': (state, {payload: {value: banksOrBranches}}) => {
    if (banksOrBranches.length > 0) {
      if ('branchCode' in banksOrBranches[0]) {
        state.branches = banksOrBranches;
      } else {
        state.banks = banksOrBranches;
      }
    } else {
      state.banks = [];
      state.branches = [];
    }
  },
  [setPageGuiAction.type]: (state, {payload}) => {
    if (false && payload) {
      for (const key in payload) {
        state.pages[key] = payload[key];
      }
    }
  },

  [setMessageEditors.type]: (state, {payload: {data, ...newEditor}}) => {
    if (Array.isArray(data) && data.length) {
      state.messageEditors = [
        ...(state.messageEditors || []).filter((editor => !isSameMessageEditor(editor, newEditor))),
        ...[data || []].flat()
      ];
    }
  },

});
export default guiReducer;
