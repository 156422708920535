import {getAdToLoadType} from 'data/redux/selectors/routerSelector';

/**
 * Generate proper state query using both, redux state, url and options
 * @param options
 * @param getState
 */
export const generateStatusQuery = (options, getState, stateAssociations = {}, allStates = []) => {
  delete options.$joinRelationArray;
  if (options.query) {
    return options.query;
  }

  const status = {$in: []};

  if (options.status) {
    status.$in = [options.status];
  } else {
    /**
     * Fetch association from the url,
     * it can also be a single state
     */
    const urlStateAssociation = getAdToLoadType(getState());
    /**
     * Associated states by a type, eg all ORDER_STATUS_TYPE_OPEN
     */
    const associatedStates = stateAssociations[options.type || urlStateAssociation];
    /**
     * if associated states are found by type, use them.
     */
    if (associatedStates) {
      status.$in = associatedStates;
    }
    /**
     * Else if what is fetched from the url is a number, and it exists in all the states
     * use that
     */
    else if ((!isNaN(urlStateAssociation))) {
      const urlStatus = parseInt(urlStateAssociation);
      if (urlStatus)
        status.$in = [urlStatus];
    }
  }
  /**
   * Make sure the state actually exists
   */
  status.$in = status.$in.filter(state => allStates.includes(state));
  /**
   * If no valid states were chosen, just search everything
   */
  if (status.$in.length <= 0) {
    delete status.$in;
  }
  return {status};
}
