import React from "react";
import LinkBlockContainer from 'data/redux/containers/linkBlockContainer';
import {linksMap} from 'data/utils/constants';
import styles from './LoginButton.module.scss';

interface IPublishButtonProps {
  className?: string;
}

const LoginButton = (props: IPublishButtonProps) => (
  <LinkBlockContainer to={linksMap.login}
                      className={`${props.className} ${styles.hsink}`.trim()}>כניסה
  </LinkBlockContainer>
);
LoginButton.defaultProps = {
  className: '',
};
export default React.memo(LoginButton);
