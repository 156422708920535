import uniqWith from 'uniq-with';
import lazyWithPreload from 'lazy-with-preload';
import {generateLinkMapSeoDetail, linksMap, linksMapSeo} from 'data/utils/constants';
import React from 'react';

//const he = require('convert-layout/he');
//const ru = require('convert-layout/ru');

const weirdEscapes = new RegExp("(&\#[0-9]+;)", 'g');
export const filterWeirdEscapes = (input) => input.replace(weirdEscapes, "");
/**
 * format title, remove weird escapes
 * @param input
 */
export const formatTitle = (input) => {
  try {
    input = filterWeirdEscapes(input).trim();
    if (input.endsWith("-")) {
      input = input.slice(0, input.length - 1).trim();
    }
    if (input.startsWith("-")) {
      input = input.slice(1);
    }
  } catch (e) {
  }
  return input;
};
/**
 * Set timeout as a promise
 * @param miliSeconds
 */
export const promiseWait = (miliSeconds: number, toReturn = null) => new Promise(resolve => setTimeout(() => resolve(toReturn), miliSeconds));

/**
 * Set timeout as a promise and a condition to exit
 * @param miliSeconds
 * @param condition
 */
export const promiseWaitCondition = async (miliSeconds: number, condition: (count: number) => Promise<boolean>) => {
  let count = 0;
  while (await condition(count)) {
    await promiseWait(miliSeconds);
    count++;
  }
  return count;
}

export const promiseAwaitIgnoreReject = async (...promises) => {
  const results = [];
  for (const promise of promises.flat(10)) {
    try {
      results.push(await promise);
    } catch (e) {
      console.log('Failed promise', e);
    }
  }
  return results;
};


export const slugFix = (text) => text.replace(/[&\/\\#,+()$~%.'":*?<>{} ]/g, "-");

/**
 * Make authenticated call using the access-token already stored in localstorage that is already used by feathers-ws
 * @param url
 * @param options
 */
export const authenticatedFetch = (url: string, options: any = {}) => fetch(url, {
  method: 'GET', // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Authorization': `Bearer ${localStorage['access-token']}`,
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  ...options,
});

export const authenticatedXHR = (url, options: any = {}, onProgress = null) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const method = options.method || 'GET';

    xhr.open(method, url, true);
   // xhr.withCredentials = true;
    xhr.setRequestHeader('Authorization', `Bearer ${localStorage['access-token']}`);
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Pragma', 'no-cache');
    xhr.setRequestHeader('Expires', '0');

    if (options.headers) {
      Object.entries(options.headers).forEach(([key, value]: any) => {
        xhr.setRequestHeader(key, value);
      });
    }

    if (onProgress && xhr.upload) {
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const bytes = event.loaded;
          const percent = Math.round((event.loaded * 100) / event.total);
          onProgress({bytes, percent});
        }
      };
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      reject(xhr.statusText);
    };

    const data = options.body || null;
    xhr.send(data);
  });
};


const shortString = (inputString, length ?, reverse = false) => {
  const stop_chars = [' ', '/', '&'];
  const acceptable_shortness = length * 0.80;
  if (reverse) {
    inputString = inputString.split("").reverse().join("");
  }
  let short_s = "";
  for (let index = 0; index < length - 1; index++) {
    short_s += inputString[index];
    if (index >= acceptable_shortness && stop_chars.indexOf(inputString[index]) >= 0) {
      break;
    }
  }
  if (reverse) {
    return short_s.split("").reverse().join("");
  }
  return short_s;
}
/**
 * Make url look shorter by slicing in the middle
 * @param url
 * @param l
 */
export const shortUrl = (url, l = 20) => {
  const chunk_l = (l / 2);
  url = url.replace("http://", "").replace("https://", "");
  if (url.length <= l) {
    return url.replace("/", "").replace("\\", "");
  }
  const start_chunk = shortString(url, chunk_l, false);
  const end_chunk = shortString(url, chunk_l, true);
  url = start_chunk + ".." + end_chunk;
  return url.replace("/", "").replace("\\", "");
}
/**
 * Read text from a fileObject
 * @param file
 */
export const readTextFile = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const blb = file;
    const reader = new FileReader();
    reader.addEventListener('loadend', (e: any) => {
      if (e.srcElement.result) {
        resolve(e.srcElement.result);
      } else {
        reject(e);
      }
    });
    reader.addEventListener('error', (e: any) => {
      reject(e);
    });

    reader.addEventListener('abort', (e: any) => {
      reject(e);
    });
    reader.readAsText(blb);
  });
};
/**
 * Generate csv file
 * @param input
 * @param labels
 * @param customObjectProcessor
 */
export const generateUTF8CSVString = (input: any[], labels: boolean | any = true, customObjectProcessor = object => object): string => {
  /**
   * Ensure that UTF-8 csv is generated
   */
  const universalUTF8BOM = "\uFEFF";
  //const startOfCSV = 'data:text/csv;charset=utf-8,';
  const startOfCSV = '';
  const csvArray = [];

  const labelsIsObject = (labels && typeof labels === 'object');
  const shouldIncludeLabelsInHeader = labelsIsObject || labels === true;
  /**
   * Find out all the possible keys in the array
   */
  
  const allPossibleLabels = labelsIsObject ? Object.keys(labels) : uniqWith((a, b) => a === b, input.map(obj => Object.keys(obj)).flat());
  if (!labelsIsObject) {
    labels = {};
    for (const o of allPossibleLabels) {
      labels[o] = o;
    }
  }

  if (shouldIncludeLabelsInHeader) {
    
    csvArray.push(allPossibleLabels.join(","));
  }
  for (let inputObject of input) {
    inputObject = customObjectProcessor(inputObject);
    const row = [];
    for (const key in labels) {
      const label = labels[key];
      row.push(inputObject[label] || "");
    }
    csvArray.push(row.join(","));
  }
  return `${universalUTF8BOM}${startOfCSV}${csvArray.join("\r\n")}`;
}
/**
 * Parse details from url
 * Todo: replace with proper library to parse url details instead
 * @param pathName
 * @param skip
 * @param mapIntoKeys
 */
export const parseUrlPath = (pathName, skip: string[] = [], ...mapIntoKeys) => {
  skip = skip.map(s => s.toLowerCase());
  const splitPathName = pathName.toLowerCase().split("/").filter(s => s && !skip.includes(s));
  const mappedObject = {};
  for (const key of mapIntoKeys) {
    mappedObject[key] = splitPathName.length > 0 ? splitPathName.shift() : null;
  }
  return mappedObject;
}
/**
 * Get midnight eg 00:00 of a chosen date
 * @param date
 */
export const getMidnight = (date) => {
  date = new Date(date);
  date.setHours(0, 0, 0, 0);
  return date;
}
/**
 * Get todays midnight
 */
export const getTodayMidnight = () => {
  return getMidnight(new Date());
}
/**
 * Auto convert any hebrew char into english
 * @param char
 */
const attemptLanguageToEnglish = (char) => {
// for (const converter of [he, ru]) {
//   const newChar = converter.toEn(char);
//   if (newChar !== char) {
//     return newChar;
//   }
// }
  return char;
}
/**
 * Allow only english and filter the rest
 * @param text
 */
export const allowOnlyEnglish = (text) => text.replace(/[^A-Za-z ']/g, "").replace(/\s\s+/g, ' ');
/**
 * Allow only english and convert to english when possible
 * @param text
 */
export const allowOnlyEnglishAutoConvert = (text) => allowOnlyEnglish(text.split('').map(char => attemptLanguageToEnglish(char)).join(''));

export const allowOnlyEnglishAndNumbers = (text) => text.replace(/[^A-Za-z0-9 ']/g, "").replace(/\s\s+/g, ' ');

export const allowOnlyEnglishAndNumbersAutoConvert = (text) => allowOnlyEnglishAndNumbers(text.split('').map(char => attemptLanguageToEnglish(char)).join(''));


export function unicodeToChar(text) {
  return text.replace(/\\u[\dA-F]{4}/gi,
    function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
}

/**
 * Mutates the existing array
 * @param array
 * @param criteria
 */
export const removeObjectFromArrayWithMutation = (array, criteria = (object: any, index?: number): boolean => false) => {
  const arrayClone = [...array];
  for (let index = 0; index < arrayClone.length; index++) {
    const object = array[index];
    if (criteria(object, index)) {
      array.splice(index, 1);
    }
  }
  return array;
}

const pushObjectToArray = (objectItem: any, destinationArray: any[]) => {
  if (!destinationArray.includes(objectItem)) {
    return destinationArray.push(objectItem);
  }
  return destinationArray.length;
}

export const fetchElementsFromState = (object: any, destinationArray: any[] = [], ...keys: string[]) => {
  for (const itemsObjectKey in object) {
    const itemsObject = object[itemsObjectKey];
    for (const key of keys) {
      if (key in itemsObject) {
        if (Array.isArray(itemsObject[key])) {
          for (const objectItem of itemsObject[key]) {
            pushObjectToArray(objectItem, destinationArray);
          }
        } else {
          pushObjectToArray(itemsObject[key], destinationArray);
        }
      }
    }
  }
  return destinationArray;
}
/**
 * Convert object or string or array, into values array
 * @param object
 */
const anyToValuesArray = (object: any) => {
  let values = [];
  if (typeof object === 'string') {
    values = [object];
  } else if (object && typeof object === 'object') {
    if (Array.isArray(object)) {
      values = object;
    } else {
      values = Object
        .values(object);
    }
  }
  return values;
}


/**
 * Convert $eager/$joineager names into their extended versions
 * @param typeToTableNameObject
 * @param detailsAddons
 */
export const autoAddAddons = (typeToTableNameObject, detailsAddons) => {
  const s = uniqWith(((a, b) => a === b),
    anyToValuesArray(typeToTableNameObject)
      .map((s: string) => (s || '').split(","))
      .flat()
      .filter(s => s));
  for (const key in s) {
    const value = s[key];
    const t = value.split(",");
    for (const tt of t) {
      const ttt = tt.split(".")[0];
      if (ttt in detailsAddons) {
        s[key] = value.replace(tt, detailsAddons[ttt]);
      }

    }
  }
  return s;
}


/**
 * Rename a key, making a new cloned object with that key renamed shallowly
 * @param object
 * @param oldKey
 * @param newKey
 */
export const renameKeyInObject = (object, oldKey, newKey) => {
  const cloned = {...object};
  cloned[newKey] = cloned[oldKey];
  delete cloned[oldKey];
  return cloned;
}
/**
 * normalize path, remove slugs and dynamic content
 * @param path
 */
const normalizePath = path => {
  const n = path => path.trim().toLowerCase().split("/").filter(s => !s.includes(":")).join("/");
  if (path && typeof path === 'object') {
    path.check = n(path.check);
    return path;
  }
  return n(path);
};

export enum eCheckType {
  EQUAL = 1,
  STARTSWITH = 2,
  ENDSWITH = 3,
  INCLUDES = 4,
};

/**
 * Check path against array of paths
 * @param pathname
 * @param checkAgainst
 */
const checkPathAgainst = (pathname, ...checkAgainst) => checkAgainst
  .find(check => {
    if (check && typeof check === 'object' && check.type) {
      switch (check.type) {
        case eCheckType.EQUAL:
          return check.check === pathname;
        case eCheckType.STARTSWITH:
          return pathname.startsWith(check.check);
        case eCheckType.ENDSWITH:
          return pathname.endsWith(check.check);
        case eCheckType.INCLUDES:
          return pathname.includes(check.check);
      }
    }
    return pathname.startsWith(check);
  });
const _getLastPossibleNavigation = (history, opposite, ...checkAgainst) => {
  if (history && Array.isArray(history) && history.length > 0) {
    checkAgainst = Array.isArray(checkAgainst) ? checkAgainst.filter(c => c).map(normalizePath) : [];
    const h = history
      .map(({pathname}) => normalizePath(pathname))
      .filter((pathname) => opposite ? !checkPathAgainst(pathname, ...checkAgainst) : checkPathAgainst(pathname, ...checkAgainst));
    
    if (h.length >= 1) {
      return h[h.length - 1];
    }
  }
  return null;
}


/**
 * Get latest possible path in history that match checkAgainst paths array
 * @param history
 * @param checkAgainst
 */
export const getLastPossibleNavigation = (history, ...checkAgainst) => _getLastPossibleNavigation(history, false, ...checkAgainst);

/**
 * Get last possible path in history that does not match checkAgainst paths array
 * @param history
 * @param checkAgainst
 */
export const getLastPossibleNavigationExcept = (history, ...checkAgainst) => _getLastPossibleNavigation(history, true, ...checkAgainst);
/**
 * turn object into query params
 * @param obj
 * @param prefix
 */
export const serializeObjectToQueryParams = (obj, prefix?) => {
  const str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serializeObjectToQueryParams(v, k) :
        encodeURIComponent(k) + "=" + encodeURIComponent(v));
    }
  }
  return str.join("&");
};
/**
 * Decode url component recursively until it stays the same
 * @param input
 */
export const decodeUrlComponentUntilNoChange = input => {
  if (!input)
    return input;
  const decoded = decodeURIComponent(input);
  if (decoded === input) {
    return decoded;
  }
  return decodeUrlComponentUntilNoChange(decoded);
}
/**
 * prepare string for searching,
 * remove everything expect lowercase english characters and numbers
 * @param name
 */
export const normalizeEnglishChars = name => {
  return name.toLowerCase().replace(/[^a-z0-9]/ig, "");
}

/**
 * link to component map object
 */
const linkMapToComponent = {};

/**
 * react lazy + preload + save to 'link to component map object'
 * @param path
 * @param factory
 * @param options
 */
export const lazyWithPreloadToPath = (path: string, factory, options?: { paths?: string[], preloadOnTimeout?: number }) => {
  if (options && typeof options === 'object') {
    const {paths, ...restOfOptions} = options;
    /**
     * if there are more paths in the options, execute on them
     */
    if (paths && paths.length) {
      for (const pathInOptions of paths) {
        if (path !== pathInOptions) {
          lazyWithPreloadToPath(path, factory, restOfOptions);
        }
      }
    }
    /**
     * Preload component anyway after miliseconds
     */
    if (options.preloadOnTimeout) {
      setTimeout(() => {
        loadComponentByPath(path);
      }, options.preloadOnTimeout);
    }
  }
  return linkMapToComponent[path] = lazyWithPreload(factory);
};

/**
 * Compare path1 to path2 using startsWith
 * @param path1
 * @param path2
 */
const comparePaths = (path1, path2) => {
  return path1 === path2 || path1.startsWith(path2) || path2.startsWith(path1);
};

const preloadPath = path => {
  if (path && path in linkMapToComponent && typeof linkMapToComponent[path].preload === 'function') {
    
    linkMapToComponent[path].preload();
  }
};

/**
 * use path to load component from the path to component object
 * @param path
 */
export const loadComponentByPath = (path: string) => {
  const keys = Object.keys(linkMapToComponent);
  for (const p of keys) {
    if (p === path) {
      preloadPath(path);
      return;
    }
  }
  path = normalizePath(path);
  const paths = keys
    .filter(linkMapPath => linkMapPath !== linksMap.root && comparePaths(normalizePath(linkMapPath), path))
    .sort((path1, path2) => path2.length - path1.length)
    .forEach(preloadPath);
};

/**
 * Process seo details that are relevant to the page, including replacing variables in text etc etc
 * @param path
 * @param preProcess
 * @param params
 */
export const pathToSeoDetail = (path: string, preProcess?, ...params) => {
  path = normalizePath(path);
  const linksMapValues = Object.values(linksMap);
  const paths = linksMapSeo[path] ? [path] : linksMapValues
    .filter(linkMapPath => linkMapPath !== linksMap.root && comparePaths(normalizePath(linkMapPath), path))
    .sort((path1, path2) => path2.length - path1.length);
  if (paths.length > 0) {
    const p = {...linksMapSeo[paths[0]]};
    if (typeof preProcess === 'object') {
      const preProcessObject = preProcess;
      preProcess = input => {
        for (const key in preProcessObject) {
          input = input.replace(new RegExp(`%${key}%`, "g"), preProcessObject[key]);
        }
        return input;
      };
    }
    const preProcessExist = typeof preProcess === 'function';
    for (const key in p) {
      if (p[key] && typeof p[key] === 'string') {
        p[key] = preProcessExist ? preProcess(p[key], ...params) : p[key].replace(/(\%.*?\%)/g, '');
      }
    }

    return p;
  }
  return generateLinkMapSeoDetail(path.split("/").join(" "));
};


export const useOnceCall = (cb: any, condition = true) => {
  const isCalledRef = React.useRef(false);
  React.useEffect(() => {
    if (condition && !isCalledRef.current) {
      isCalledRef.current = true;
      cb();
    }
  }, [cb, condition]);
};


export const replaceAllCaseInsensitive = (input, needle, value = '') => input.replace(new RegExp(needle, "ig"), value);
export const replaceArrayAllCaseInsensitive = (input, needles, value = '') => {
  for (const needle of needles) {
    input = replaceAllCaseInsensitive(input, needle, value);
  }
  return input;
};
