import {createReducer} from 'redux-starter-kit';
import {
  authenticationInitialized,
  authenticationUnInitialize,
  forceOfflineLogout,
  setEmailIsVerifiedState
} from '../actions/authenticationActions';
import {setUserDetails} from 'data/redux/actions/usersActions';

export interface IUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  allowAdvertisementEmails: boolean;
  isVerified?: boolean;
  createdAt: number;
  updatedAt: number;
  profilePicture?: string;
  facebookId?: string;
  googleId?: string;
}

const initialNavigationReducerState = {
  initialized: false,
  initializationTime: 0,
  emailVerification: null,
};

const authenticationReducer = createReducer(initialNavigationReducerState, {
  [authenticationInitialized.type]: (state, action) => {
    state.initialized = true;
    state.initializationTime = new Date().getTime();
  },
  [authenticationUnInitialize.type]: (state: any, action) => {
    state.initialized = false;
    state.initializationTime = new Date().getTime();
  },
  [setUserDetails.type]: (state: any, {payload}) => {
    state.user = {...state.user, ...payload};
    state.initializationTime = new Date().getTime();
    state.emailVerification = null;
  },
  SERVICES_AUTHENTICATION_AUTHENTICATE_FULFILLED: (state: any, {payload}) => {
    state.initializationTime = new Date().getTime();
    state.emailVerification = null;
  },
  SERVICES_AUTHENTICATION_LOGOUT: (state: any, {payload}) => {
    state.initializationTime = new Date().getTime();
    state.emailVerification = null;
  },
  [forceOfflineLogout.type]: (state: any) => {
    state.user = null;
    state.isSignedIn = false;
    state.isLoading = null;
    state.isError = null;
    state.token = null;
    state.ignorePendingAuth = false;
    state.initializationTime = new Date().getTime();
  },
  [setEmailIsVerifiedState.type]: (state: any, {payload}) => {
    state.emailVerification = {
      ...(state.emailVerification || {}),
      ...payload
    };
    if (payload.verified && state.user) {
      state.user.isVerified = true;
    }
  },
});
export default authenticationReducer;
