import React from "react";
import {MDBNavbarBrand, MDBNavLink} from 'mdbreact';
import {loadComponentByPath} from 'data/utils/shortcuts';
import styles from './LinkElement.module.scss';
import SkeletonPlaceHolder from 'gui/components/skeletonPlaceHolder';

export interface ILinkElementProps {
  currentPathName?: string;
  text?: string;
  icon?: string;
  to?: string;
  href?: string;
  title?: string;
  brand?: boolean;
  src?: string;
  alt?: string;
  active?: boolean;
  className?: string;
  onMenuSelect?: (to?: any, alreadyLink?: boolean, e?: any) => void;
  afterMenuSelect?: (props?: any) => void;
  children?: any;
  style?: any;
  imgStyle?: any;
  onlyClick?: boolean;
  activeToLogic?: (to: string, compareTo: string) => boolean;
  activeTos?: string[];
  componentMode?: boolean;
  loading?: boolean;
}

/**
 *
 * @param to
 * @param activeTos
 * @param compareTo
 * @param activeToLogic
 */
const activeLogicProcess = (to: string, activeTos: string[], compareTo: string, activeToLogic) => {
  if (activeToLogic) {
    if (activeTos && Array.isArray(activeTos)) {
      for (const activeTo of activeTos) {
        if (activeToLogic(activeTo, compareTo) === true) {
          return true;
        }
      }
    } else {
      return activeToLogic(to, compareTo);
    }
  }
  return false;
};

const LinkElement = ({
                       currentPathName,
                       afterMenuSelect,
                       activeToLogic,
                       to,
                       ...props
                     }: ILinkElementProps) => {
  if (props.loading) {
    return (
      <SkeletonPlaceHolder
        className={props.className}
        style={props.style}
      />
    );
  } else if (props.componentMode) {
    return (
      <span
        onClick={(e) => props.loading ? null : props.onMenuSelect(props.href, true, e)}
        className={`nav-link Ripple-parent${props.className ? ` ${props.className}` : ''} ${styles.navLink}`}
        style={props.style}
        title={props.title}
      >
        {props.children}
      </span>
    );
  } else if (props.onlyClick) {
    return props.children;
  } else if (props.brand) {
    return (
      <MDBNavbarBrand className={props.className}
                      onClick={(e) => props.onMenuSelect(to, true, e)}
                      href={props.href || to}
                      style={props.style}
                      onMouseEnter={() => loadComponentByPath(props.href || to)}
                      aria-label={props['aria-label']}
      >
        {props.src &&
          <img className="navbar-link"
               style={props.imgStyle}
               referrerPolicy="no-referrer"
               src={props.src}
               alt={props.alt}/>}
        {props.children}
      </MDBNavbarBrand>
    );
  } else if (props.href) {
    return (
      <a
        onClick={(e) => props.onMenuSelect(props.href, true, e)}
        target="_blank"
        rel="noopener noreferrer"
        className={`nav-link Ripple-parent${props.className ? ` ${props.className}` : ''} ${styles.navLink}`}
        href={props.href}
        onMouseEnter={() => loadComponentByPath(props.href)}
        style={props.style}
        title={props.title}
        aria-label={props['aria-label']}
      >
        {props.children}
      </a>
    );
  } else {
    return (<MDBNavLink
      exact
      active={props.active || activeLogicProcess(to, props.activeTos, currentPathName, activeToLogic)}
      onClick={(e) => props.onMenuSelect(to, true, e)}
      to={to}
      onMouseEnter={() => loadComponentByPath(props.href || to)}
      style={props.style}
      className={props.className}
      aria-label={props['aria-label']}
    >{props.children}
    </MDBNavLink>)
  }
}


LinkElement.defaultProps = {
  currentPathName: "",
  text: "",
  icon: null,
  to: "",
  brand: false,
  src: null,
  alt: "",
  style: {},
  className: null,
  onlyClick: false,
  onMenuSelect: function () {
  },
  afterMenuSelect: null,
  activeToLogic: (to, compareTo) => to === compareTo,
};

export default React.memo(LinkElement);
