export enum PERMISSION {
  NONE = 0,
  VIEW = 0b001,
  DELETE = 0b010,
  EDIT = 0b100,
  VIEW_EDIT_DELETE = 0b001 | 0b010 | 0b100,
}

export const VIEW_PRODUCT_PERMISSIONS = [
  PERMISSION.VIEW,
  PERMISSION.DELETE,
  PERMISSION.EDIT,
  PERMISSION.VIEW_EDIT_DELETE
];

export const OWNER_PERMISSIONS = [PERMISSION.VIEW_EDIT_DELETE, PERMISSION.EDIT, PERMISSION.DELETE];
