import React from "react";
import styles from "./Footer.module.scss";

import LinkBlockContainer from 'data/redux/containers/linkBlockContainer/LinkBlockContainer';
import {linksMap} from 'data/utils/constants';
import DealAndGoLogo from "../navigation/components/dealAndGoLogo/DealAndGoLogo";

interface IFooterProps {

}

const Footer = (props: IFooterProps) => {
  return (
    <div className={`${styles.footer} font-small pt-4`}>
      <div className={styles.row}>
        <DealAndGoLogo noPadding/>
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <LinkBlockContainer className="" to={linksMap.about}>
            עלינו
          </LinkBlockContainer>
          <LinkBlockContainer className="" to={linksMap.howItWorksBuyer}>
            איך זה עובד
          </LinkBlockContainer>
          <LinkBlockContainer className="" to={linksMap.faq}>
            שאלות ותשובות
          </LinkBlockContainer>
          <LinkBlockContainer className="" to={linksMap.contactUs}>
            צור קשר
          </LinkBlockContainer>
        </div>
        <div className={styles.col}>
          <LinkBlockContainer className="" to={linksMap.terms}>
            תנאי שימוש - תקנון
          </LinkBlockContainer>
          <LinkBlockContainer className="" to={linksMap.accessibility}>
            הצהרת נגישות
          </LinkBlockContainer>
          <LinkBlockContainer className="" to={linksMap.privacyPolicy}>
            מדיניות פרטיות
          </LinkBlockContainer>
          <LinkBlockContainer className="" to={linksMap.tourism}>
            תיירות
          </LinkBlockContainer>
          <LinkBlockContainer className="" to={linksMap.shopping}>
            חנויות ופנאי
          </LinkBlockContainer>
        </div>
      </div>
      <div className={styles.copyRightFooter}>
        <div className={styles.row}>
          <div>
            Made with ❤ By&nbsp;
            <a href="https://www.gemplan.co.il/" target="_blank" rel="noopener noreferrer">GemPlan</a>
          </div>
          <div>
            Deal & Go 2020 ©
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {};

export default React.memo(Footer);
