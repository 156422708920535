import React from "react";
import styles from './FormErrorDisplay.module.scss';
import {FormHelperText} from '@material-ui/core';
import {FieldProps} from "formik";

interface IFormErrorDisplayProps {
  form?: any;
  error?: string;
  className?: string;
  field?: { name: string, value: any };
}

const extractError = props => {
  if (props.form && props.field) {
    const touched = props.form.touched[props.field.name];
    const error = touched && props.form.errors[props.field.name];
    const value = props.field.value;
    return error;
  }
  return null;
}

const FormErrorDisplay = ({error, ...props}: IFormErrorDisplayProps) => {
  const _error = error || extractError(props);
  if (_error) {
    return (
      <div className={`${styles.FormErrorDisplayContainer} ${props.className}`.trim()}>
        <FormHelperText
          className={styles.FormErrorDisplay}
          error>
          {_error}
        </FormHelperText>
      </div>
    );
  } else {
    return null;
  }
};

FormErrorDisplay.defaultProps = {
  className: '',
  error: '',
};

export default React.memo(FormErrorDisplay);
