import {setLoadingState} from 'data/redux/actions/guiActions';
import {httpConnectionString, services} from 'data/redux/reducers/feathersReducers';
import {createAction} from 'redux-starter-kit';
import {
  fileMimeSelector,
  fileNameSelector,
  fileProductsSelector,
  productDocumentFilesSelector
} from 'data/redux/selectors/productSelector';
import {xxHashBlob} from 'data/utils/calculators';
import {saveAs} from 'file-saver';
import {authenticatedFetch, authenticatedXHR, promiseWait} from 'data/utils/shortcuts';
import {IDocumentFile} from "../../interfaces/documents";


export const setDocumentToProduct = createAction("setDocumentToProduct");
export const setThumbnailToFile = createAction("setThumbnailToFile");
export const setFileIsLoading = createAction("setFileIsLoading");
export const removeFileFromProduct = createAction("removeFileFromProduct");
export const removeAllFileFromProduct = createAction("removeAllFileFromProduct");

export function getUploadFilesIntoProduct(product_id: number, files: File[]) {
  return (dispatch, getState) => {
    const currentFiles = productDocumentFilesSelector(getState());
    if (files.length > 0) {
      /*

      const fd = new FormData();
      files.forEach(file => {
        fd.append('file[]', file);
      });
      return authenticatedFetch(`${httpConnectionString}/documents?product_id=${product_id}`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: fd
      });
    */
      const onProgressCallback = (progress) => {
        console.log(`Bytes sent: ${progress.bytes}, Percent: ${progress.percent}`);
      };

      const fd = new FormData();
      files.forEach(file => {
        fd.append('file[]', file);
      });

      return authenticatedXHR(
        `${httpConnectionString}/documents?product_id=${product_id}`,
        {
          method: 'POST',
          body: fd,
        },
        onProgressCallback
      );
    }
  }
}

export function getCreateDocumentWithFilesForProduct(product_id: number, files: File[], skipLoading = false) {
  return (dispatch, getState) => {
    if (files.length > 0) {
      if (skipLoading !== true) {
        dispatch(setLoadingState(true));
      }
      return dispatch(services
        .documents
        .create({
          product_id,
        }))
        .then(response => dispatch(getUploadFilesIntoProduct(product_id, files)))
        .then(result => {
          if (skipLoading !== true) {
            dispatch(setLoadingState(false));
          }
          return result;
        })
        .then(result => dispatch(getLoadInitialFiles(product_id)))
        .catch(e => {
          dispatch(setLoadingState(false));
          return Promise.reject(e);
        });
    }
  };
}

export function getDeleteFileFromDocumentForProduct(product_id: number, file: IDocumentFile) {
  return (dispatch, getState) => {
    if (file.uploadedFile) {
      dispatch(setLoadingState(true));
      return dispatch(services
        .documents
        .remove(product_id, {
          query: {
            file: {
              id: file.id || file.uploadedFile.id,
            },
            noWait: false,
          }
        }))
        .then(result => {
          dispatch(removeFileFromProduct({
            product_id,
            file_id: file.uploadedFile.id,
          }));
          dispatch(setLoadingState(false));
          return result;
        })
        .catch(e => {
          dispatch(setLoadingState(false));
          return Promise.reject(e);
        });
    }
  };
}

export function getDeleteAllFilesFromDocumentForProduct(product_id: number) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services
      .documents
      .remove(product_id, {
        query: {
          all: true,
          noWait: false,
        }
      }))
      .then(result => {
        dispatch(removeAllFileFromProduct({
          product_id,
        }));
        dispatch(setLoadingState(false));
        return result;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.reject(e);
      });
  };
}

export function getLoadInitialFiles(product_id) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services
      .documents
      .get(product_id))
      .then(v => {
        v.value.product_id = product_id;
        dispatch(setLoadingState(false));
        dispatch(setDocumentToProduct(v.value));
        return v.value;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.resolve();
      });

  };
}

const tempImageHash = '88e50b4';
const thumbnailBlobCache = {};

export function getLoadFileThumbnail(file_id: string) {
  return (dispatch, getState) => {
    if (file_id) {
      if (file_id in thumbnailBlobCache) {
        dispatch(setThumbnailToFile({
          file_id,
          thumbnail: thumbnailBlobCache[file_id],
        }));
        return Promise.resolve(thumbnailBlobCache[file_id]);
      } else {

        const product = fileProductsSelector(file_id)(getState())[0];
        if (product) {
          return authenticatedFetch(`${httpConnectionString}/documents/product/${product.id}/file/${file_id}/thumbnail`)
            .then(response => response.blob())
            .then(async image => {
              const hash = await xxHashBlob(image);
              const mime = fileMimeSelector(file_id)(getState());
              if (hash === tempImageHash && (mime.includes("image") || mime.includes("pdf") || mime.includes("doc"))) {
                await promiseWait(2000);
                return dispatch(getLoadFileThumbnail(file_id));
              } else {
                const thumbnail = URL.createObjectURL(image);
                thumbnailBlobCache[file_id] = thumbnail;
                dispatch(setThumbnailToFile({
                  file_id,
                  thumbnail: thumbnail,
                }));
                return thumbnail;
              }
            });
        }
      }
    }
    return Promise.resolve(null);

  }
}

const fileBlobCache = {};

export function getLoadFileDownload(file_id: string) {
  return (dispatch, getState) => {
    if (file_id) {
      if (file_id in fileBlobCache) {
        saveAs(fileBlobCache[file_id], fileNameSelector(file_id)(getState()));
        return Promise.resolve(fileBlobCache[file_id]);
      } else {
        const product = fileProductsSelector(file_id)(getState())[0];
        if (product) {
          dispatch(setFileIsLoading(
            {
              file_id,
              loading: true,
            }
          ));
          return authenticatedFetch(`${httpConnectionString}/documents/product/${product.id}/file/${file_id}`)
            .then(response => response.blob())
            .then((blob) => {
              fileBlobCache[file_id] = new Blob([blob], {type: fileMimeSelector(file_id)(getState())});
              saveAs(fileBlobCache[file_id], fileNameSelector(file_id)(getState()));
              dispatch(setFileIsLoading(
                {
                  file_id,
                  loading: false,
                }
              ));
              return fileBlobCache[file_id];
            });
        }
      }
    }
    return Promise.resolve(null);

  }
}
