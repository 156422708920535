import {createSelector} from 'redux-starter-kit';
import {noEmailHostPlaceHolder} from "../constants/userConstants";

export const profilePictureSelector = (() => createSelector(["auth.user.profilePicture"]))();
export const profileFirstNameSelector = (() => createSelector(["auth.user.firstName", "auth.user.lastName"], (firstName, lastName) => {
  return firstName || lastName;
}))();

export const generateFullName = (userDetails) => {
  const {firstName, lastName} = userDetails || {};
  return [firstName, lastName]
    .filter(s => s)
    .join(" ");
}

export const profileFirstLastNameSelector = (() => createSelector(["auth.user"], generateFullName))();

export const profileEmailSelector = (() => createSelector(["auth.user.email"], (email) => {
  if (isValidEmail(email)) {
    return email;
  }
  return '';
}))();
export const userSelector = (() => createSelector(["auth.user"]))();
export const userIdSelector = (() => createSelector(["auth.user.id"]))();

export const userPhoneSelector = (() => createSelector(["auth.user.phone"]))();

export const userIsAllowed = ((type: number) => createSelector(["auth.initialized", "auth.user.type"], (isInitialized, user_type) => {
  return !isInitialized || !type || !!(type & user_type);
}));

const isValidEmail = (_email) => {
  const email = (_email || '').trim().toLowerCase();
  return !!(email && !email.endsWith(noEmailHostPlaceHolder));
};

export const isValidEmailSelector = (() => createSelector(["auth.user.email"], (email) => {
  return isValidEmail(email);
}))();

export const isTokenExpired = (() => createSelector(["auth.user.isTokenExpired"], (isTokenExpired) => {
  return isTokenExpired;
}))();


/**
 * for now all email is verified...fix later cuz no time
 */
export const userIsEmailVerified = (() => createSelector(["auth.initialized", "auth.user.isVerified"], (isInitialized, isVerified) => {
  return !isInitialized || !!isVerified;
}))();
