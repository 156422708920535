import {isLoggedInSelector} from 'data/redux/selectors/authenticationSelector';
import {linksMap, USER_TYPE_ANY} from 'data/utils/constants';
import {navigateIfNotThere, navigateToPageBeforeLogin} from 'data/redux/actions/navigationActions';
import {userIsAllowed, userIsEmailVerified} from 'data/redux/selectors/userSelector';

export const AuthenticatedMapStateToProps = (state, ownProps = {userType: USER_TYPE_ANY}) => {
  return {
    signedIn: isLoggedInSelector(state),
    isEmailVerified: userIsEmailVerified(state),
    allowed: userIsAllowed(ownProps.userType)(state),
  };
};

export const AuthenticatedMapDispatchToProps = (dispatch) => {
  return {
    navigateOnLogout: () => dispatch(navigateIfNotThere(linksMap.login)),
    navigateOnLogin: () => dispatch(navigateToPageBeforeLogin()),
    navigateOnDisallowed: () => dispatch(navigateIfNotThere(linksMap.dashboard)),
    navigateOnEmailNotValidated: () => dispatch(navigateIfNotThere(linksMap.verifyToken)),
  };
};

