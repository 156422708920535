import {responsiveStateReducer, responsiveStoreEnhancer} from 'redux-responsive'
import {configureStore, getDefaultMiddleware} from 'redux-starter-kit';
import navigationReducer from 'data/redux/reducers/navigationReducer';
import {listenForHistoryChange} from 'redux-location-state';
import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk';
import createWaitForState from 'redux-wait-for-state'
import guiReducer from 'data/redux/reducers/guiReducer';
import {loadState, saveState} from 'data/redux/middlewares/localStorageMiddleWare';
import {connectRouter, routerMiddleware} from 'connected-react-router'
import feathersReducers, {
  attachAuthenticationEvents,
  attachEventListeners,
  feathersAuthentication
} from 'data/redux/reducers/feathersReducers';
import notificationReducer from 'data/redux/reducers/notificationReducer';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import authenticationReducer from 'data/redux/reducers/authenticationReducer';
import reduceReducers from 'reduce-reducers';
import productsReducer from 'data/redux/reducers/productsReducer';
import ordersReducer from 'data/redux/reducers/ordersReducer';
import transactionsReducer from 'data/redux/reducers/transactionsReducer';
import errorHandlerMiddleWare from 'data/redux/middlewares/errorHandlingMiddleWare';


const {waitForState, setStore} = createWaitForState();
const defaultMiddleWare = getDefaultMiddleware();
if (defaultMiddleWare.length > 2) {
  defaultMiddleWare.pop();
}
export const history = createBrowserHistory();

const store = configureStore({
  reducer: {
    navigation: navigationReducer,
    gui: reduceReducers(responsiveStateReducer, guiReducer as any),
    //router: connectRouter(history),
    router: connectRouter(history),
    notifications: notificationReducer,
    auth: reduceReducers(feathersAuthentication.reducer, authenticationReducer),
    products: productsReducer,
    orders: ordersReducer,
    transactions: transactionsReducer,
    ...feathersReducers
  },
  middleware: [
    thunk.withExtraArgument({waitForState}),
    reduxPromiseMiddleware,
    errorHandlerMiddleWare,
    saveState(),
    routerMiddleware(history),
  ],
  preloadedState: loadState(),
  enhancers: [responsiveStoreEnhancer],
});

listenForHistoryChange(store, history);
setStore(store);


attachEventListeners([
  {
    name: "products",
    types: ["created", "updated", "removed", "patched"]
  }
], store);
attachAuthenticationEvents(store);

export default store;
