import {setLoadingState} from 'data/redux/actions/guiActions';
import {services} from 'data/redux/reducers/feathersReducers';
import {IUser} from 'data/redux/reducers/authenticationReducer';
import {userIdSelector} from 'data/redux/selectors/userSelector';
import {discardObjectKeys} from 'data/utils/calculators';
import {createAction} from 'redux-starter-kit';
import {isLoggedInSelector} from 'data/redux/selectors/authenticationSelector';

export const setUserDetails = createAction("setUserDetails");

export function getPatchUserActionCreator(formValues: IUser) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services
      .users
      .patch(userIdSelector(getState()), {
        ...discardObjectKeys(formValues, (key, value) => value === null || value === undefined, (key) => ['id', 'createdAt', 'updatedAt'].includes(key))
      }))
      .then(result => {
        dispatch(setLoadingState(false));
        return result;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        if (e.type.toLowerCase() === "featherserror") {
          if (e.className === 'conflict') {
            return Promise.resolve(formValues);
          }
        }
        return Promise.reject(e);
      });
  };
}

export function getDeleteUserActionCreator(userId: number) {
  return (dispatch, getState, {waitForState}) =>
    waitForState(state => isLoggedInSelector(state))
      .then(() => dispatch(setLoadingState(true)))
      .then(() => dispatch(services.users
        .remove(userId)))
      .then(({value}) => {
        dispatch(setLoadingState(false));
        return value;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.resolve();
      });
}

export function createSupportMessageActionCreator(formValues) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services
      .support
      .create(formValues))
      .then(({value}) => {
        dispatch(setLoadingState(false));
        return value;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.resolve({});
      });
  };
}
