import React from "react";

import {Skeleton} from '@material-ui/lab';

export enum eSkeletonVariant {
  rect = "rect",
  text = "text",
  circle = "circle",
}

export enum eSkeletonAnimation {
  pulse = "pulse",
  wave = "wave",

}


interface ISkeletonPlaceHolderProps {
  variant: eSkeletonVariant | string,
  animation: eSkeletonAnimation | string,
  className?: string;
  style?: any;
  width?: number;
  height?: number;
  children?: any;
}


const SkeletonPlaceHolder = (props: ISkeletonPlaceHolderProps) => (
  <Skeleton
    {...props as any}
  />
);

SkeletonPlaceHolder.defaultProps = {
  variant: eSkeletonVariant.rect,
  animation: eSkeletonAnimation.wave,
  style: {},
};

export default React.memo(SkeletonPlaceHolder);
