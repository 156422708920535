import React from "react";
import styles from './DealAndGoNavButtons.module.scss';
import PublishButton from './components/publishButton';
import RegisterButton from './components/registerButton';
import LoginButton from './components/loginButton';

interface IDealAndGoNavButtonsProps {

}

const DealAndGoNavButtons = (props: IDealAndGoNavButtonsProps) => {
  return (
    <div className={`${styles.nav} DealAndGoNavButtonsContainer`}>
      <LoginButton className={styles.row}/>
      <RegisterButton className={styles.row}/>
      <PublishButton className={styles.row}/>
    </div>
  );

};

DealAndGoNavButtons.defaultProps = {};

export default React.memo(DealAndGoNavButtons);
