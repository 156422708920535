import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './App.css';
import MainRouter from './pages/mainRouter/MainRouter';
import {ErrorBoundary as _ErrorBoundary} from 'react-error-boundary'
import EnableAccessibility from 'gui/components/enableAccessibility';
import OrganizationLogoJsonD from 'gui/components/organizationLogoJsonD';
import SeoContainer from 'data/redux/containers/seoContainer';
import {Alert, AlertTitle} from '@material-ui/lab';
import {Box, Button, Container, Grid} from '@material-ui/core';


const ErrorFallback = ({error, resetErrorBoundary}) => (
  <Container maxWidth="sm">
    <Grid container item xs={12}>
      <Box component="span" m={1}>
        <Alert severity="info">
          <AlertTitle>האתר התעדכן</AlertTitle>
          <Grid container item xs={12}>
            האתר התעדכן הנה לחץ פה כדי לקבל את העדכון
          </Grid>
          <Grid container item xs={12}>
            <Button variant="contained" color="primary" disableElevation onClick={() => (location as any).reload(true)}>
              עדכן
            </Button>
          </Grid>
        </Alert>
      </Box>
    </Grid>
  </Container>
);


const ErrorBoundary: any = _ErrorBoundary;


const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <SeoContainer/>
      <OrganizationLogoJsonD/>
      <EnableAccessibility/>
      <MainRouter/>
    </ErrorBoundary>
  );
};

export default App;
