import {createReducer} from 'redux-starter-kit';
import {clearTransaction, setTransaction} from 'data/redux/actions/transactionsActions';
import {removeObjectFromArrayWithMutation} from 'data/utils/shortcuts';
import {ITransaction} from 'data/interfaces/transactions';


const initialProductsReducerState: ITransaction[] = [];

const removeTransactionOfProduct = (payload, state) => {
  removeObjectFromArrayWithMutation(state, (transaction) => {
    return transaction.product_id === payload.product_id;
  });
}

const transactionsReducer = createReducer(initialProductsReducerState, {
  [setTransaction.type]: (state, {payload}) => {
    removeTransactionOfProduct(payload, state);
    state.push(payload);
  },

  [clearTransaction.type]: (state, {payload}) => {
    removeTransactionOfProduct(payload, state);
  },
});
export default transactionsReducer;
