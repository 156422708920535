import {services} from 'data/redux/reducers/feathersReducers';
import {createAction} from 'redux-starter-kit';
import {setLoadingState} from './guiActions';
import {IProduct} from 'data/interfaces/products';
import {ITransaction} from 'data/interfaces/transactions';
import {IOrder, IOrderOptions} from 'data/interfaces/orders';
import {isLoggedInSelector} from 'data/redux/selectors/authenticationSelector';
import {userIdSelector, userIsAllowed} from 'data/redux/selectors/userSelector';
import uniqWith from 'uniq-with';
import {getInitializationTimeStamp, isLoadingSelector} from 'data/redux/selectors/guiSelector';
import {allProductTypesWithAddons} from '../constants/productsConstants';
import {
  allOrderStates,
  ORDER_STATUS_TYPE_OPEN,
  ORDER_STATUS_TYPE_SOLD,
  orderAssociatedStates
} from 'data/redux/constants/ordersConstants';
import {generateStatusQuery} from 'data/utils/actionTools';
import {promiseAwaitIgnoreReject} from 'data/utils/shortcuts';
import {preProcessReceivedProduct} from 'data/redux/actions/productsActions';
import {USER_TYPE_ADMIN} from "../../utils/constants";


export const setOrdersServiceRequestSkip = createAction("setOrdersServiceRequestSkip");
export const setOrdersServiceRequestLimit = createAction("setOrdersServiceRequestLimit");
export const setOrdersServiceRequestTotal = createAction("setOrdersServiceRequestTotal");
export const setOrderList = createAction("setOrderList");
export const setOrderStatusCount = createAction("setOrderStatusCount");
export const setOrder = createAction("setOrder");
export const updateOrderModifications = createAction("updateOrderModifications");

const preProcessOrder = async (order: IOrder, state) => {
  order.retrievedAt = getInitializationTimeStamp(state);
  return order;
}

const preProcessOrders = async ({value, ...rest}, state) => {
  return {
    ...rest,
    value: {
      ...value,
      data: await Promise.all(uniqWith(((row1, row2) => row1.id === row2.id), value.data).map((product) => preProcessOrder(product, state)))
    }
  };
}


export function getInsertOrderActionCreator(
  product: IProduct,
  options: IOrderOptions,
  transaction: ITransaction) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services
      .orders
      .create({
        product_id: product.id,
        transaction: transaction.uniqueID,
        transaction_type: transaction.transaction_type,
        options: options
      }))
      .then(result => {
        dispatch(setLoadingState(false));
        return result;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.reject(e);
      });
  };
}


/**
 * Generate proper state query using both, redux state, url and options
 * @param options
 * @param getState
 */
const generateOrderStatusQuery = (options, getState) => {
  return generateStatusQuery(options, getState, orderAssociatedStates, allOrderStates);
}

export function getLoadUserOrdersActionCreator(userId?: number, limit = null, skip = null, options: { query?: any, type?: string, status?: number, statusCount?: boolean } = {}) {
  return ((dispatch, getState, {waitForState}) => {
    const query = generateOrderStatusQuery(options, getState);
    return waitForState(state => isLoggedInSelector(state))
      .then(() => dispatch(setOrderList({
        type: "myOrders"
      })))
      .then(() => {
        dispatch(setLoadingState(true));
        return userId || userIdSelector(getState());
      })
      .then((userId) => dispatch(services.orders.find({
        query: {
          $joinRelation: `[]`,
          $eager: `[productDetails.[${allProductTypesWithAddons.join(",")},assets.[asset],saleDetails.currencyDetails],passengerDetails]`,
          $skip: skip || getState().products.myProducts.serviceRequestProperties.skip,
          $limit: limit || getState().products.myProducts.serviceRequestProperties.limit,
          'user_id': userId,
          ...(options.statusCount ? {"$modify": 'statusCounts'} : {}),
          $sort: {
            updatedAt: -1
          },
          ...query,

        }
      })))
      .then(({value}) => {
        if (options.statusCount) {
          dispatch(setOrderStatusCount({
            ...value,
            type: "myOrders"
          }));
        } else {
          value.data = value.data.map((order) => {
            if (order.passengerDetails)
              order.options = {
                passengers: order.passengerDetails,
                ...(order.options ? order.options : {})
              };
            return order;
          });
          dispatch(setOrderList({
            ...value,
            type: "myOrders"
          }));
          dispatch(setLoadingState(false));
        }
        return value;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.resolve();
      });
  });
}

export function getLoadProductOrdersActionCreator(productId: number, userId?: number) {
  const types = [];
  return (dispatch, getState) => {
    if (isLoggedInSelector(getState())) {
      const isAdmin = userIsAllowed(USER_TYPE_ADMIN)(getState());
      return dispatch(services.orders
        .find({
          query: {
            product_id: productId,
            $eager: `[${[
              'passengerDetails',
              ...(isAdmin ? ['userDetails'] : [])
            ].join(',')}]`,
            $skip: 0,
            ...(userId ? {'user_id': userId} : {}),
            status: {
              $in: [
                ...orderAssociatedStates[ORDER_STATUS_TYPE_OPEN],
                ...orderAssociatedStates[ORDER_STATUS_TYPE_SOLD],
              ],
            },
          }
        }))
        .then(({value}) => {
          value.data = value.data.map((order) => {
            if (order.passengerDetails)
              order.options = {
                passengers: order.passengerDetails,
                ...(order.options ? order.options : {})
              };
            return order;
          });
          dispatch(setOrderList({
            ...value,
            type: "productOrders"
          }));
          return value;
        })
        .catch(e => {
          return Promise.resolve();
        });
    }
    return Promise.resolve(null);
  };
}

export function getLoadProductOrdersCurrentUserActionCreator(productId) {
  return async (dispatch, getState, {waitForState}) => {
    await waitForState(state => isLoggedInSelector(state));
    return dispatch(getLoadProductOrdersActionCreator(productId, userIdSelector(getState)));
  };
}

const preProcessReceivedOrder = async (order: IOrder, state) => {
  if (order.productDetails) {
    order.productDetails = await preProcessReceivedProduct(order.productDetails, state);
  }
  order.retrievedAt = getInitializationTimeStamp(state);
  return order;
}


const preProcessReceivedOrders = async ({value, ...rest}, state) => {
  return {
    ...rest,
    value: {
      ...value,
      data: await Promise.all(uniqWith(((row1, row2) => row1.id === row2.id), (value.data || []).filter(row => row && row.id)).map((product) => preProcessReceivedOrder(product, state)))
    }
  };
}


/**
 * Get products of a certain type
 * @param type
 * @param skip
 * @param limit
 * @param queryOptionsList
 */
export function getLoadOrdersByTypeActionCreator(type, skip = 0, limit = 10, ...queryOptionsList: {}[]) {
//  console.log(3123123, type, skip, limit, queryOptionsList);
  return (dispatch, getState) =>
    promiseAwaitIgnoreReject(queryOptionsList
      .flat()
      .map(({$eagerAdditions, $joinRelationArray, ...queryOptions}: any) => dispatch(services.orders
        .find({
          query: {
            $skip: skip,
            $limit: limit,
            ...queryOptions,
          }
        }))))
      .then(results => ({
        value: {
          ...results[0].value,
          total: results.map(({value}) => value.total).reduce((a, b) => a + b, 0),
          data: results.map(({value}) => value.data).flat(),
          originalResults: results
        }
      }))
      .then((result) => preProcessReceivedOrders(result, getState()))
      .then(({value}: any) => {
        dispatch(setOrderList({
          data: value.data,
          type: type
        }));
        return value.data;
      })
      .catch(e => {
        
        return Promise.resolve([]);
      });
}

export function getDeleteOrderActionCreator(orderId: number) {
  return (dispatch, getState, {waitForState}) =>
    waitForState(state => isLoggedInSelector(state))
      .then(() => dispatch(setLoadingState(true)))
      .then((userId) => dispatch(services.orders
        .remove(orderId)))
      .then(({value}) => {
        dispatch(setLoadingState(false));
        return value;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.resolve();
      });
}

export function getUpdateOrderActionCreator(order: IOrder) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services
      .orders
      .patch(order.id,
        order))
      .then(({value}) => {
        
        dispatch(updateOrderModifications(preProcessOrder(value, getState())));
        dispatch(setLoadingState(false));
        return value;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.reject(e);
      });
  };
}

export function cancelOrder(product: IProduct) {
  return (dispatch) => {
    return dispatch(setOrder({
      product_id: product.id,
    }));
  };
}


export function beginOrder(product: IProduct, options: IOrderOptions = {}) {
  return (dispatch) => {
    return dispatch(setOrder({
      product_id: product.id,
      options: options,
    }));
  };
}

export function completeOrder(product: IProduct, options: IOrderOptions = {}, transaction: ITransaction) {
  return (dispatch, getStatus) => {
    return dispatch(getInsertOrderActionCreator(product, options, transaction))
      .then(({value}) => {
        if (value.id && value.id > 0) {
          dispatch(setOrder({
            product_id: product.id,
            options: options,
            ...value,
          }));
        }
      });
  };
}
