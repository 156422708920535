import {createReducer} from 'redux-starter-kit';
import {
  setProduct,
  setProductList,
  setProductsServiceRequestLimit,
  setProductsServiceRequestSkip,
  setProductsServiceRequestTotal,
  setProductStatusCount,
  updateProductModifications,
} from 'data/redux/actions/productsActions';
import {typeToTableName} from 'data/redux/constants/productsConstants';
import {IProduct} from 'data/interfaces/products';
import {
  removeAllFileFromProduct,
  removeFileFromProduct,
  setDocumentToProduct,
  setFileIsLoading,
  setThumbnailToFile
} from 'data/redux/actions/documentsActions';
import {getAllProducts, getDocuments, getDocumentsFiles} from 'data/redux/selectors/productSelector';
import {removeObjectFromArrayWithMutation} from 'data/utils/shortcuts';
import {cloneDeep, merge} from 'lodash';


export interface IServiceRequestProperties {
  total?: number;
  skip: number;
  limit: number;
}

export interface ITransaction {
  url: string;
  token: string;
  uniqueID: string;
  user_id: number;
  product_id: number;
  id: number;
  type: string;
  ErrorCode: string;
  ErrorText: string;
  lang: string;
}

interface IProductReducerState {
  [key: string]: {
    serviceRequestProperties?: IServiceRequestProperties;
    products?: Partial<IProduct>[];
    product?: Partial<IProduct>;
    statusCount?: any;
    hash?: string;
    updatedAt?: number;
  };
}


const initialProductsReducerState: IProductReducerState = {};
const initialObject = {
  serviceRequestProperties: {
    skip: 0,
    limit: 10,
  },
  products: [],
  product: {},
  hash: '',
  updatedAt: new Date().getTime(),
};

for (const key of Object.keys(typeToTableName)) {
  initialProductsReducerState[key] = cloneDeep(initialObject);
}

const productsReducer = createReducer(initialProductsReducerState, {
  [setProductList.type]: (state, {payload}: any) => {
    if (!state[payload.type]) {
      state[payload.type] = cloneDeep(initialObject);
    }
    state[payload.type].products = payload.data || [];
    state[payload.type].serviceRequestProperties.total = payload.total || state[payload.type].serviceRequestProperties.total;
    //   state[payload.type].serviceRequestProperties.limit = payload.limit || state[payload.type].serviceRequestProperties.limit;
    //   state[payload.type].serviceRequestProperties.skip = payload.skip || state[payload.type].serviceRequestProperties.skip;
    state[payload.type].hash = payload.hash;
  },
  [setProductStatusCount.type]: (state, {payload}) => {
    state[payload.type].statusCount = payload.data.reduce((acc, item) => {
      acc[item.status] = item.total;
      return acc;
    }, {});
  },
  [setProductsServiceRequestSkip.type]: (state, {payload}) => {
    state[payload.type].serviceRequestProperties.skip = payload.skip;
  },
  [setProductsServiceRequestLimit.type]: (state, {payload}) => {
    state[payload.type].serviceRequestProperties.limit = payload.limit;
  },
  [setProductsServiceRequestTotal.type]: (state, {payload}) => {
    state[payload.type].serviceRequestProperties.total = payload.total;
  },
  [setProduct.type]: (state, {payload}) => {
    state[payload.type].product = payload.data || {};
  },
  [setDocumentToProduct.type]: (state, {payload}) => {
    getAllProducts(state)
      .filter(product => product.id === payload.product_id)
      .forEach(product => product.document = payload);
  },
  [setThumbnailToFile.type]: (state, {payload}) => {
    getDocumentsFiles(state)
      .filter(file => file.id === payload.file_id)
      .forEach(file => file.thumbnail = payload.thumbnail);
  },
  [setFileIsLoading.type]: (state, {payload}) => {
    getDocumentsFiles(state)
      .filter(file => file.id === payload.file_id)
      .forEach(file => file.loading = payload.loading);
  },
  [removeFileFromProduct.type]: (state, {payload}) => {
    getDocuments(state, (product) => product.id === payload.product_id)
      .forEach(document => {
        document.files = document.files || [];
        removeObjectFromArrayWithMutation(document.files, file => file.id === payload.file_id);
      });
  },
  [removeAllFileFromProduct.type]: (state, {payload}) => {
    getDocuments(state, (product) => product.id === payload.product_id)
      .forEach(document => {
        document.files = document.files || [];
        removeObjectFromArrayWithMutation(document.files, file => true);
      });
  },
  [updateProductModifications.type]: (state, {payload}) => {
    getAllProducts(state)
      .filter(product => product.id === payload.id)
      .forEach(product => {
        merge(product, payload);
      });
  },
  [`${updateProductModifications.type}_FULFILLED`]: (state, {payload}) => {
    getAllProducts(state)
      .filter(product => product.id === payload.id)
      .forEach(product => {
        merge(product, payload);
      });
  }
});
export default productsReducer;
