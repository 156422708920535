import {createSelector} from 'redux-starter-kit';

export const isLoggedInSelector = (() => createSelector(["auth.initialized", "auth.isLoading", "auth.isSignedIn"], (isInitialized, isLoading, isSignedIn) => {
  if (isSignedIn === false && (isInitialized !== true || isLoading === true))
    return null;
  return !!isSignedIn;
}))();

export const emailVerificationSelector = (() => createSelector(["auth.emailVerification"]))();

export const isEmailVerificationSentSelector = (() => createSelector(["auth.emailVerification.sent"], sent => !!sent))();

export const isEmailVerificationVerifiedSelector = (() => createSelector(["auth.emailVerification.verified", "auth.user.isVerified"], (verified, userVerified) => !!verified || !!userVerified))();

export const isEmailPasswordVerificationSentSelector = (() => createSelector(["auth.emailVerification.passwordVerificationSent"], sent => !!sent))();

export const isEmailVerificationUserExistSelector = (() => createSelector(["auth.emailVerification.exist"], (exist) => exist === true))();

export const isEmailIsResetExpiredSelector = (() => createSelector(["auth.emailVerification.resetExpired"], (resetExpired) => resetExpired === true))();

export const isEmailIsBadTokenSelector = (() => createSelector(["auth.emailVerification.badToken"], (badToken) => badToken === true))();

export const getEmailVerificationEmailSelector = (() => createSelector(["auth.emailVerification.email"], email => !!email))();


export const emailVerificationTokenInUrlSelector = (() => createSelector(["router.location.query.token"]))();

export const authManagementErrorSelector = (() => createSelector(["authManagementService.isError"]))();

export const authManagementErrorUserNotFoundSelector = (() => createSelector(["authManagementService.isError.message", "auth.emailVerification"], (message, emailVerification) => !!(emailVerification && message && typeof message === 'string' && message.toLowerCase() === 'user not found.')))();
