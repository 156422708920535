import React from 'react';
import {Helmet} from "react-helmet";

interface IEnableAccessibilityProps {
}

const EnableAccessibility = (props: IEnableAccessibilityProps) => {
  return (
    <Helmet>
      <script defer src="https://dealandgo.co.il/enable.js"></script>
    </Helmet>
  )
};
EnableAccessibility.defaultProps = {};
export default React.memo(EnableAccessibility);
