import React from 'react';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {jssPreset, StylesProvider} from '@material-ui/core/styles';

export interface IRTLProps {
  children?: any;
}

const jss = create({plugins: [...jssPreset().plugins, rtl()]});
const RTL = (props: IRTLProps) => (
  <StylesProvider jss={jss}>
    {props.children}
  </StylesProvider>
);
RTL.defaultProps = {};
export default React.memo(RTL);
