import {feathersAuthentication, services} from 'data/redux/reducers/feathersReducers';
import {createAction} from 'redux-starter-kit';
import {disableLoadingState, setLoadingState} from 'data/redux/actions/guiActions';
import {profileEmailSelector} from 'data/redux/selectors/userSelector';
import {getQueryOrHashParamSelector} from "../selectors/routerSelector";
import {decodeUrlComponentUntilNoChange} from "../../utils/shortcuts";
import {navigateIfNotThere} from "./navigationActions";

export const authenticationInitialized = createAction("authenticationInitialized");
export const authenticationUnInitialize = createAction("authenticationUnInitialize");


export const forceOfflineLogout = createAction("forceOfflineLogout");

export const setEmailIsVerifiedState = createAction("setEmailIsVerifiedState");

export function getForceOfflineLogout() {
  return (dispatch, getState) => {
    dispatch(authenticationInitialized);
    localStorage.removeItem('access-token');
    return dispatch(forceOfflineLogout());
  };
}

export function getSignOutActionCreator() {
  return (dispatch, getState) => {
    if (getState().auth.isSignedIn === false && getState().auth.isLoading === false) {
      localStorage.removeItem("access-token");
      dispatch(authenticationInitialized);
      return dispatch(getForceOfflineLogout());
    } else
      return dispatch(feathersAuthentication.logout());
  };
}


export function getSignUpActionCreator(formValues) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services.users
      .create(formValues))
      .then(result => {
        
        return dispatch(getSignInActionCreator(formValues));
      })
      .catch(({data, error}) => {
        
        return Promise.resolve(null);
      })
      .then(disableLoadingState(dispatch));
  };
}

export function getPasswordResetActionCreator({email} = {email: null}) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services.authManagement
      .create(
        {
          "action": "sendResetPwd",
          "value": {email: email || profileEmailSelector(getState())}
        }
      ))
      .then(result => {
        
        dispatch(setEmailIsVerifiedState({
          email,
          exist: true,
          passwordVerificationSent: true,
          verified: true,
        }));
      })
      .catch(({errors: {$className: error}}) => {
        
        switch (error) {
          case 'isVerified':
            dispatch(setEmailIsVerifiedState({
              email,
              exist: true,
              passwordVerificationSent: false,
              verified: false,
            }));
            break;
          case 'badParams':
            dispatch(setEmailIsVerifiedState({
              email,
              passwordVerificationSent: true,
              exist: false,
            }));
            break;
        }

        return Promise.resolve(null);
      })
      .then(disableLoadingState(dispatch));
  };
}

export function getEmailVerificationResendActionCreator({email} = {email: null}) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services.authManagement
      .create(
        {
          "action": "resendVerifySignup",
          "value": {email: email || profileEmailSelector(getState())}
        }
      ))
      .then(result => {
        
        dispatch(setEmailIsVerifiedState({
          email,
          exist: true,
          verified: false,
          sent: true,
        }));
      })
      .catch(({errors: {$className: error}}) => {
        
        return Promise.resolve(null);
      })
      .then(disableLoadingState(dispatch));
  };
}

export function getEmailTokenVerifyActionCreator(token) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services.authManagement
      .create(
        {
          "action": token.length >= 20 ? "verifySignupLong" : "verifySignupShort",
          "value": token
        }
      ))
      .then(result => {
        
      })
      .catch(({errors: {$className: error}}) => {
        
        return Promise.resolve(null);
      })
      .then(disableLoadingState(dispatch));
  };
}


export function getPasswordResetSetPasswordActionCreator({token, password}) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(services.authManagement
      .create(
        {
          "action": "resetPwdLong",
          "value": {
            token,
            password
          }
        }
      ))
      .then(({value: {email}}) => {
        
        return dispatch(getSignInActionCreator({email, password}));
      })
      .catch(({errors: {$className: error}}) => {
        
        switch (error) {
          case 'resetExpired':
            dispatch(setEmailIsVerifiedState({
              resetExpired: true,
            }));
            break;
          case 'badParams':
            dispatch(setEmailIsVerifiedState({
              badToken: true,
            }));
            break;
        }

        return Promise.resolve(null);
      })
      .then(disableLoadingState(dispatch));
  };
}

export function getSignInActionCreator(formValues) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    return dispatch(feathersAuthentication.authenticate({
      email: formValues.email,
      password: formValues.password,
      recaptcha: formValues.recaptcha,
      strategy: 'local'
    }))
      .then(result => {
        dispatch(authenticationInitialized);
        
      })
      .catch(error => {
        dispatch(authenticationInitialized);
        
        return Promise.resolve(null);
      })
      .then(disableLoadingState(dispatch));
  };
}

export const fbStateParsed = state => {
  const fbState = getQueryOrHashParamSelector('state')(state);
  try {
    if (fbState) {
      return JSON.parse(decodeUrlComponentUntilNoChange(fbState));
    }
  } catch (e) {
    console.log('failed to parse fb state', e);
  }
  return {facebook: false};

}

function getSignIn0AuthActionCreator({accessToken, onlyAuth}, type) {
  return (dispatch, getState) => {
    const fbState = fbStateParsed(getState());
    dispatch(setLoadingState(true));
    return dispatch(feathersAuthentication.authenticate({
      strategy: type,
      access_token: accessToken,
      onlyAuth,
    }))
      .then(result => {
        dispatch(authenticationInitialized);
        
        if (fbState.redirect) {
          dispatch(navigateIfNotThere(fbState.redirect));
        }
      })
      .catch(error => {
        dispatch(authenticationInitialized);
        
        return Promise.resolve(null);
      })
      .then(disableLoadingState(dispatch));
  };
}

export function getSignInFacebookActionCreator(formValues) {
  return getSignIn0AuthActionCreator(formValues, 'facebook');
}

export function getSignInGoogleActionCreator(formValues) {
  return getSignIn0AuthActionCreator(formValues, 'google');
}
