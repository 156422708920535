import React from "react";
import styles from './FormikSearchSelector.module.scss';
import ReactSelectMaterialUi, {SelectOption} from 'react-select-material-ui';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import FormErrorDisplay from "../formErrorDisplay/FormErrorDisplay";
import classNames from "classnames";

export interface IFormikSearchSelectorProps {
  label?: string;
  placeholder?: any;
  defaultOptions?: string[] | SelectOption[];
  options?: string[] | SelectOption[];
  promiseOptions?: (query: string) => Promise<any>;
  onChange?: any;
  defaultValue?: any;
  value?: any;
  className?: string;
  rtl?: boolean;
  field?: any;
  form?: any;
  components?: any;
  styles?: any;
  readOnly?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  onFocus?: any;

  onInputChange?: (value: string) => string;

  componentMode?: boolean;

  listPathName?: string;

  isClearable?: boolean;
  noBorder?: boolean;
  isMulti?: boolean;
  closeMenuOnSelect?: boolean;
  errorClassName?: string;
  selectRef?: any;
  fakeMulti?: boolean;
}

const FormikSearchSelector = (props: IFormikSearchSelectorProps) => {
  const touched = props.form.submitCount > 0 || props.form.touched[props.field.name];
  let error = touched && props.form.errors[props.field.name];
  const valid = touched && !error;
  const asyncFunctionDebounced = props.promiseOptions ? AwesomeDebouncePromise(
    props.promiseOptions,
    200,
  ) : null;
  if (typeof error === "object") {
    error = error[Object.keys(error)[0]];
  }
  return (
    <>
      <ReactSelectMaterialUi
        className={classNames({
          'SearchSelectorLabel': true,
          [styles.SearchSelectorLabel]: true,
          'Mui-error': !!error,
          [props.className]: !!props.className,
          [styles.notSearchable]: props.isSearchable === false,
          [styles.multi]: !!props.isMulti
        })}
        SelectProps={{
          ref: props.selectRef,
          closeMenuOnSelect: props.closeMenuOnSelect === undefined ? true : !!props.closeMenuOnSelect,
          components: {
            DropdownIndicator: null,
            ...props.components,
          },
          className: `SearchSelectorContainer ${props.noBorder ? styles.noBorder : ''}`.trim(),
          classNamePrefix: ((error ? " Mui-error " : "") + (valid ? " valid " : "") + "SearchSelector").trim(),
          id: props.field.name,
          name: props.field.name,
          defaultValue: (props.value || props.field.value),
          styles: props.styles,
          placeholder: props.placeholder,
          readOnly: props.readOnly,
          isSearchable: typeof props.isSearchable !== 'undefined' ? props.isSearchable : props.readOnly,
          isDisabled: props.readOnly,
          isLoading: props.isLoading,
          onInputChange: props.onInputChange,
          componentMode: props.componentMode,
          listPathName: props.listPathName,
          isClearable: props.isClearable,
          isMulti: !!props.isMulti,
          menuPlacement: "bottom",
          menuShouldScrollIntoView: true,
          //   menuIsOpen: true
        }}
        id={props.field.name}
        loadOptions={asyncFunctionDebounced as any}
        options={props.options}
        fullWidth={true}
        label={props.label}
        value={props.value || props.field.value}
        onChange={props.onChange || ((value) => {
          if (props.fakeMulti === true && !Array.isArray(value)) {
            props.field.onChange({
              target: {
                name: props.field.name,
                value: value === null ? undefined : [value],
              },
            });
          } else {
            props.field.onChange({
              target: {
                name: props.field.name,
                value: value === null ? undefined : value,
              },
            });
          }
        })}
        onBlur={props.field.onBlur}
        onFocus={(e) => {
          if (props.onFocus) {
            props.onFocus(e);
          }
          if (props.field && props.field.onFocus) {
            props.field.onFocus(e)
          }
        }}
        placeholder={props.placeholder}
      />
      <FormErrorDisplay className={`${styles.SearchSelectErrorsContainer} ${props.errorClassName}`} error={error}/>
    </>
  );
};

FormikSearchSelector.defaultProps = {
  defaultOptions: false,
  options: [],
  promiseOptions: null,
  rtl: true,
  components: {},
  styles: {},
  isClearable: true,
  noBorder: false,
  isSearchable: true,
  className: '',
  errorClassName: '',
};

export default FormikSearchSelector;
