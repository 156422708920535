import {createAction} from 'redux-starter-kit';
import {services} from 'data/redux/reducers/feathersReducers';
import {xxHash} from 'data/utils/calculators';
import {ICompanyPrediction, IPlaceDetails, IPrediction, IProduct} from 'data/interfaces/products';
import {navigateIfNotThere, toggleNavigationState} from 'data/redux/actions/navigationActions';
import {getSlug, getSluglessPath} from 'data/redux/selectors/routerSelector';
import {normalizeEnglishChars, slugFix} from 'data/utils/shortcuts';
import {
  flight_TYPE,
  hotel_TYPE,
  identifyTypeDbName,
  vacation_TYPE,
  voucher_TYPE,
} from 'data/redux/constants/productsConstants';
import {config, linksMap} from 'data/utils/constants';
import {getVoucherName} from 'data/redux/selectors/productSelector';


export const setLoadingAction = createAction("setLoadingAction");

export const setPageGuiAction = createAction("setPageGuiAction");

export const setMessageEditors = createAction("setMessageEditors");

export const setLoggedInButtonMenuOpen = createAction("setLoggedInButtonMenuOpen");
export const setLoggedInButtonMenuClose = createAction("setLoggedInButtonMenuClose");

export const serviceWorkerInitiated = createAction("serviceWorkerInitiated");
export const serviceWorkerUpdated = createAction("serviceWorkerUpdated");


export function setLoadingState(state: boolean) {
  return (dispatch, getState) => {
    dispatch(setLoadingAction({state}))
  };
}

export function disableLoadingState(dispatch) {
  return (input) => {
    dispatch(setLoadingState(false));
    return input;
  };
}

export function enableLoadingState(dispatch) {
  return (input) => {
    dispatch(setLoadingState(true));
    return input;
  };
}

const spaceChars = [" ", "-", "_"];
const replaceArray = (a, to) => {
  for (const s of spaceChars) {
    const regex = new RegExp(s, "g");
    a = a.replace(regex, to);
  }
  return a;
};

export function getQueryAirportActionCreator(query: string) {
  query = query.trim();
  return (dispatch) => {
    return dispatch(services.airports
      .find(
        {
          query:
            {
              $or: [
                {
                  iata: normalizeEnglishChars(query).toUpperCase(),
                },
                {
                  icao: normalizeEnglishChars(query).toUpperCase(),
                },
                {
                  name_no_spaces: {
                    $like: `%${normalizeEnglishChars(query)}%`,
                  }
                }
              ],
              $limit: 50,
            },
        }))
      .then(({value}) => {
        return value.data;
      })
      .catch(error => {
        
        return Promise.resolve([]);
      });
  };
}


export function getQueryAirlineActionCreator(query: string) {
  query = query.trim();
  return (dispatch) => {
    return dispatch(services.airlines
      .find(
        {
          query:
            {
              $or: [
                {
                  iata: normalizeEnglishChars(query).toUpperCase(),
                },
                {
                  icao: normalizeEnglishChars(query).toUpperCase(),
                },
                {
                  name_no_spaces: {
                    $like: `%${normalizeEnglishChars(query)}%`,
                  }
                }
              ],
            }
        }))
      .then(({value}) => {
        return value.data;
      })
      .catch(error => {
        
        return Promise.resolve([]);
      });
  };
}


export function getQueryLogosActionCreator(query: string) {
  if (query) {
    query = query.trim();
    return (dispatch) => {
      return dispatch(services.brandsoftheworld
        .find(
          {
            query:
              {search: query}
          }))
        .then(({value}) => value.data)
        .catch(error => {
          
          return Promise.resolve([]);
        });
    };
  }
}


const generateUniqueUserToken = (getState) => `${getState().auth.user.id}-${xxHash(JSON.stringify(getState().auth.user))}-${xxHash(JSON.stringify(getState()['places-detailsService']))}`;

export function getQueryAutocompletePlacesActionCreator(query: string) {
  return (dispatch, getState) => {
    return dispatch(services['places-autocomplete']
      .get(query, {
        query: {
          sessiontoken: generateUniqueUserToken(getState)
        }
      }))
      .then(({value}) => value.value.predictions as IPrediction[])
      .catch(error => {
        
        return Promise.resolve([]);
      });
  };
}

export function getQueryAutocompleteBanksActionCreator(query: string, options = {}) {
  return (dispatch, getState) => {
    return dispatch(services['banks-autocomplete']
      .get(query, {
        query: {
          ...options
        }
      }))
      .then(({value}) => value.value)
      .catch(error => {
        
        return Promise.resolve([]);
      });
  };
}


export function getQueryDetailsPlacesActionCreator(placeId: string) {
  return (dispatch, getState) => {
    return dispatch(services['places-details']
      .get(placeId, {
        query: {
          sessiontoken: generateUniqueUserToken(getState)
        }
      }))
      .then(({value}) => {
        const result = value.value.result as IPlaceDetails;
        if (!result.photos) {
          result.photos = [
            {
              photo_reference: "/img/broken.png",
            }
          ];
        }
        result.id = value.value.id;
        return result;
      })
      .catch(error => {
        
        return Promise.resolve([]);
      });
  };
}

export function getQueryAutocompleteCompaniesActionCreator(query: string) {
  return (dispatch, getState) => {
    return dispatch(services['companies-autocomplete']
      .get(query))
      .then(({value}) => value.value as ICompanyPrediction[])
      .catch(error => {
        
        return Promise.resolve([]);
      });
  };
}

export function getAskingPricesActionCreator() {
  return (dispatch) => {
    return dispatch(services['prices']
      .find({
        query: {
          $groupByAndCount: 'askingPrice',
        }
      }))
      .then(({data}) => data)
      .catch(error => {
        
        return Promise.resolve([]);
      });
  };
}

const defaultSlug = 'slug';
const generateSlug = (product: Partial<IProduct>) => {
  try {
    switch (identifyTypeDbName(product)) {
      case hotel_TYPE:
        return [product.hotelDetails.hotel.formatted_address,
          product.hotelDetails.hotel.name].map(a => slugFix(a)).join("-");
      case flight_TYPE:
        const s = [product.ticketDetails.originAirport.city,
          product.ticketDetails.destinationAirport.city,
          product.ticketDetails.originFlightCompany.name];
        if (product.ticketDetails.destinationFlightCompany) {
          s.push(product.ticketDetails.destinationFlightCompany.name);
        }
        return s.map(a => slugFix(a)).join("-");
      case vacation_TYPE: {
        return [product.ticketDetails.originAirport.city,
          product.ticketDetails.destinationAirport.city,
          product.ticketDetails.originFlightCompany.name,
          product.ticketDetails.destinationFlightCompany.name,
          product.hotelDetails.hotel.formatted_address,
          product.hotelDetails.hotel.name].map(a => slugFix(a)).join("-");
      }
      case voucher_TYPE: {
        return [getVoucherName(product)].map(a => slugFix(a)).join("-");
      }
    }
  } catch (e) {
    
  }
  return defaultSlug;
}

export const generateMappedUrl = (url: string, product: Partial<IProduct>) => url
  .replace(":id", `${product.id}`)
  .replace(":slug", generateSlug(product));


export const generateSluggedAdUrl = (product: Partial<IProduct>) => generateMappedUrl(linksMap.adIdSlug_, product);

export const generateSluggedAdFullUrl = (product: Partial<IProduct>) => generateMappedUrl(`${config.api_server.baseUrl}/${linksMap.adIdSlug_}`, product);


export const generateSluggedEditUrl = (product: Partial<IProduct>) => generateMappedUrl(linksMap.adEditIdSlug, product);


export function autoFixSlug(product: Partial<IProduct>) {
  return (dispatch, getState) => {
    dispatch(toggleNavigationState(false));
    const currentSlug = getSlug(getState());
    const correctSlug = generateSlug(product);
    if (currentSlug !== correctSlug && correctSlug !== defaultSlug) {
      dispatch(navigateIfNotThere(`${getSluglessPath(getState())}/${correctSlug}`));
    }
    setTimeout(() => dispatch(toggleNavigationState(true)), 500);
  };
}


export function serviceWorkerInitiatedActionCreator() {
  return (dispatch) => {
    return dispatch(serviceWorkerInitiated());
  };
}


export function serviceWorkerUpdatedActionCreator(update) {
  return (dispatch) => {
    return dispatch(serviceWorkerUpdated(update));
  };
}
