import React, {useEffect} from "react";
import {FastField} from 'formik';
import {Select} from 'formik-material-ui';
import styles from './FormikSelectInput.module.scss';
import SkeletonPlaceHolder from 'gui/components/skeletonPlaceHolder';
import classNames from "classnames";

export interface IFormikSelectInputProps {
  label?: string;
  name: string;
  id?: string;
  options?: any;
  values: any;
  errors: any;
  touched: any;
  onChange?: any;
  setFieldError?: any;
  customErrorsObject?: any;
  noBorder?: boolean;
  loading?: boolean;
}


const SelectFormatted = ({options, children, ...props}) => {
  return (<Select {...props.field} {...props as any}>
    {options.map(option => <option key={option.label} value={option.value}>{option.label}</option>)}
  </Select>);
}

const FormikSelectInput = ({
                             name,
                             id,
                             label,
                             errors,
                             touched,
                             values,
                             customErrorsObject,
                             setFieldError,
                             loading,
                             noBorder,
                             ...rest
                           }: IFormikSelectInputProps) => {
    useEffect(() => {
      if (setFieldError && !!(customErrorsObject && typeof customErrorsObject === 'object' && Object.keys(customErrorsObject).length > 0)) {
        Object.keys(customErrorsObject)
          .filter(key => customErrorsObject[key])
          .filter(key => !(errors[name || id] || []).includes(customErrorsObject[key]))
          .forEach(key => {
            setFieldError(name || id, customErrorsObject[key]);
          });
      }
    }, [customErrorsObject, errors, touched, name, id, setFieldError]);
    return (<>
        {loading ?
          <SkeletonPlaceHolder
            className={styles.loadingField}
          >
            {label}
          </SkeletonPlaceHolder>
          :
          <FastField
            component={SelectFormatted}
            label={label}
            name={name || id}
            variant="outlined"
            className={classNames({
              [styles.TextField]: true,
              [styles.valid]: (!errors[name || id] && (touched[name || id] || values[name || id])),
              [styles.noBorder]: noBorder,
            })}
            required
            fullWidth
            {...rest}
          />
        }
      </>
    )
  }
;

FormikSelectInput.defaultProps = {
  label: "",
  values: [],
  errors: [],
  touched: [],
  loading: false,
};

export default FormikSelectInput;
