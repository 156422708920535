import {createReducer} from 'redux-starter-kit';
import {
  setOrder,
  setOrderList,
  setOrdersServiceRequestLimit,
  setOrdersServiceRequestSkip,
  setOrdersServiceRequestTotal,
  setOrderStatusCount,
  updateOrderModifications,
} from 'data/redux/actions/ordersActions';
import {IServiceRequestProperties} from 'data/redux/reducers/productsReducer';
import {IOrder} from 'data/interfaces/orders';
import {cloneDeep, merge} from 'lodash';
import {getAllOrders} from 'data/redux/selectors/orderSelector';

interface IOrderReducerState {
  myOrders: {
    serviceRequestProperties: IServiceRequestProperties;
    orders: IOrder[];
  },
  productOrders: {
    serviceRequestProperties: IServiceRequestProperties;
    orders: IOrder[];
  },
}

const initialObject = {
  serviceRequestProperties: {
    skip: 0,
    limit: 10,
  },
  orders: [],
  hash: '',
  updatedAt: new Date().getTime(),
};
const initialOrdersReducerState: IOrderReducerState = {
  myOrders: cloneDeep(initialObject),
  productOrders: cloneDeep(initialObject),
};

const ordersReducer = createReducer(initialOrdersReducerState, {
  [setOrderList.type]: (state, {payload}) => {
    if (!state[payload.type]) {
      state[payload.type] = cloneDeep(initialObject);
    }
    state[payload.type].orders = payload.data || [];
    state[payload.type].serviceRequestProperties.total = payload.total || state[payload.type].serviceRequestProperties.total;
    state[payload.type].hash = payload.hash;
  },
  [setOrderStatusCount.type]: (state, {payload}) => {
    state[payload.type].statusCount = payload.data.reduce((acc, item) => {
      acc[item.status] = item.total;
      return acc;
    }, {});
  },
  [setOrdersServiceRequestSkip.type]: (state, {payload}) => {
    state[payload.type].serviceRequestProperties.skip = payload.skip;
  },
  [setOrdersServiceRequestLimit.type]: (state, {payload}) => {
    state[payload.type].serviceRequestProperties.limit = payload.limit;
  },
  [setOrdersServiceRequestTotal.type]: (state, {payload}) => {
    state[payload.type].serviceRequestProperties.total = payload.total;
  },
  [setOrder.type]: (state, {payload}) => {
    state.myOrders.orders = state.myOrders.orders.filter(({product_id}) => product_id !== payload.product_id);
    state.myOrders.orders.push(payload);
  },
  [updateOrderModifications.type]: (state, {payload}) => {
    getAllOrders(state)
      .filter(order => order.id === payload.id)
      .forEach(order => {
        merge(order, payload);
      });
  },
  [`${updateOrderModifications.type}_FULFILLED`]: (state, {payload}) => {
    getAllOrders(state)
      .filter(order => order.id === payload.id)
      .forEach(order => {
        merge(order, payload);
      });
  }
});
export default ordersReducer;
