import React from 'react';
import {Helmet} from "react-helmet";
import {config} from 'data/utils/constants';

interface IOrganizationLogoJsonDProps {
}

const OrganizationLogoJsonD = (props: IOrganizationLogoJsonDProps) => {
  return (
    <Helmet>
      <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "${config.api_server.baseUrl}",
      "logo": "${config.api_server.baseUrl}/img/logo.png"
    }
  `}</script>
    </Helmet>
  )
};
OrganizationLogoJsonD.defaultProps = {};
export default React.memo(OrganizationLogoJsonD);
