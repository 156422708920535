import './polyfills.ts';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './gui/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {Provider} from 'react-redux'
import store from './data/redux'
import RTL from 'gui/components/RTL/RTL';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import "@fontsource/rubik/400.css"
import "@fontsource/rubik/700.css"
import "@fontsource/heebo"; // Defaults to weight 400.
import {orange} from '@material-ui/core/colors';
import {serviceWorkerInitiatedActionCreator, serviceWorkerUpdatedActionCreator} from 'data/redux/actions/guiActions';
/*
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
Sentry.init({
  dsn: "https://2044764ecdba44fc858189c27b9e056b@sentry.doar.systems/3",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
*/

const theme = createTheme({
  palette: {
    primary: orange,
  },
  typography: {
    fontFamily: "rubik",
  },
  direction: 'rtl',
});
ReactDOM.render(
  <Provider store={store}>
    <RTL>
      <MuiThemeProvider theme={theme}>
        <App/>
      </MuiThemeProvider>
    </RTL>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(
  {
    onSuccess: () => store.dispatch(serviceWorkerInitiatedActionCreator()),
    onUpdate: reg => {
      (location as any).reload(true)
      return store.dispatch(serviceWorkerUpdatedActionCreator(reg));
    },
  }
);
