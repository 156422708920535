import React from "react";
import {MDBIcon} from 'mdbreact';
import LinkElement from "./components/LinkElement";

export interface ILinkBlockElementProps {
  currentPathName?: string;
  text?: string;
  icon?: string;
  to?: string;
  href?: string;
  title?: string;
  brand?: boolean;
  src?: string;
  alt?: string;
  active?: boolean;
  className?: string;
  onMenuSelect?: (to?: any, alreadyLink?: boolean, e?: any) => void;
  afterMenuSelect?: (props?: any) => void;
  children?: any;
  style?: any;
  imgStyle?: any;
  onlyClick?: boolean;
  activeToLogic?: (to: string, compareTo: string) => boolean;
  activeTos?: string[];
  componentMode?: boolean;
  loading?: boolean;
}

const LinkBlock = (props: ILinkBlockElementProps) => {
  if (props.icon !== null) {
    return (<LinkElement {...props}><MDBIcon fab icon={props.icon}/></LinkElement>);
  } else
    return (<LinkElement {...props}>{props.text || props.children}</LinkElement>);
}

LinkBlock.defaultProps = {
  currentPathName: "",
  text: "",
  icon: null,
  to: "",
  brand: false,
  src: null,
  alt: "",
  style: {},
  className: null,
  onlyClick: false,
  onMenuSelect: function () {
  },
  afterMenuSelect: null,
  'aria-label': '',
  activeToLogic: (to, compareTo) => to === compareTo,
};

export default React.memo(LinkBlock);
