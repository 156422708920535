import {connect} from 'react-redux'
import {AuthenticatedMapStateToProps} from 'data/redux/containers/commons';
import AuthenticationNavigator from 'gui/pages/mainRouter/components/navigation/components/authenticationNavigator/AuthenticationNavigator';

const mapStateToProps = (state) => {
  return {
    ...AuthenticatedMapStateToProps(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const AuthenticationNavigatorContainer = connect(mapStateToProps, mapDispatchToProps)(AuthenticationNavigator);

export default AuthenticationNavigatorContainer;
