import React from "react";
import './PredefinedMenuList.css';
import {MenuList} from '@material-ui/core';
import NavigateLinkContainer from "data/redux/containers/navigateLinkContainer/NavigateLinkContainer";
import {linksMap, USER_TYPE_ADMIN} from 'data/utils/constants';

import {ReactComponent as dashboard} from 'assets/img/dashBoard.svg';
import {ReactComponent as productsOffered} from 'assets/img/productsOffered.svg';
import {ReactComponent as productsOrdered} from 'assets/img/productsOrdered.svg';
import {ReactComponent as profile} from 'assets/img/profile.svg';
import {ReactComponent as adminShield} from 'assets/img/adminShield.svg';
import {ReactComponent as exitDoor} from 'assets/img/exitDoor.svg';
import Editor from 'gui/components/editors/commons/editor';


interface IMenuItem {
  label: string | any,
  path: string,
  onlyClick?: boolean,
  userType?: number,
  className?: string,
  Icon?: any;
  activePaths?: string[];
};

interface IMenuListProps {
  className?: string;
  style?: any;
  menuList: IMenuItem[];
  menuListFilter?: (menuList: IMenuItem[]) => IMenuItem[];
}


const slashesRegex = new RegExp('/', 'g');
export const CreateProductButton = (props: { style? }) => {
  const path = linksMap.createProduct;
  return (<ul style={{width: 'fit-content', margin: 0, padding: 0, ...(props.style ? props.style : {})}}>
    <NavigateLinkContainer
      className={"MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button " + path.replace(/-/g, '').replace(slashesRegex, '')}
      to={path}
    >
    <span
      className="innerText text-center">פרסם מכירה
                  </span>
    </NavigateLinkContainer>
  </ul>);
}

export const OrderProductButton = (props: { style? }) => {
  const path = linksMap.tourism;
  return (<ul style={{width: 'fit-content', margin: 0, padding: 0, ...(props.style ? props.style : {})}}>
    <NavigateLinkContainer
      className={"MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button " + path.replace(/-/g, '').replace(slashesRegex, '')}
      to={path}
      style={props.style}
    >
    <span
      className="innerText text-center">הזמן
                  </span>
    </NavigateLinkContainer>
  </ul>);
}

const MenuItemList = (props: IMenuListProps) => (<>
  {props.menuList
    .map(({label, path, activePaths, onlyClick, userType, className, Icon}, index) =>
      <NavigateLinkContainer
        key={index}
        className={"MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button " + (className || path.replace(/-/g, '').replace(slashesRegex, ''))}
        to={path}
        onlyClick={onlyClick}
        userType={userType}
        activePaths={activePaths}
      >
        {Icon ? <Icon/> : null}
        {label}
      </NavigateLinkContainer>
    )}
</>);


const PredefinedMenuList = (props: IMenuListProps) => (
  <MenuList style={props.style} className={"controlMenuContainer" + (props.className ? ` ${props.className}` : "")}>
    <MenuItemList
      {...props}
      menuList={props.menuListFilter(props.menuList)}
    />
  </MenuList>
);
PredefinedMenuList.defaultProps = {
  className: null,
  style: null,
  menuList: [
    {path: linksMap.dashboard, label: "איזור אישי", Icon: dashboard},
    {path: linksMap.productsByMeOpen, label: "מוצרים שפרסמתי", Icon: productsOffered},
    {path: linksMap.ordersByMeOpen, label: "מוצרים שרכשתי", Icon: productsOrdered},
    {path: linksMap.profile, label: "הגדרות פרופיל", Icon: profile},
    {
      path: `${linksMap.adminProducts}?updatedatsort=false`,
      className: "adminshield",
      userType: USER_TYPE_ADMIN,
      label: "אדמין",
      Icon: adminShield
    },
    {
      path: linksMap.createProduct, label: <span
        className="innerText">פרסם מכירה
                  </span>
    },
    {path: linksMap.logout, label: "התנתק", onlyClick: true, Icon: exitDoor},
  ],
  menuListFilter: (menuList) => menuList,
};
export default React.memo(PredefinedMenuList);
