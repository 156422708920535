import React from "react";
import {MDBCol, MDBCollapse, MDBContainer, MDBNavbar, MDBRow} from 'mdbreact';
import styles from './Navigation.module.scss';
//import 'react-flags-select/css/react-flags-select.css';
// React Component
import Burger from '@animated-burgers/burger-squeeze'
// don't forget the styles
import '@animated-burgers/burger-squeeze/dist/styles.css'
import DealAndGoLogo from "./components/dealAndGoLogo/DealAndGoLogo";
import AuthenticationNavigatorContainer from "data/redux/containers/authenticationNavigatorContainer";
import NavigateLinks from './components/navigateLinks/NavigateLinks';
import {linksMap} from 'data/utils/constants';
import PublishButton from "./components/dealAndGoNavButtons/components/publishButton/PublishButton";

interface INavigationProps {
  menuCollapseToggle: boolean;
  pathName: string;
  onMenuCollapseToggle: (state?: boolean) => void;
  selectedMenu: string;
  onMenuSelect: (menuSelection) => void;
  signedIn?: boolean;
}

const Navigation = (props: INavigationProps) => {
  return (
    <MDBNavbar data-page={props.pathName}
               light
               tag="header"
               expand="md"
               className={`navigationContainer ${props.menuCollapseToggle ? styles.menuCollapseClosed : ''} ${styles.navigationContainer} ${props.signedIn ? "signedIn" : "signedOut"}`.trim()}
               scrolling
               fixed="top">
      <MDBContainer fluid>
        <MDBRow style={{width: "100%"}}>
          <MDBCol className='column' sm="2" md="2" lg="2" xs="2" size="2">
            <DealAndGoLogo/>
          </MDBCol>
          <MDBCol className='column' sm="10" md="10" lg="10" xs="10" size="10">
            <PublishButton className={styles.mobilePublish}/>
            <div onClick={() => props.onMenuCollapseToggle()} className="burgerContainer">
              <Burger isOpen={props.menuCollapseToggle}
                      aria-label={'Burger-Menu'}
                      Component="button"
                      type="button"/>
            </div>
            <MDBCollapse tag="nav"
                         isOpen={props.menuCollapseToggle}
                         onClick={e => e.stopPropagation()}
                         onTouchMove={e => e.stopPropagation()}
                         onTouchStart={e => e.stopPropagation()}
                         onTouchEnd={e => e.stopPropagation()}
                         className={`animated fadeInDown`}
                         navbar>
              <MDBContainer fluid>
                <MDBRow className={styles.menuRowContainer}>
                  <MDBCol md="5" lg="6">
                    <NavigateLinks
                      className={`hideWhenSignedIn767 ${styles.topNavigationLinks}`}
                      right
                      links={[
                        {
                          text: "דף בית",
                          to: linksMap.root,
                        },
                        {
                          text: "תיירות",
                          to: linksMap.tourism,
                        },
                        {
                          text: "חנויות ופנאי",
                          to: linksMap.experiences,
                        },
                        {
                          text: "שאלות ותשובות",
                          to: linksMap.faq,
                        },
                        //     {
                        //      text: "תרבות וספורט",
                        //      to: "/sport",
                        //    },
                        //    {
                        //      text: "חנויות ופנאי",
                        //      to: "/shopping",
                        //    },
                      ]}
                      onMenuSelect={() => props.onMenuCollapseToggle(false)}
                    />
                  </MDBCol>
                  <MDBCol md="7" lg="6">
                    <AuthenticationNavigatorContainer/>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBNavbar>
  );
}
Navigation.defaultProps = {
  pathName: "",
  menuCollapseToggle: false,
  onMenuCollapseToggle: (collapseToggleState) => {
    
  },
  selectedMenu: "",
  onMenuSelect: () => {
  },


};
export default React.memo(Navigation);
