import {connect} from 'react-redux'
import {collapseToggleState, locationPop, navigateIfNotThere} from 'data/redux/actions/navigationActions';
import LinkBlock from 'gui/components/linkBlock/LinkBlock';
import {setLoggedInButtonMenuClose} from 'data/redux/actions/guiActions';
import {getSignOutActionCreator} from 'data/redux/actions/authenticationActions';
import {linksMap} from 'data/utils/constants';
import {getPathName} from 'data/redux/selectors/routerSelector';


const mapStateToProps = ({router, navigation}, ownProps) => {
  if (typeof ownProps.preProcessesTo === 'function') {
    ownProps.to = ownProps.preProcessesTo(router, navigation.history) || ownProps.to;
  }
  return {
    currentPathName: getPathName({router}),
    ...ownProps,
  };
};

export const mapDispatchToProps = (dispatch, ownProps?) => {
  return {
    onMenuSelect: (routeSelection, alreadyLink = true, e) => {
      dispatch(setLoggedInButtonMenuClose(false));
      dispatch(collapseToggleState(false));
      if (routeSelection === linksMap.logout) {
        dispatch(getSignOutActionCreator());
      }
      if (!alreadyLink) {
        if (routeSelection) {
          dispatch(navigateIfNotThere(routeSelection));
        } else {
          dispatch(locationPop());
        }
      }
    },
    ...((ownProps) ? ownProps : {})
  };
};

const LinkBlockContainer = connect(mapStateToProps, mapDispatchToProps)(LinkBlock);

export default LinkBlockContainer;
