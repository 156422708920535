import React from "react";

import LoggedInAccountButtonContainer
  from "data/redux/containers/LoggedInAccountButtonContainer/LoggedInAccountButtonContainer";
import LoggedOut from "./components/loggedOut/LoggedOut";


interface IAuthenticationNavigatorProps {
  signedIn: boolean;
}

const AuthenticationNavigator = (props: IAuthenticationNavigatorProps) => (
  props.signedIn ? <LoggedInAccountButtonContainer/> : <LoggedOut/>
);
AuthenticationNavigator.defaultProps = {
  signedIn: false,
};
export default React.memo(AuthenticationNavigator);
