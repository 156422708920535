import React from "react";
import NavigateLink, {INavigateLinkProps} from '../navigateLink';
import {MDBNavbarNav} from 'mdbreact';

export interface INavigateLinksProps {
  className?: string;
  links?: INavigateLinkProps[];
  onMenuSelect?: (to: any) => void;
  left?: boolean;
  right?: boolean;
}

const NavigateLinks = (props: INavigateLinksProps) => {
  const {links, onMenuSelect, className, ...navProps} = props;
  const currentPathName = window.location.pathname;
  return (
    <MDBNavbarNav className={`NavigationLinks ${className}`.trim()} {...navProps}>
      {links
        .map((link, index) =>
          <NavigateLink
            key={index}
            currentPathName={currentPathName}
            onMenuSelect={() => onMenuSelect(link.to || link.href)} {...link}/>)
      }
    </MDBNavbarNav>
  );
};

NavigateLinks.defaultProps = {
  className: '',
  links: [],
  onMenuSelect: function () {
  },
  left: false,
  right: false,
};

export default React.memo(NavigateLinks);
