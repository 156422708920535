import {connect} from 'react-redux'

import {collapseToggleState} from 'data/redux/actions/navigationActions';
import Navigation from 'gui/pages/mainRouter/components/navigation';
import {isLoggedInSelector} from 'data/redux/selectors/authenticationSelector';
import {getPathName} from 'data/redux/selectors/routerSelector';

const mapStateToProps = (state) => {
  return {
    menuCollapseToggle: state.navigation.menuCollapseToggle,
    pathName: getPathName(state),
    signedIn: isLoggedInSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMenuCollapseToggle: (state?) => {
      dispatch(collapseToggleState(state));
    }
  };
};

const NavigationContainer = connect(mapStateToProps, mapDispatchToProps)(Navigation as any);

export default NavigationContainer;
