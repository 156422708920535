import {createSelector} from 'redux-starter-kit';
import {eMessageEditorCategory, eMessageEditorType} from 'data/enums/messageEditors';
import {isEqual, pick} from 'lodash';

export const isLoadingSelector = (() => createSelector(["gui.loading", "auth.initialized"], (loading, initialized) => loading || !initialized))();

export const getInitializationTimeStamp = (() => createSelector(["auth.initializationTime"]))();

export const askingPriceSelector = (() => createSelector(["pricesService.queryResult.data"], priceDetails => (priceDetails || [])))();

export const minPriceSelector = (() => createSelector(["pricesService.queryResult.data"], priceDetails => (priceDetails.length ? priceDetails.map(s => s.askingPrice)[0] : 0)))();

export const maxPriceSelector = (() => createSelector(["pricesService.queryResult.data"], priceDetails => (priceDetails.length ? priceDetails.map(s => s.askingPrice).reverse()[0] : 0)))();

export const getBanksSelector = (() => createSelector(["gui.banks"]))();
export const getBranchesSelector = (() => createSelector(["gui.branches"]))();

export const getLogosSelector = (() => createSelector(["brandsoftheworldService.queryResult.data"]))();


export const getPageVariablesSelector = (page: string) => (() => createSelector([`gui.pages.${page}`]))();

export const isSameMessageEditor = (editor1, editor2) => {
  const comparisonPick = [
    'type',
    'category',
    'status',
  ];
  return isEqual(
    pick(editor1, comparisonPick),
    pick(editor2, comparisonPick),
  );
};

export const getMessageEditor = (type: eMessageEditorType, category: eMessageEditorCategory, status: number) => (() => createSelector([`gui.messageEditors`],
  messageEditors => {
    return (messageEditors || []).find(editor => isSameMessageEditor(editor, {type, category, status})) || {
      type,
      category,
      status,
    };
  }))();


export const companiesSelector = (() => createSelector(["companiesService.queryResult.data"], r => r || []))();
export const productTypesSelector = (() => createSelector(["product-typesService.queryResult.data"], r => r || []))();
