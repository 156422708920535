import uniqWith from 'uniq-with';
import {autoAddAddons as _autoAddAddons} from 'data/utils/shortcuts';
import {eProductSubCategoryType, eProductTags, eProductCategoryType, eVoucherTypes} from 'data/enums/products';
import {linksMap, productTypes} from "../../utils/constants";
import {getProductTypesWithClassification} from "../../utils/products";

export const ticketDetails = 'ticketDetails';
export const hotelDetails = 'hotelDetails';
export const voucherDetails = 'voucherDetails';
export const vacationDetails = 'vacationDetails';

export const myFlights_TYPE = 'myFlights';
export const myHotels_TYPE = 'myHotels';
export const myVacations_TYPE = 'myVacations';
export const myVouchers_TYPE = 'myVouchers';

export const ad_TYPE = 'ad';
export const flight_TYPE = 'flight';
export const hotel_TYPE = 'hotel';
export const vacation_TYPE = 'vacation';
export const voucher_TYPE = 'voucher';
export const cruise_TYPE = 'cruise';

export const tourism_TYPE = 'tourism';

export const flights_TYPE = 'flights';
export const hotels_TYPE = 'hotels';
export const vacations_TYPE = 'vacations';
export const vouchers_TYPE = 'vouchers';
export const cruises_TYPE = 'cruises';

export const restaurant_TYPE = 'restaurant';
export const shop_TYPE = 'shop';
export const experience_TYPE = 'experience';
export const shopping_multi_TYPE = 'shopping_multi';


export const typeToTableName = {
  [myFlights_TYPE]: ticketDetails,
  [eProductTags.FLIGHT]: ticketDetails,
  [myHotels_TYPE]: hotelDetails,
  [eProductTags.HOTEL]: hotelDetails,
  [myVouchers_TYPE]: voucherDetails,
  [eProductTags.CRUISE]: voucherDetails,

  [eProductTags.FLIGHTS]: ticketDetails,
  [eProductTags.HOTELS]: hotelDetails,
  [eProductTags.VACATIONS]: [ticketDetails, hotelDetails].join(','),
  [eProductTags.VOUCHERS]: voucherDetails,
  [eProductTags.RESTAURANT]: voucherDetails,
  [eProductTags.SHOP]: voucherDetails,
  [eProductTags.EXPERIENCE]: voucherDetails,
  [eProductTags.MULTI]: voucherDetails,

  'myProducts': '',
  'allProducts': '',
  'ad': '',
};


export const PRODUCT_STATUS_UNKNOWN = 0;
export const PRODUCT_STATUS_WAITING_FOR_APPROVAL = 1;
export const PRODUCT_STATUS_DISAPPROVED = 2;
export const PRODUCT_STATUS_PUBLISHED = 100;
export const PRODUCT_STATUS_CANCELED = 102;
export const PRODUCT_STATUS_TIMED_OUT = 103;
export const PRODUCT_STATUS_ORDER = 200;

export const PRODUCT_STATUS_ORDER_APPROVED_WAITING_TRANSFER = 201;
export const PRODUCT_STATUS_ORDER_APPROVED_WAITING_TRANSFER_APPROVAL_BY_BUYER = 203;
export const PRODUCT_STATUS_ORDER_APPROVED_TRANSFERRED = 204;
export const PRODUCT_STATUS_ORDER_APPROVED_WAITING_PAYMENT = 205;
export const PRODUCT_STATUS_ORDER_SOLD = 300;
export const PRODUCT_STATUS_ORDER_DISPUTE = 400;

export const PRODUCT_STATUS_PREVIEW = -9999;

export const PRODUCT_STATUS_TYPE_OPEN = 'open';
export const PRODUCT_STATUS_TYPE_SOLD = 'sold';
export const PRODUCT_STATUS_TYPE_CANCELED = 'canceled';
export const PRODUCT_STATUS_TYPE_EDITABLE = 'editable';

export const productAssociatedStates = {
  [PRODUCT_STATUS_TYPE_OPEN]: [
    PRODUCT_STATUS_WAITING_FOR_APPROVAL,
    PRODUCT_STATUS_DISAPPROVED,
    PRODUCT_STATUS_PUBLISHED,
    PRODUCT_STATUS_ORDER,
    PRODUCT_STATUS_ORDER_APPROVED_WAITING_TRANSFER,
    PRODUCT_STATUS_ORDER_APPROVED_TRANSFERRED,
    PRODUCT_STATUS_ORDER_APPROVED_WAITING_PAYMENT,
    PRODUCT_STATUS_ORDER_DISPUTE,
    PRODUCT_STATUS_ORDER_APPROVED_WAITING_TRANSFER_APPROVAL_BY_BUYER
  ],
  [PRODUCT_STATUS_TYPE_EDITABLE]: [
    PRODUCT_STATUS_WAITING_FOR_APPROVAL,
    PRODUCT_STATUS_PUBLISHED,
    PRODUCT_STATUS_CANCELED,
    PRODUCT_STATUS_TIMED_OUT,
  ],

  [PRODUCT_STATUS_TYPE_SOLD]: [PRODUCT_STATUS_ORDER_SOLD],
  [PRODUCT_STATUS_TYPE_CANCELED]: [PRODUCT_STATUS_TIMED_OUT, PRODUCT_STATUS_CANCELED, PRODUCT_STATUS_DISAPPROVED],
};
const associatedOrder = [
  PRODUCT_STATUS_TYPE_OPEN,
  PRODUCT_STATUS_TYPE_SOLD,
  PRODUCT_STATUS_TYPE_CANCELED,
];


export const allProductStates = uniqWith(((a, b) => a === b), associatedOrder.map(type => productAssociatedStates[type]).flat());

productAssociatedStates[0] = allProductStates;



/**
 * All the product types that currently exist, without duplicates
 */
export const allProductTypes = uniqWith(((a, b) => a === b), Object.values(typeToTableName).filter((v, i, a) => a.indexOf(v) === i).join(',').split(','));

/**
 * Commonly used product addons
 */
export const detailsAddons = {
  [ticketDetails]: `${ticketDetails}.[originFlightCompanyDetails,destinationFlightCompanyDetails,originAirportDetails,destinationAirportDetails]`,
  [voucherDetails]: `${voucherDetails}.[companyDetails.assets.asset,flightCompanyDetails]`,
  [hotelDetails]: `${hotelDetails}`,
};

/**
 * Automatically combines sub-details, such as originFlightCompanyDetails in ticketDetails,   or placeDetails in hotelDetails using the detailsAddons object
 * @param typeToTableName
 */
export const autoAddAddons = typeToTableName => _autoAddAddons(typeToTableName, detailsAddons);

export const isTypeInTypes = (hayStack = [], ...needles: string[]) => {
  for (const stick of hayStack) {
    for (const needle of needles) {
      if (stick === needle)
        return true;
    }
  }
  return false;
}

/**
 * All the product types that currently exist with common addons , eg sub details such as hotels placeDetails
 */
export const allProductTypesWithAddons = autoAddAddons(allProductTypes);

const detailExist = (product, detailName) => detailName in product && Object.keys(product[detailName]).length > 0;
const detailsExist = (product, ...detailNames) => detailNames.filter(detailName => detailExist(product, detailName)).length === detailNames.length;

export const identifyProductCategoryType = (product, overrides: any = {}) => {
  let details = null;
  if (detailsExist(product, ticketDetails, hotelDetails)) {
    details = vacationDetails;
  } else if (detailsExist(product, ticketDetails)) {
    details = ticketDetails;
  } else if (detailsExist(product, hotelDetails)) {
    details = hotelDetails;
  } else if (detailsExist(product, voucherDetails)) {
    details = voucherDetails;
  }
  return detailsTypeOverrider(details, overrides);
}


/**
 * TODO replace this with language friendly approach?
 * @param product
 */
export const identifyTypeText = (product) => {
  if (product.typeDetails && product.typeDetails.subType) {
    switch (product.typeDetails.subType) {
      case eProductSubCategoryType.HOTEL:
        return 'מלונות';
      case eProductSubCategoryType.FLIGHT:
        return 'טיסות';
      case eProductSubCategoryType.VOUCHER:
        return 'שוברים';
      case eProductSubCategoryType.shop:
      case eProductSubCategoryType.shops:
        return 'חנויות';
      case eProductSubCategoryType.experience:
      case eProductSubCategoryType.experiences:
        return 'חוויות';
      case eProductSubCategoryType.restaurant:
      case eProductSubCategoryType.restaurants:
        return 'מסעדות';
      case eProductSubCategoryType.multi:
        return 'מולטי שוברים';
      case eProductSubCategoryType.VACATION:
        return 'חופשות';
    }
  }
  switch (identifyProductCategoryType(product)) {
    case hotelDetails:
      return 'מלונות';
    case ticketDetails:
      return 'טיסות';
    case voucherDetails:
      return 'תיירות';
    case vacationDetails:
      return 'חופשות';
  }
  return "";
}


const productTypeToCategoryUrl = product => {
  switch (product.typeDetails.type) {
    case eProductCategoryType.TOURISM:
      return linksMap.tourism;
    case eProductCategoryType.SHOPPING:
      return linksMap.shopping;
    case eProductCategoryType.SPORT:
      return linksMap.sport;
  }
  return linksMap.home;
};

const productSubTypeToCategoryUrl = product => {
  if (product.typeDetails) {
    const ptypes = getProductTypesWithClassification(productTypes, product.typeDetails.type, product.typeDetails.subType)
      .filter(productType => !productType.showResultsInSubCategory);
    if (ptypes.length) {
      return ptypes[0].url;
    }
  }
  return linksMap.home;
};

export const productToCategoryUrl = product => {
  return [productTypeToCategoryUrl(product), productSubTypeToCategoryUrl(product)].join('/');
};

export const identifyTypeDbName = (product, overrides: any = {}) => {
  switch (identifyProductCategoryType(product, overrides)) {
    case hotelDetails:
      return hotel_TYPE;
    case ticketDetails:
      return flight_TYPE;
    case voucherDetails:
      return voucher_TYPE;
    case vacationDetails:
      return vacation_TYPE;
  }
  return "";
}


export const typeStringToNumber = (type: string) => {
  switch (type.toLowerCase()) {
    case flights_TYPE:
    case flight_TYPE:
    case myFlights_TYPE:
      return eProductSubCategoryType.FLIGHT;
    case hotels_TYPE:
    case hotel_TYPE:
    case myHotels_TYPE:
      return eProductSubCategoryType.HOTEL;
    case vouchers_TYPE:
    case voucher_TYPE:
    case myVouchers_TYPE:
    case cruise_TYPE:
      return eProductSubCategoryType.VOUCHER;
    case vacations_TYPE:
    case vacation_TYPE:
    case myVacations_TYPE:
      return eProductSubCategoryType.VACATION;
  }
  return 0;
};

export const typeTourismToDetails = (tourismType: eProductSubCategoryType): string => {
  switch (tourismType) {
    case eProductSubCategoryType.FLIGHT:
      return ticketDetails;
    case eProductSubCategoryType.HOTEL:
      return hotelDetails;
    case eProductSubCategoryType.CRUISE:
    case eProductSubCategoryType.AGENCY:
    case eProductSubCategoryType.VOUCHER:
      return voucherDetails;
    case eProductSubCategoryType.VACATION:
      return vacationDetails;
  }
  return '';
}


export const typeAndSubTypeToDetails = (type: eProductCategoryType, subType: eProductSubCategoryType) => {
  switch (type) {
    case eProductCategoryType.TOURISM:
      return typeTourismToDetails(subType);
  }
  return 0;
}
/**
 * used for things like replacing vacationDetails with ticketDetails because vacationDetails dont exist
 * @param detailsType
 * @param overrides
 */
export const detailsTypeOverrider = (detailsType: string, overrides: any = {[vacationDetails]: ticketDetails}): string => {
  if (detailsType in overrides) {
    return overrides[detailsType];
  }
  return detailsType;
}


export const productTypeToVoucher = (type: string) => {
  switch (type) {
    case flights_TYPE:
    case flight_TYPE:
      return eVoucherTypes.flightCompanies;
    case hotels_TYPE:
    case hotel_TYPE:
      return eVoucherTypes.hotels;
    case vacations_TYPE:
    case vacation_TYPE:
      return eVoucherTypes.agencies;
    default:
      return eVoucherTypes.none;
  }
}
