import React from "react";
import styles from './DealAndGoLogo.module.scss';
import LinkBlockContainer from 'data/redux/containers/linkBlockContainer/LinkBlockContainer';

interface IDealAndGoLogoProps {
  noPadding: boolean;
}

const DealAndGoLogo = (props: IDealAndGoLogoProps) => {
  return (
    <LinkBlockContainer
      brand
      to="/"
      className={styles.dealgo}
      style={{
        ...(props.noPadding ? {padding: 0} : {})
      }}
      src="/img/logo.svg"
      aria-label="Logo"
    />
  );

};

DealAndGoLogo.defaultProps = {
  noPadding: false,
};

export default React.memo(DealAndGoLogo);
