import {connect} from 'react-redux'
import {setLoggedInButtonMenuClose, setLoggedInButtonMenuOpen} from 'data/redux/actions/guiActions';
import LoggedInAccountButton
  from 'gui/pages/mainRouter/components/navigation/components/authenticationNavigator/components/loggedIn';
import {getPathName} from 'data/redux/selectors/routerSelector';


const mapStateToProps = ({gui, router}) => {
  return {
    menuOpen: gui.loggedInButtonMenuOpen,
    pathName: getPathName({router}),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openMenu: () => dispatch(setLoggedInButtonMenuOpen()),
    closeMenu: () => dispatch(setLoggedInButtonMenuClose()),
  };
};

const LoggedInAccountButtonContainer = connect(mapStateToProps, mapDispatchToProps)(LoggedInAccountButton);

export default LoggedInAccountButtonContainer;
