import React from 'react';
import {Helmet} from "react-helmet";
import {allPossibleUrls, config, linksMap, productTypes} from 'data/utils/constants';

interface ISeoProps {
  title: string;
  metaDescription: string;
  pathName: string;
  loading?: boolean;
}

/**
 * check if url is possible
 * @param pathName
 */
const isPossibleUrl = pathName => {
  for (const possibleUrl of allPossibleUrls) {
    if (possibleUrl.length <= 1) {
      if (pathName === possibleUrl) {
        return true;
      }
    } else {
      if (pathName.startsWith(possibleUrl)) {
        return true;
      }
    }
  }
  return false;
};

const formatPathName = pathName => {
  if (pathName) {
    if (isPossibleUrl(pathName)) {
      if (pathName.startsWith('/')) {
        return `${config.api_server.baseUrl}${pathName.replace(/\/\/+/g, '/')}`;
      }
      return `${config.api_server.baseUrl}/${pathName.replace(/\/\/+/g, '/')}`;
    }
    return `${config.api_server.baseUrl}//404`;
  }
  return window.location.href;
};

const Seo = ({loading, title, metaDescription, pathName}: ISeoProps) => {
  return loading ? null : (
    <Helmet>
      <title>{title || ''}</title>
      <meta name="description" content={metaDescription || ''}/>
      <link rel="canonical"
            href={formatPathName(pathName)}/>
    </Helmet>
  );
};
Seo.defaultProps = {};
export default React.memo(Seo);
