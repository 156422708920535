import {createAction} from 'redux-starter-kit';
import {LOCATION_POP} from 'redux-location-state/lib/constants';
import {
  getLastPossibleNavigationLoggedIn,
  getPathName,
  getQueryParamsSelector,
  isPathName
} from 'data/redux/selectors/routerSelector';
import {isLoggedInSelector} from 'data/redux/selectors/authenticationSelector';
import {push} from 'connected-react-router';
import {getLastPossibleNavigationExcept, serializeObjectToQueryParams} from 'data/utils/shortcuts';
import {linksMap} from 'data/utils/constants';

export const collapseToggle = createAction("collapseToggle");
export const locationPop = createAction(LOCATION_POP);
export const toggleVacationTypeTab = createAction("toggleVacationTypeTab");
export const toggleNavigation = createAction("toggleNavigation");
export const addPathToHistory = createAction("addPathToHistory")

export function collapseToggleState(state: boolean = null) {
  return (dispatch, getState) => {
    dispatch(collapseToggle({open: state}));
    if (getState().navigation.menuCollapseToggle) {
      document.body.style.overflow = 'hidden';
      document.body.style['-webkit-overflow-scrolling'] = 'touch';
    } else {
      document.body.style.overflow = 'auto';
      delete document.body.style['-webkit-overflow-scrolling'];
    }

  };
}

export function toggleNavigationState(state: boolean = false) {
  return (dispatch) => {
    dispatch(toggleNavigation({allow: state}));
  };
}

export function navigateToPageBeforeLogin() {
  return (dispatch, getState, {waitForState}) => {
    return waitForState(state => isLoggedInSelector(state))
      .then(loggedIn => dispatch(navigateIfNotThere(getLastPossibleNavigationLoggedIn(getState()))));
  };
}

/**
 * Navigate to path ONLY if the current path is not there according to state.
 * This measure prevents navigation loops especially when the website is just loading.
 * @param pathName
 */
export function navigateIfNotThere(pathName: string, queryParams?: any) {
  return (dispatch, getState) => {
    const combinedWithQueryParamsPathName = `${pathName}${(queryParams && typeof queryParams === 'object') ? `?${serializeObjectToQueryParams(queryParams)}` : ''}`;
    if (!isPathName(combinedWithQueryParamsPathName)(getState())) {
      return dispatch(push(combinedWithQueryParamsPathName));
    }
    return false;
  };
}

export function navigateSetQueryParams(queryParams?: any) {
  return (dispatch, getState) => {
    const currentQueryParams = getQueryParamsSelector(getState());
    const keys1 = Object.keys(currentQueryParams).sort();
    const keys2 = Object.keys(queryParams).sort();
    const values1 = keys1.map(key => `${currentQueryParams[key]}`);
    const values2 = keys2.map(key => `${queryParams[key]}`);
    if (JSON.stringify(values1) !== JSON.stringify(values2)) {
      // console.log(values1, values2);
      dispatch(navigateIfNotThere(getPathName(getState()), queryParams));
    }
  };
}


export function navigateToPageExpectFor(...pagesToOmit) {
  return (dispatch, getState, {waitForState}) => {
    return waitForState(state => isLoggedInSelector(state))
      .then(loggedIn => dispatch(navigateIfNotThere(getLastPossibleNavigationExcept(getState().navigation.history, ...pagesToOmit) || linksMap.dashboard)));
  };
}
