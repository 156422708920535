import {services} from 'data/redux/reducers/feathersReducers';
import {createAction} from 'redux-starter-kit';
import {IProduct} from 'data/interfaces/products';
import {ITransaction} from 'data/interfaces/transactions';
import {adProductSelector} from 'data/redux/selectors/productSelector';
import {completeOrder} from 'data/redux/actions/ordersActions';
import {orderSelector} from 'data/redux/selectors/orderSelector';
import {IOrder, IOrderOptions} from 'data/interfaces/orders';
import {isLoggedInSelector} from 'data/redux/selectors/authenticationSelector';
import {getLoadProductActionCreator} from 'data/redux/actions/productsActions';
import {setLoadingState} from 'data/redux/actions/guiActions';
import {TRANSACTION} from "data/enums/transactions";


export const setTransaction = createAction("setTransaction");
export const clearTransaction = createAction("clearTransaction");
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
const CANCEL = 'CANCEL';

export function beginTransaction(product: IProduct, options: IOrderOptions = {}) {
  return (dispatch, getState, {waitForState}) => {
    dispatch(setLoadingState(true));
    return waitForState(state => isLoggedInSelector(state))
      .then(() => dispatch(services.transactions
        .create({
          'product_id': product.id,
          'options': options,
          //  type: identifyProductCategoryType(product),
        })))
      .then(({value}) => {
        if(value.url){
          dispatch(setTransaction({
            product_id: product.id,
            ...value,
          }));
        }
        dispatch(setLoadingState(false));
        return value;
      })
  };
}


export function completeTransaction(transaction: ITransaction) {
  return (dispatch, getState, {waitForState}) => {
    return waitForState(state => isLoggedInSelector(state))
      .then(() => {
        switch (transaction.type) {
          case SUCCESS:
            dispatch(setTransaction(transaction));
            const product = adProductSelector(getState());
            const order: IOrder = orderSelector(product)(getState());
            if (product && order && product.id === transaction.product_id) {
              return dispatch(completeOrder(product, order.options, transaction))
                .then(() => dispatch(getLoadProductActionCreator(product.id)));
            }
            
            return;
          case ERROR:
            return dispatch(setTransaction(transaction));
          case CANCEL:
            return dispatch(clearTransaction(transaction));
        }
      })
  };
}


const performTransactionUpdate = (transaction, newTransactionStatus: TRANSACTION) => {
  return (dispatch, getState, {waitForState}) => {
    return waitForState(state => isLoggedInSelector(state))
      .then(() => dispatch(services.transactions
        .update(
          transaction.id
          , {
            status: newTransactionStatus,
          })))
  };
};


export const performTransactionCharge = (transaction) => {
  return performTransactionUpdate(transaction, TRANSACTION.CHARGED);
};

export const performTransactionRefund = (transaction) => {
  return performTransactionUpdate(transaction, TRANSACTION.REFUNDED);
};
