import {connect} from 'react-redux'
import {getPathName} from 'data/redux/selectors/routerSelector';
import Seo from 'gui/components/Seo/Seo';
import {pathToSeoDetail} from 'data/utils/shortcuts';


const mapStateToProps = (state, {preProcess, loading}) => {
  const pathName = getPathName(state);
  return {
    ...pathToSeoDetail(pathName, preProcess, state),
    loading,
    pathName,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const SeoContainer: any = connect(mapStateToProps, mapDispatchToProps)(Seo as any);

export default SeoContainer;
