import {services} from 'data/redux/reducers/feathersReducers';
import {createAction} from 'redux-starter-kit';
import {setLoadingState} from './guiActions';
import uniqWith from 'uniq-with';
import {isLoggedInSelector} from 'data/redux/selectors/authenticationSelector';
import {IPlaceDetails, IProduct, IProductTypes} from 'data/interfaces/products';
import {getInitializationTimeStamp, isLoadingSelector} from 'data/redux/selectors/guiSelector';
import {
  ad_TYPE,
  allProductStates,
  allProductTypes,
  allProductTypesWithAddons,
  autoAddAddons,
  hotelDetails,
  isTypeInTypes,
  productAssociatedStates,
  ticketDetails,
  typeToTableName
} from 'data/redux/constants/productsConstants';
import {OWNER_PERMISSIONS} from 'data/redux/constants/permissionsConstants';
import {generateStatusQuery} from 'data/utils/actionTools';
import {promiseAwaitIgnoreReject} from 'data/utils/shortcuts';
import {
  convertProductToShekels,
  deleteOriginalDetails,
  urlOrTypeProductPageSelector,
  urlOrTypeProductSkipSelector
} from "../selectors/productSelector";
import {max} from 'lodash';
import {userIsAllowed} from '../selectors/userSelector';
import {USER_TYPE_ADMIN} from "../../utils/constants";
import {parseExpression, stringifyExpression} from 'relation-expression-normalizer';


import {getCreateDocumentWithFilesForProduct, getDeleteAllFilesFromDocumentForProduct} from "./documentsActions";
import {productTypeKey} from "../../utils/products";
import {ASSET_TYPE} from "../../enums/assets";

export const setProductsServiceRequestSkip = createAction("setProductsServiceRequestSkip");
export const setProductsServiceRequestLimit = createAction("setProductsServiceRequestLimit");
export const setProductsServiceRequestTotal = createAction("setProductsServiceRequestTotal");
export const setProductList = createAction("setProductList");
export const setProductStatusCount = createAction("setProductStatusCount");
export const setProduct = createAction("setProduct");
export const updateProductModifications = createAction("updateProductModifications");


const queryAddressComponentsByType = (components: IPlaceDetails["address_components"], ...types): IPlaceDetails["address_components"][0] | any => {
  return components.find(component => !!component.types.find(type => types.map(t => t.toLowerCase().trim()).includes(type.toLowerCase().trim()))) || {long_name: ''};
}
const bankBranchesCache = {};

const getPlaceImagePicks = (product: Partial<IProduct>, brokenPng?) => {
  const imagePick = (product.assets || [])
    .filter(({type}) => type === ASSET_TYPE.PLACE)
    .map(({asset: {sourceLink}}) => sourceLink);
  if (brokenPng) {
    return imagePick
      .map(sourceLink => sourceLink.endsWith("broken.png") ? brokenPng : sourceLink);
  }
  return imagePick;
};

const getAreasPick = (product: Partial<IProduct>) => {
  return (product.areaDetails || [])
    .map(areaDetail => areaDetail.area_id);
}

export const preProcessReceivedProduct = async (product: Partial<IProduct>, state) => {
  if (product.placeDetails && product.placeDetails.length) {
    if (product.hotelDetails) {
      product.hotelDetails.hotel = product.placeDetails[0];
    }
    if (product.voucherDetails) {
      product.voucherDetails.placeDetails = product.placeDetails[0];
    }
  }
  if (product.hotelDetails && product.hotelDetails.hotel) {
    product.hotelDetails.hotel.country = queryAddressComponentsByType(product.hotelDetails.hotel.address_components, 'country').long_name;
    product.hotelDetails.hotel.countryShort = queryAddressComponentsByType(product.hotelDetails.hotel.address_components, 'country').short_name;
    product.hotelDetails.hotel.city = queryAddressComponentsByType(product.hotelDetails.hotel.address_components, 'locality').long_name || queryAddressComponentsByType(product.hotelDetails.hotel.address_components, 'administrative_area_level_1').long_name;
    product.hotelDetails.hotel.street = `${queryAddressComponentsByType(product.hotelDetails.hotel.address_components, 'route').long_name} ${queryAddressComponentsByType(product.hotelDetails.hotel.address_components, 'street_number').long_name}`;
    /*
     product.hotelDetails.hotel.countryTranslation = {
       HE: await services['ip-2-location-country'].get("HE", {query: {country_alpha2_code: product.hotelDetails.hotel.countryShort}}).payload.promise
     };

     */
    product.hotelDetails.imagePick = product.hotelDetails.imagePick || getPlaceImagePicks(product, '/img/defaultHotel.webp');
  }
  if (product.bankDetails) {
    /*
    const key = `${product.bankDetails.bank}-${product.bankDetails.branch}`
    let branch = bankBranchesCache[key];
    if (!branch) {
      console.log(3333333, JSON.parse(JSON.stringify(product.bankDetails)));
      const {value} = await services['banks-autocomplete']
        .get(`${product.bankDetails.branch}`, {
          query: {
            bankCode: product.bankDetails.bank,
            inputType: 'BRANCH_CODE'
          }
        })
        .payload
        .promise
        .catch(e => Promise.resolve({value: []}));
      if (value.length > 0) {
        console.log(44444444, value[0]);
        branch = value[0];
        bankBranchesCache[key] = branch;
      }
    }

    if (branch) {
      //  product.bankDetails.bank = branch;
      //  product.bankDetails.branch = branch;
      product.bankDetails.branchDetails = branch;
    }
   */
    product.bankDetails.branchDetails = {...product.bankDetails};
  }
  if (product.ticketDetails) {
    if (product.ticketDetails.destinationFlightCompany) {
      if (product.ticketDetails.destinationTimeOfTakeOff)
        product.ticketDetails.destinationTimeOfTakeOff = new Date(product.ticketDetails.destinationTimeOfTakeOff).getTime();
      if (product.ticketDetails.destinationTimeOfLanding)
        product.ticketDetails.destinationTimeOfLanding = new Date(product.ticketDetails.destinationTimeOfLanding).getTime();
      product.ticketDetails.isRoundTripTicket = true;
    } else {
      delete product.ticketDetails.destinationFlightCompany;
      delete product.ticketDetails.destinationTimeOfTakeOff;
      delete product.ticketDetails.destinationTimeOfLanding;
      product.ticketDetails.isRoundTripTicket = false;
    }
  }
  if (product.voucherDetails) {
    product.voucherDetails.imagePick = product.voucherDetails.imagePick || getPlaceImagePicks(product, '/img/defaultHotel.webp');
    product.voucherDetails.areasPick = product.voucherDetails.areasPick || getAreasPick(product);
  }
  if (!Array.isArray(product.assets)) {
    product.assets = [];
  }


  product.retrievedAt = getInitializationTimeStamp(state);
  return product;
};

const preProcessReceivedProducts = async ({value, ...rest}, state) => {
  return {
    ...rest,
    value: {
      ...value,
      data: await Promise.all(uniqWith(((row1, row2) => row1.id === row2.id), (value.data || []).filter(row => row && row.id)).map((product) => preProcessReceivedProduct(product, state)))
    }
  };
};

const preProcessSentProduct = ({
                                 assets,
                                 areaDetails,
                                 permissions,
                                 productIdentifier,
                                 product_type_id,
                                 placeDetails,
                                 ...product
                               }: Partial<IProduct>) => {
  return deleteOriginalDetails(product);
  // return product;
};


export const removeAssociatedStateFromPath = (path) => {
  if (path) {
    for (const key in productAssociatedStates) {
      if (path.endsWith(key)) {
        return path.slice(0, path.length - key.length - 1);
      }
    }
  }
  return path;
}

export function getChangeProductPage(pageNumber: number, type: string) {
  return (dispatch, getState) => {
    dispatch(setProductsServiceRequestSkip({
      type,
      skip: Math.min(Math.ceil(getState().products[type].serviceRequestProperties.total / 10) * 10 - getState().products[type].serviceRequestProperties.limit, getState().products[type].serviceRequestProperties.limit * (Math.max(pageNumber - 1, 0))),
    }));
    return dispatch(getLoadUserProductsActionCreator_deprecated(null, type));
  };
}

export function getInsertProductActionCreator({document, documents, files, ...formValues}: any) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    const uploadFilesToNewDocument = files || [];
    return dispatch(services
      .products
      .create(preProcessSentProduct(formValues)))
      .then(async result => {
        dispatch(setLoadingState(false));
        const productId = result.value.id;
        if (uploadFilesToNewDocument.length > 0) {
          await dispatch(getCreateDocumentWithFilesForProduct(productId, uploadFilesToNewDocument, true));
        }
        return result;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.reject(e);
      });
  };
}

export function getUpdateProductActionCreator({files, document, ...product}: Partial<IProduct>) {
  return (dispatch, getState) => {
    dispatch(setLoadingState(true));
    const uploadFilesToExistingDocument = files || [];
    return dispatch(services
      .products
      .patch(product.id,
        preProcessSentProduct(product)))
      .then(async result => {
        if (uploadFilesToExistingDocument.length > 0) {
          await dispatch(getDeleteAllFilesFromDocumentForProduct(product.id));
          await dispatch(getCreateDocumentWithFilesForProduct(product.id, uploadFilesToExistingDocument, true));
        }
        return result;
      })
      .then(result => {
        /**
         * Todo: someday disable convertProductToShekels,  temporary code choice to make sure redux keeps shekels.  This should come maybe from server side.
         */
        dispatch(updateProductModifications(preProcessReceivedProduct({
          ...convertProductToShekels(product),
          status: result.value ? result.value.status : product.status,
        }, getState())));
        dispatch(setLoadingState(false));
        return result;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.reject(e);
      });
  };
}

export function getLoadUserProductsActionCreator_deprecated(userId?: number, ...types) {
  if (!types) types = allProductTypes;
  return ((dispatch, getState, {waitForState}) =>
    Promise.all(types.map(type =>
      waitForState(state => isLoggedInSelector(state))
        .then(() => dispatch(setProductList({
          type: type
        })))
        .then(() => {
          dispatch(setLoadingState(true));
          if (!userId) {
            if (getState().auth.user)
              userId = getState().auth.user.id;
          }
          return userId
        })
        .then((userId) => dispatch(services.products
          .find({
            query: {
              $joinRelation: `[${(typeToTableName)[type]},permissions]`,
              $eager: `[${autoAddAddons([typeToTableName[type]])},assets.[asset],saleDetails.currencyDetails,permissions]`,
              $skip: getState().products.myFlights.serviceRequestProperties.skip,
              $limit: getState().products.myFlights.serviceRequestProperties.limit,
              'permissions.user_id': userId,
              'permissions.permission': {
                $in: OWNER_PERMISSIONS
              },
            }
          })))
        .then((result) => preProcessReceivedProducts(result, getState()))
        .then(({value, ...rest}) => {
          dispatch(setProductList({
            ...value,
            type: type
          }));
          dispatch(setLoadingState(false));
          return value;
        })
        .catch(e => {
          dispatch(setLoadingState(false));
          return Promise.resolve();
        })
    ))
      .then(results => results.flat()));
}

/**
 * Generate proper state query using both, redux state, url and options
 * @param options
 * @param getState
 */
const generateProductStatusQuery = (options, getState) => {
  return generateStatusQuery(options, getState, productAssociatedStates, allProductStates);
}

/**
 * Get products of the current user
 * @param userId
 * @param limit
 * @param skip
 * @param options
 */
export function getLoadUserProductsActionCreator(userId?: number, limit = null, skip = null, options: { query?: any, type?: string, status?: number, statusCount?: boolean } = {}) {
  return ((dispatch, getState, {waitForState}) => {
    const query = generateProductStatusQuery(options, getState);
    return waitForState(state => isLoggedInSelector(state))
      .then(() => dispatch(setProductList({
        type: "myProducts"
      })))
      .then(() => {
        dispatch(setLoadingState(true));
        if (!userId) {
          if (getState().auth.user)
            userId = getState().auth.user.id;
        }
        return userId
      })
      .then((userId) => dispatch(services
        .products
        .find({
          query: {
            $joinRelation: `[permissions]`,
            $eager: `[${[
              allProductTypesWithAddons.join(","),
              'assets.[asset]',
              'saleDetails.currencyDetails',
              'permissions',
              'continentDetails.translations',
              'placeDetails.places',
              'areaDetails.area',
              'typeDetails',
              'typeAssociationDetails.typeDetails'
            ].join(',')}]`,
            $skip: skip || getState().products.myProducts.serviceRequestProperties.skip,
            $limit: limit || getState().products.myProducts.serviceRequestProperties.limit,
            'permissions.user_id': userId,
            $sort: {
              updatedAt: -1,
            },
            ...(options.statusCount ? {"$modify": 'statusCounts'} : {
              'permissions.permission': {
                $in: OWNER_PERMISSIONS
              }
            }),
            ...query
          }
        })))
      .then((result) => options.statusCount ? result : preProcessReceivedProducts(result, getState()))
      .then(({value}) => {
        if (options.statusCount) {
          dispatch(setProductStatusCount({
            ...value,
            type: "myProducts"
          }));
        } else {
          dispatch(setProductList({
            ...value,
            type: "myProducts"
          }));
        }
        dispatch(setLoadingState(false));
        return value;
      })
      .catch(e => {
        
        dispatch(setLoadingState(false));
        return Promise.resolve();
      });
  });
}

export function getLoadProductActionCreator(productId: number, {
  updateModification,
  loggedIn,
  ...customQueryOverrides
}: any = {updateModification: false, loggedIn: false}) {
  const types = allProductTypes;
  return async (dispatch, getState, {waitForState}) => {
    await waitForState(state => !isLoadingSelector(state));
    if (loggedIn === true) {
      await waitForState(state => isLoggedInSelector(state));
    }
    return dispatch(services.products
      .find({
        query: {
          id: productId,
          $eager: `[${[
            autoAddAddons(types).join(),
            'assets.[asset]',
            'saleDetails.currencyDetails',
            ...(userIsAllowed(USER_TYPE_ADMIN)(getState()) ? ['permissions.userDetails'] : ['permissions']),
            'bankDetails',
            'continentDetails.translations',
            'placeDetails.places',
            'areaDetails.area',
            'typeDetails',
            'typeAssociationDetails.typeDetails'
          ].join(',')}]`,
          $skip: 0,
          $limit: 1,
          ...customQueryOverrides,
        }
      }))
      .then((result) => preProcessReceivedProducts(result, getState()))
      .then(({value}) => {
        if (value.data.length > 0) {
          if (updateModification === true) {
            dispatch(updateProductModifications({
              data: value.data[0],
              type: ad_TYPE
            }));
          } else {
            dispatch(setProduct({
              data: value.data[0],
              type: ad_TYPE
            }));
          }
          return value.data[0];
        }
      })
      .catch(e => {
        return Promise.resolve(e);
      });
  };
  return (dispatch, getState, {waitForState}) => waitForState(state => !isLoadingSelector(state))
    .then(() => dispatch(services.products
      .find({
        query: {
          id: productId,
          /*
          $eager32: `[${[
            autoAddAddons(types).join(),
            'assets.[asset]',
            'saleDetails',
            'permissions',
            'bankDetails',
            'continentDetails',
            'translations',
          ].join(',')}]`,

           */
          $eager: `[${[
            autoAddAddons(types).join(),
            'assets.[asset]',
            'saleDetails.currencyDetails',
            ...(userIsAllowed(USER_TYPE_ADMIN)(getState()) ? ['permissions.userDetails'] : ['permissions']),
            'bankDetails',
            'continentDetails.translations',
          ].join(',')}]`,
          $skip: 0,
          $limit: 1,
          ...customQueryOverrides,
        }
      })))
    .then((result) => preProcessReceivedProducts(result, getState()))
    .then(({value}) => {
      if (value.data.length > 0) {
        if (updateModification === true) {
          dispatch(updateProductModifications({
            data: value.data[0],
            type: ad_TYPE
          }));
        } else {
          dispatch(setProduct({
            data: value.data[0],
            type: ad_TYPE
          }));
        }
        return value.data[0];
      }
    })
    .catch(e => {
      return Promise.resolve();
    });
}

export function getDeleteProductActionCreator(productId: number) {
  return (dispatch, getState, {waitForState}) =>
    waitForState(state => isLoggedInSelector(state))
      .then(() => dispatch(setLoadingState(true)))
      .then((userId) => dispatch(services.products
        .remove(productId)))
      .then(({value}) => {
        dispatch(setLoadingState(false));
        return value;
      })
      .catch(e => {
        dispatch(setLoadingState(false));
        return Promise.resolve();
      });
}

/**
 * Generate eager and joinRelations
 * @param inputType the original type
 * @param queryType custom type in queryOptions overriding inputType
 * @param modify modify final eager
 * @param queryOptions
 */
const typeToEagerRelations = (inputType, {
  type: queryType,
  modify,
  skipEagerRelations,
  joinRelations: _joinRelations,
  ...queryOptions
}) => {
  if (skipEagerRelations) {

    return queryOptions;
  }
  const type = queryType || inputType;
  const joinRelations = ['typeAssociationDetails.typeDetails', 'saleDetails', ...(_joinRelations || [])];
  const eager = [
    'assets.[asset]',
    'saleDetails.currencyDetails',
    'placeDetails.places',
    'areaDetails.area',
    'typeDetails',
    'typeAssociationDetails.typeDetails',
    'voucherDetails.[companyDetails.assets.asset,flightCompanyDetails]',
    ...((type && type.query && type.query.$eager) || [])
  ];

  if (isTypeInTypes([ticketDetails, hotelDetails], ...(typeToTableName[type] || '').split(","))) {
    joinRelations.push('continentDetails.translations');
    eager.push('continentDetails.translations');
  }
  const $eager = parseExpression(`[${uniqWith((a, b) => a === b, eager.filter(e => e && e.length > 0)).join(',')}]`);
  if (ticketDetails in $eager) {
    $eager[ticketDetails].originFlightCompanyDetails = true;
    $eager[ticketDetails].destinationFlightCompanyDetails = true;
    $eager[ticketDetails].originAirportDetails = true;
    $eager[ticketDetails].destinationAirportDetails = true;
  }
  if (hotelDetails in $eager) {
  }
  const eagerAndRelations = {
    ...((type && type.query) || {}),
    $joinRelation: `[${uniqWith((a, b) => a === b, joinRelations.filter(e => e)).join(',')}]`,
    $eager: stringifyExpression($eager),
    ...queryOptions,

  };
  if (typeof modify === 'function') {
    return modify(eagerAndRelations, type, queryOptions);
  }
  return eagerAndRelations;
}

/**
 * Get products of a certain type
 * @param type
 * @param skip
 * @param limit
 * @param queryOptionsList
 */
export function getLoadProductsByTypeActionCreator(type: IProductTypes, _skip = 0, _limit = 10, ...queryOptionsList: {}[]) {
//  console.log(3123123, type, _skip, _limit, queryOptionsList);
  return (dispatch, getState) => {
    const typeKey = type.key || productTypeKey(type);
    const limit = _limit || urlOrTypeProductPageSelector(typeKey)(getState());
    const page = urlOrTypeProductPageSelector(typeKey)(getState());
    const skip = _skip || Math.max(0, page - 1) * limit || urlOrTypeProductSkipSelector(typeKey)(getState());
    return promiseAwaitIgnoreReject(queryOptionsList
      .flat()
      .map(({$joinRelationArray, ...queryOptions}: any) => dispatch(services.products
        .find({
          query: {
            $skip: skip,
            $limit: limit,
            ...typeToEagerRelations(type, queryOptions),
          }
        }))))
      .then(results => {
        return ({
          value: {
            ...results[0].value,
            total: max(results.map(({value}) => value.total)) || 1,
            data: results.map(({value}) => value.data).flat(),
            originalResults: results
          }
        });
      })
      .then((result) => preProcessReceivedProducts(result, getState()))
      .then(({value}: any) => {
        dispatch(setProductList({
          ...value,
          data: value.data,
          type: typeKey,
        }));
        return value.data;
      })
      .catch(e => {
        
        return Promise.resolve([]);
      });
  }
}

export function getLoadAllProductsActionCreator(...queryOptionsList: any[]) {
  return (dispatch, getState) =>
    Promise
      .all(queryOptionsList
        .flat()
        .map(({$eagerAdditions, $joinRelationArray, ...queryOptions}) =>
          dispatch(services.products
            .find({
              query: {
                $eager: `[${uniqWith((a, b) => a === b, [autoAddAddons(allProductTypes).join(","), 'typeDetails', 'typeAssociationDetails.typeDetails', 'assets.[asset]', 'saleDetails.currencyDetails', 'permissions.userDetails', ...($eagerAdditions || [])]).join(',')}]`,
                ...queryOptions,
              }
            }))))
      .then(results => {
        return ({
          value: {
            ...results[0].value,
            total: max(results.map(({value}) => value.total)),
            data: results.map(({value}) => value.data).flat(),
            originalResults: results
          }
        });
      })
      .then((result) => preProcessReceivedProducts(result, getState()))
      .then(({value}: any) => {
        dispatch(setProductList({
          ...value,
          data: value.data.map(product => {
            if (product.permissions.length === 0) {
              product.permissions.push({});
            }
            product.permissions.forEach(permission => {
              if (!permission.userDetails) {
                permission.userDetails = {};
              }
            });
            return product;
          }),
          type: 'allProducts'
        }));
        return value.data;
      })
      .catch(e => {
        
        return Promise.resolve([]);
      });
}

export function getProductTypeId(productType, productSubType) {
  return (dispatch, getState) => {
    return dispatch(
      services['product-types']
        .find({
          query: {
            type: productType,
            subType: productSubType,
          }
        })
    );
  }
}
