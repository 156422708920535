
export enum FILE {
  BINARY = 0b000,
  IMAGE = 0b001,
  SOUND = 0b010,
}

export enum SOURCE {
  LOCAL = 0b000,
  SERVER = 0b001,
  GOOGLE = 0b010,
  CLEARBIT = 0b011,
  BRANDSOFTHEWORLD = 0b100,
};


export enum ASSET_TYPE {
  PLACE = 1,
  ITEM = 2,
  PERSON = 3,
  LOGO = 4,
};
