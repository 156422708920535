import {
  hotelDetails,
  PRODUCT_STATUS_TYPE_CANCELED,
  PRODUCT_STATUS_TYPE_OPEN,
  PRODUCT_STATUS_TYPE_SOLD,
  ticketDetails,
  voucherDetails
} from 'data/redux/constants/productsConstants';
import {
  ORDER_STATUS_TYPE_CANCELED,
  ORDER_STATUS_TYPE_OPEN,
  ORDER_STATUS_TYPE_SOLD
} from 'data/redux/constants/ordersConstants';
import UrlPattern from 'url-pattern';
import {IProductTypes} from 'data/interfaces/products';
import {productTypeClassification, productTypeClassifications} from "./products";
import {eProductSubCategoryType, eProductTags, eProductCategoryType} from "../enums/products";
import {pick, uniq} from "lodash";

export const config = {
  facebook: {
    appId: '',
  },
  google: {
    login: '',
    recaptcha: '',
    maps: '',
  },
  crisp: {
    websiteId: '',
  },
  web_client: {
    baseUrl: ''
  },
  api_server: {
    baseUrl: '',
    placeImageUrl: ''
  },
  ...((window as any).config || {}),
};

config.api_server.placeImageUrl = config.api_server.placeImageUrl || config.api_server.baseUrl;

export const versionStamp = new Date(2022, 6, 15).getTime();

export const USER_TYPE_GUEST = 0b001;
export const USER_TYPE_USER = 0b010;
export const USER_TYPE_ADMIN = 0b100;
export const USER_TYPE_ANY = USER_TYPE_GUEST | USER_TYPE_USER | USER_TYPE_ADMIN;


export const typeSeperator = 'category';
//remember to modify css inside the module to reflect the new urls
export const linksMap = {
  root: "/",
  home: "/",
  logout: "/logout",
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  verifyToken: "/verify-token",
  control: "/control",

  howItWorks: "/how-it-works",
  howItWorksBuyer: "/how-it-works-buyer",
  howItWorksSeller: "/how-it-works-seller",
  article1: "/article1",
  about: "/about",
  contactUs: "/contact-us",
  terms: "/terms",
  accessibility: "/accessibility",
  privacyPolicy: "/privacy-policy",
  ad: "/ad",
  voucher: "/voucher",
  voucherId: "/voucher/:id",
  adIdSlug: "/ad/:id/:slug?",
  adIdSlug_: "/ad/:id/:slug",
  adEditIdSlug: "/ad/edit/:id/:slug?",

  checkoutIdSlug: "/checkout/:id",

  reportId: "/report/:id",

  homeTypes: `/${typeSeperator}/:${typeSeperator}`,

  tourism: "/tourism",
  tourismTypes: "/tourism/type/:type",

  experiences: "/shopping",
  experienceTypes: "/shopping/type/:type",

  shopping: "/shopping",
  shoppingTypes: "/shopping/type/:type",

  sport: "/sport",
  sportTypes: "/sport/types/:type",

  faq: "/faq",


  dashboard: '/control/dashboard',
  productsByMe: '/control/products-by-me',
  productsByMeOpen: `/control/products-by-me/${PRODUCT_STATUS_TYPE_OPEN}`,
  productsByMeSold: `/control/products-by-me/${PRODUCT_STATUS_TYPE_SOLD}`,
  productsByMeTimedOut: `/control/products-by-me/${PRODUCT_STATUS_TYPE_CANCELED}`,

  ordersByMe: '/control/orders-by-me',
  ordersByMeOpen: `/control/orders-by-me/${ORDER_STATUS_TYPE_OPEN}`,
  ordersByMeSold: `/control/orders-by-me/${ORDER_STATUS_TYPE_SOLD}`,
  ordersByMeTimedOut: `/control/orders-by-me/${ORDER_STATUS_TYPE_CANCELED}`,

  createProduct: '/control/create/product',
  profile: '/control/profile',
  admin: '/admin',
  adminProducts: '/admin/products',
  adminPayments: '/admin/payments',
  adminPaymentsOpen: '/admin/payments/open',
  adminPaymentsClosed: '/admin/payments/closed',
  adminNotifications: '/admin/notifications',
  adminNotificationsBuyer: '/admin/notifications/buyer',
  adminNotificationsSeller: '/admin/notifications/seller',
  adminUsers: '/admin/users',
  adminImport: '/admin/import',
  adminReports: '/admin/reports',
  adminSettings: '/admin/settings',
  adminSupport: '/admin/support',


  tourismCreateProductEditor: '/control/create/product/tourism',
  tourismFlightCreateProductEditor: '/control/create/product/tourism/flight',
  tourismFlightCreateProductEditorOrder: '/control/create/product/tourism/flight/order',
  tourismFlightCreateProductEditorVoucher: '/control/create/product/tourism/flight/voucher',
  tourismHotelCreateProductEditor: '/control/create/product/tourism/hotel',
  tourismHotelCreateProductEditorOrder: '/control/create/product/tourism/hotel/order',
  tourismHotelCreateProductEditorVoucher: '/control/create/product/tourism/hotel/voucher',
  tourismVacationCreateProductEditor: '/control/create/product/tourism/vacation',
  tourismVacationCreateProductEditorOrder: '/control/create/product/tourism/vacation/order',
  tourismVacationCreateProductEditorVoucher: '/control/create/product/tourism/vacation/voucher',
  tourismAgencyCreateProductEditor: '/control/create/product/tourism/agency',
  tourismAgencyCreateProductEditorOrder: '/control/create/product/tourism/agency/order',
  tourismAgencyCreateProductEditorVoucher: '/control/create/product/tourism/agency/voucher',

  cultureCreateProductEditor: '/control/create/product/culture',

  shoppingCreateProductEditor: '/control/create/product/shopping',
  shoppingRestaurantCreateProductEditor: '/control/create/product/shopping/restaurant',
  shoppingRestaurantCreateProductEditorOrder: '/control/create/product/shopping/restaurant/one',
  shoppingRestaurantCreateProductEditorVoucher: '/control/create/product/shopping/restaurant/multi',
  shoppingShopCreateProductEditor: '/control/create/product/shopping/shop',
  shoppingShopCreateProductEditorOrder: '/control/create/product/shopping/shop/one',
  shoppingShopCreateProductEditorVoucher: '/control/create/product/shopping/shop/multi',
  shoppingExperienceCreateProductEditor: '/control/create/product/shopping/experience',
  shoppingExperienceCreateProductEditorOrder: '/control/create/product/shopping/experience/one',
  shoppingExperienceCreateProductEditorVoucher: '/control/create/product/shopping/experience/multi',
  shoppingMultiCreateProductEditor: '/control/create/product/shopping/multi',
  phoneNumber: '08-6995131',

}

export const subTitle = 'Deal&Go';
export const generateLinkMapSeoDetail = (title = '', metaDescription = ''): { title: string, metaDescription: string } => ({
  title: title.includes(subTitle) ? title : `${title} | ${subTitle}`,
  metaDescription: metaDescription,
});

export const linksMapSeo = {
  [linksMap.home]: generateLinkMapSeoDetail('זירת מסחר מהפכנית לשוברים יד 2 ✌️ | תמיד מרוויחים!'
    , 'ברוכים הבאים לזירת מסחר של WIN WIN WIN עבור מוכרים וקונים המעוניינים למכור/לרכוש כרטיסי טיסה, מלונות, חופשות, שוברים וחבילות נופש במחירי רצפה! הצטרפו למהפכה >>'),
  [linksMap.tourism]: generateLinkMapSeoDetail('זירת מסחר לכרטיסי טיסה, מלונות ועוד! תמיד מרוויחים!', 'רוצים לקנות או למכור כרטיסי טיסה, חבילות נופש או שוברים לכל מקום בעולם? היכנסו ל-Deal&Go וגלו מאגר ענק של הצעות משתלמות למגוון יעדים במחירים שאסור לפספס!'),
  [linksMap.adIdSlug]: generateLinkMapSeoDetail(["%productName%", '%productDescription%', `| ${subTitle} |`, 'קונים, מוכרים ותמיד מרוויחים'].join(" "), 'מחפשים לרכוש %productName% בזול? Deal&Go הינה זירת מסחר של WIN WIN WIN עבור מוכרים וקונים בענף התיירות. רכישה מאובטחת ובטוחה 100% ✔'),
  [linksMap.about]: generateLinkMapSeoDetail('זירת מסחר מהפכנית לשוברים יד 2 ✌', 'הכירו את האתר שהולך לשנות את חוקי המשחק בענף הכרטיסים ושוברים יד 2! Deal&Go הינה זירת מסחר של WIN WIN WIN עבור מוכרים וקונים במגוון תחומים!'),
  [linksMap.contactUs]: generateLinkMapSeoDetail('דברו איתנו לכל שאלה או בעיה', 'מעוניינים בנציג שירות או פרטים נוספים? יש לכם שאלה או תקלה באתר? נשמח לעמוד לשירותכם ולספק לכם מענה'),
  [linksMap.faq]: generateLinkMapSeoDetail('שאלות ותשובות', 'הכנו במיוחד בשבילכם שאלות נפוצות ותשובות מלאות שיעשו לכם סדר ויסירו כל אי וודאות בכל מה שקשור לקנייה ומכירה של כרטיסים ושוברי יד 2 ✌️2'),
  [linksMap.howItWorks]: generateLinkMapSeoDetail('איך זה עובד? מדריך מפורט + דוגמאות', 'מטרתו של אתר דיל אנד גו היא ליצור זירת מסחר של WIN WIN WIN עבור מוכרים ועבור קונים המעוניינים למכור/לרכוש כרטיסי טיסה, מלונות וחופשות. הנה כל מה שצריך לדעת על התהליך בפועל'),
  [linksMap.dashboard]: generateLinkMapSeoDetail('איזור אישי', ''),
  [linksMap.productsByMe]: generateLinkMapSeoDetail('מוצרים שפרסמתי', ''),
  [linksMap.ordersByMe]: generateLinkMapSeoDetail('מוצרים שברצוני לרכוש', ''),
  [linksMap.profile]: generateLinkMapSeoDetail('הגדרות פרופיל', ''),
  [linksMap.admin]: generateLinkMapSeoDetail('אדמין', ''),
  [linksMap.createProduct]: generateLinkMapSeoDetail('פרסם מכירה', ''),
  [linksMap.adEditIdSlug]: generateLinkMapSeoDetail('עריכת מוצר', ''),
};


const singlefySlash = pathName => pathName.split("/").filter(s => s).join("/");


const tourismProductType = {
  label: 'הכל',
  searchName: '',
  types: [productTypeClassification(eProductCategoryType.TOURISM)],
  url: '',
  query: {
    $eager: [ticketDetails, hotelDetails, voucherDetails],
  },
  showResultsInSubCategory: true,
};
const shoppingProductType = {
  label: 'הכל',
  types: [productTypeClassification(eProductCategoryType.SHOPPING)],
  url: '',
  query: {
    $eager: [voucherDetails],
  },
  showResultsInSubCategory: true,
};
export const homeProductType = {
  ...tourismProductType,
  types: uniq([...tourismProductType.types, ...shoppingProductType.types]),
  query: {
    $eager: uniq([...tourismProductType.query.$eager, ...shoppingProductType.query.$eager]),
  },
};

export const productTypes: IProductTypes[] = [
  tourismProductType,
  {
    label: 'טיסות',
    searchName: 'חברת תעופה',
    types: productTypeClassifications(eProductCategoryType.TOURISM, eProductSubCategoryType.FLIGHT),
    url: eProductTags.FLIGHTS,
    query: {
      $eager: [ticketDetails],
    },
  },
  {
    label: 'מלונות',
    searchName: 'בית מלון',
    types: productTypeClassifications(eProductCategoryType.TOURISM, eProductSubCategoryType.HOTEL),
    tags: [eProductTags.HOTEL],
    url: eProductTags.HOTELS,
    query: {
      $eager: [hotelDetails],
    },
  },
  {
    label: 'הפלגות',
    searchName: 'חברת הפלגות',
    types: productTypeClassifications(eProductCategoryType.TOURISM, eProductSubCategoryType.CRUISE),
    url: eProductTags.CRUISE,
    query: {
      $eager: [voucherDetails],
    },
  },
  {
    label: 'חבילות',
    searchName: 'סוכנות נסיעות',
    types: productTypeClassifications(eProductCategoryType.TOURISM, eProductSubCategoryType.VACATION),
    url: eProductTags.VACATION,
    query: {
      $eager: [ticketDetails, hotelDetails],
    },
  },
  shoppingProductType,
  {
    label: 'חוויות',
    searchName: 'חוויה',
    types: productTypeClassifications(eProductCategoryType.SHOPPING, eProductSubCategoryType.experience, eProductSubCategoryType.experiences),
    url: eProductTags.EXPERIENCES,
    query: {
      $eager: [voucherDetails],
    },
  },
  {
    label: 'חנויות',
    searchName: 'חנות',
    types: productTypeClassifications(eProductCategoryType.SHOPPING, eProductSubCategoryType.shop, eProductSubCategoryType.shops),
    url: eProductTags.SHOPS,
    query: {
      $eager: [voucherDetails],
    },
  },
  {
    label: 'מסעדות',
    searchName: 'מסעדה',
    types: productTypeClassifications(eProductCategoryType.SHOPPING, eProductSubCategoryType.restaurant, eProductSubCategoryType.restaurants),
    url: eProductTags.RESTAURANTS,
    query: {
      $eager: [voucherDetails],
    },
  },
  {
    label: 'מולטי',
    searchName: 'מולטי',
    types: productTypeClassifications(eProductCategoryType.SHOPPING, eProductSubCategoryType.multi),
    url: eProductTags.MULTI,
    query: {
      $eager: [voucherDetails],
    },
  },
]
  .map(p => ({
    ...p,
    query: {
      ...p.query,
      // $or: p.types.map(t => pick(t, ['type', 'subType'])),
      $or: p.types
        .map(t => pick(t, ['type', 'subType']))
        .map(t => ({
          'typeAssociationDetails:typeDetails.type': t.type,
          'typeAssociationDetails:typeDetails.subType': t.subType
        })),
    },
  }));

const pathToPattern = {
  [linksMap.home]: new UrlPattern(linksMap.homeTypes),
  [linksMap.tourism]: new UrlPattern(linksMap.tourismTypes),
  [linksMap.shopping]: new UrlPattern(linksMap.shoppingTypes),
  [linksMap.sport]: new UrlPattern(linksMap.sportTypes),
};
export const getProductTypePattern = (pathname = location.pathname, pathKey = linksMap.home) => {
  return (pathToPattern[pathKey].match(pathname) || {}).type;
};


export const allPossibleUrls = uniq([...productTypes.map(p => p.url), ...Object.values(linksMap)]
  .filter(Boolean)
  .map(url => url.startsWith("/") ? url : `/${url}`));
