import React from "react";
import {MDBNavItem} from 'mdbreact';

import {Divider} from '@material-ui/core';
import LinkBlockContainer from 'data/redux/containers/linkBlockContainer/LinkBlockContainer';
import './NavigateLink.css';
import {removeAssociatedStateFromPath} from 'data/redux/actions/productsActions';
import {ILinkBlockElementProps} from 'gui/components/linkBlock';

export interface INavigateLinkProps extends ILinkBlockElementProps {
  border?: boolean;
  className?: string;
  currentPathName?: string;
  onMenuSelect?: any;
  allowed?: boolean;

  activePaths?: string[];
}

const isActive = (path, props) => props.currentPathName ? props.currentPathName === (removeAssociatedStateFromPath(path) || '').toLowerCase() : false;
const isActivePaths = (props, ...paths) => paths.filter(path => isActive(path, props)).length > 0;
const isActiveCheck = (props) => {
  if (Array.isArray(props.activePaths)) {
    return isActivePaths(props, ...props.activePaths);
  }
  return isActive(props.to, props);
}
const NavigateLink = (props: INavigateLinkProps) => {
  const {border, currentPathName, ...remainingProps} = props;
  if (props.allowed === true) {
    return (
      <MDBNavItem
        active={isActiveCheck(props)}
        className={"navigateLink" + (props.className ? ` ${props.className}` : '')}
        onClick={(props.onMenuSelect && props.to) ? (() => props.onMenuSelect(props.to)) : undefined}
      >
        {border ? <Divider
            className="menuDivider"
            orientation="vertical"
          /> :
          <LinkBlockContainer {...remainingProps}/>}
      </MDBNavItem>
    );
  } else {
    return (null);
  }
};

NavigateLink.defaultProps = {
  border: false,
  className: null,
  currentPathName: null,
  allowed: true,
};

export default React.memo(NavigateLink);
