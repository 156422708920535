import {IProductTypeClassification, IProductTypes} from "../interfaces/products";
import {eProductSubCategoryType, eProductCategoryType} from "../enums/products";
import {xxHash} from "./calculators";
import {productTypes} from "./constants";

export const productTypeClassification = (productType?: eProductCategoryType, productSubType?: eProductSubCategoryType): IProductTypeClassification => {
  return {
    ...(productType ? {type: productType} : {}),
    ...(productSubType ? {subType: productSubType} : {}),
  };
};

export const productTypeClassifications = (productType?: eProductCategoryType, ...productSubType: eProductSubCategoryType[]): IProductTypeClassification[] => {
  return [productSubType]
    .flat()
    .map(subType => productTypeClassification(productType, subType));
};

export const productTypeClassificationsKey = (productType?: eProductCategoryType, ...productSubType: eProductSubCategoryType[]) => {
  return JSON.stringify(productTypeClassifications(productType, ...productSubType));
};

const getSortedProductTypeTypes = (productType: IProductTypes) => {
  return (productType.types || [])
    .sort((a, b) => (a.type - b.type || a.subType - b.subType));
};

export const productTypeKey = (productType: IProductTypes) => {
  return xxHash(JSON.stringify(getSortedProductTypeTypes(productType)));
};
/**
 * filter type list that match type and subtype
 * @param productTypes
 * @param product_category_type
 * @param productSubType
 */
export const getProductTypesWithClassification = (productTypes: IProductTypes[], product_category_type: eProductCategoryType | eProductCategoryType[], ...productSubType: eProductSubCategoryType[]) => {
  const _productCategoryType: eProductCategoryType[] = [product_category_type].flat().filter(p => p);
  return productTypes
    .filter(({types}) => {
      return types
        .find(({type, subType}) => {
          if (_productCategoryType.includes(type)) {
            if (productSubType.length) {
              return productSubType.includes(subType);
            } else {
              return true;
            }
          } else if (productSubType.length) {
            return productSubType.includes(subType);
          } else {
            return false;
          }
        })
    });
};
/**
 * Append starting url to all urls in array
 * @param productTypes
 * @param startingUrl
 */
export const getProductTypeWithStartingUrl = (productTypes: IProductTypes[], startingUrl: string) =>
  productTypes
    .map(({url, ...rest}) => ({url: [startingUrl, url].filter(url => url && url.length).join('/'), ...rest}));

export const getProductTypeByPathName = (productTypes: IProductTypes[], pathName: string = location.pathname) => {
  return productTypes.find(productType => productType.url === pathName);
};

const isSameProductTypeClassificationInSameCategory = (productTypeClassification1: IProductTypeClassification, productTypeClassification2: IProductTypeClassification,) => {
  return productTypeClassification1.type === productTypeClassification2.type;
};

const isSameProductTypeClassification = (productTypeClassification1: IProductTypeClassification, productTypeClassification2: IProductTypeClassification,) => {
  return isSameProductTypeClassificationInSameCategory(productTypeClassification1, productTypeClassification2)
    &&
    productTypeClassification1.subType === productTypeClassification2.subType;
};

export const isSameProductType = (productType1: IProductTypes, productType2: IProductTypes) => {
  return (productType1.types || [])
    .filter(t1 => (productType2.types || []).find(t2 => isSameProductTypeClassification(t1, t2)))
    .length === (productType1.types || []).length;
};

export const isSameProductTypeCategory = (productType1: IProductTypes, productType2: IProductTypes) => {
  return (productType1.types || [])
    .filter(t1 => (productType2.types || []).find(t2 => isSameProductTypeClassificationInSameCategory(t1, t2)))
    .length === (productType1.types || []).length;
};


export const isProductClassificationInProductType = (productType: IProductTypes, ...productClassification: IProductTypeClassification[]) => {
  return (productType.types || [])
    .filter(t1 => (productClassification || []).find(t2 => isSameProductTypeClassification(t1, t2)))
    .length > 0;
};

export const generateProductCategoryList = (categoryProductType: eProductCategoryType | eProductCategoryType[], categoryStartingUrl = '/') => {
  const productTypesOfCategoryType = getProductTypesWithClassification(productTypes, categoryProductType);
  return getProductTypeWithStartingUrl(productTypesOfCategoryType, categoryStartingUrl);
};
