import {connect} from 'react-redux'
import {Avatar} from '@material-ui/core';
import {
  profileEmailSelector,
  profileFirstLastNameSelector,
  profilePictureSelector
} from 'data/redux/selectors/userSelector';

const mapStateToProps = (state) => {
  const profilePicture = profilePictureSelector(state);
  const firstLastName = profileFirstLastNameSelector(state);
  return {
    src: profilePicture,
    alt: firstLastName,
    title: firstLastName,
    children: profilePicture ? undefined : (profileEmailSelector(state) || ' ').slice(0, 1),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const AvatarContainer = connect(mapStateToProps, mapDispatchToProps)(Avatar);

export default AvatarContainer;
