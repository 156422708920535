import React, {Suspense} from "react";
import {Route, Switch} from "react-router";
import {linksMap} from 'data/utils/constants';
import {lazyWithPreloadToPath} from 'data/utils/shortcuts';
import {Redirect} from "react-router-dom";

/** Static import old way
 import CreateProductContainer from 'data/redux/containers/createProductContainer';
 import About from "../../pages/about/About";
 import EditProductContainer from 'data/redux/containers/editProductContainer';
 import ControlRouter from '../../../controlRouter/ControlRouter';
 import Home from "gui/pages/mainRouter/pages/home/Home";
 import SignInContainer from "data/redux/containers/signInContainer/SignInContainer";
 import SignUpContainer from "data/redux/containers/signUpContainer/SignUpContainer";
 import ForgotPasswordContainer from 'data/redux/containers/forgotPasswordContainer/ForgotPasswordContainer';
 import AdContainer from 'data/redux/containers/adContainer/AdContainer';
 import Terms from 'gui/pages/mainRouter/pages/terms/Terms';
 import Contact from "gui/pages/mainRouter/pages/contact/Contact";
 import AdminRouter from '../../../adminRouter';
 import Faq from "../../pages/faq/Faq";
 import HowItWorks from "../../pages/howItWorks/HowItWorks";
 import VerifyTokenContainer from "data/redux/containers/verifyTokenContainer/VerifyTokenContainer";
 import Tourism from 'gui/pages/mainRouter/pages/tourism/Tourism';
 */

const CreateProductContainer = lazyWithPreloadToPath(linksMap.control, () => import(/* webpackChunkName: "CreateProductContainer" , webpackMode:'lazy' */ "data/redux/containers/createProductContainer"));
const About = lazyWithPreloadToPath(linksMap.control, () => import(/* webpackChunkName: "ControlRouter" , About:'lazy' */ "../../pages/about/About"));
// const EditProductContainer = lazyWithPreloadToPath(linksMap.control, () => import(/* webpackChunkName: "EditProductContainer" , webpackMode:'lazy' */ "data/redux/containers/editProductContainer"));

const ControlRouter = lazyWithPreloadToPath(linksMap.control, () => import(/* webpackChunkName: "ControlRouter" , webpackMode:'lazy' */ "../../../controlRouter/ControlRouter"));
const Home = lazyWithPreloadToPath(linksMap.home, () => import(/* webpackChunkName: "Home" , webpackMode:'lazy' */ "gui/pages/mainRouter/pages/home/Home"));
const SignInContainer = lazyWithPreloadToPath(linksMap.login, () => import(/* webpackChunkName: "SignInContainer" , webpackMode:'lazy' */ "data/redux/containers/signInContainer/SignInContainer"));
const SignUpContainer = lazyWithPreloadToPath(linksMap.register, () => import(/* webpackChunkName: "SignUpContainer" , webpackMode:'lazy' */ "data/redux/containers/signUpContainer/SignUpContainer"));
const ForgotPasswordContainer = lazyWithPreloadToPath(linksMap.forgotPassword, () => import(/* webpackChunkName: "ForgotPasswordContainer" , webpackMode:'lazy' */ "data/redux/containers/forgotPasswordContainer/ForgotPasswordContainer"));
const VerifyTokenContainer = lazyWithPreloadToPath(linksMap.verifyToken, () => import(/* webpackChunkName: "VerifyTokenContainer" , webpackMode:'lazy' */ "data/redux/containers/verifyTokenContainer/VerifyTokenContainer"));
const VoucherContainer = lazyWithPreloadToPath(linksMap.adIdSlug, () => import(/* webpackChunkName: "VoucherContainer" , webpackMode:'lazy' */ "data/redux/containers/voucherContainer"));
const AdContainer = lazyWithPreloadToPath(linksMap.adIdSlug, () => import(/* webpackChunkName: "AdContainer" , webpackMode:'lazy' */ "data/redux/containers/adContainer/AdContainer"),
  {
    paths: [linksMap.adIdSlug, linksMap.adEditIdSlug],
  }
);
const Terms = lazyWithPreloadToPath(linksMap.terms, () => import(/* webpackChunkName: "Terms" , webpackMode:'lazy' */ "gui/pages/mainRouter/pages/terms/Terms"));
const Accessibility = lazyWithPreloadToPath(linksMap.accessibility, () => import(/* webpackChunkName: "Accessibility" , webpackMode:'lazy' */ "gui/pages/mainRouter/pages/accessibility/Accessibility"));
const PrivacyPolicy = lazyWithPreloadToPath(linksMap.privacyPolicy, () => import(/* webpackChunkName: "PrivacyPolicy" , webpackMode:'lazy' */ "gui/pages/mainRouter/pages/privacypolicy/PrivacyPolicy"));
const Contact = lazyWithPreloadToPath(linksMap.contactUs, () => import(/* webpackChunkName: "Contact" , webpackMode:'lazy' */ "gui/pages/mainRouter/pages/contact/Contact"));
const AdminRouter = lazyWithPreloadToPath(linksMap.admin, () => import(/* webpackChunkName: "AdminRouter" , webpackMode:'lazy' */ "../../../adminRouter"));
const HowItWorks = lazyWithPreloadToPath(linksMap.howItWorks, () => import(/* webpackChunkName: "HowItWorks" , webpackMode:'lazy' */ "../../pages/howItWorks/HowItWorks"));
const Faq: any = lazyWithPreloadToPath(linksMap.faq, () => import(/* webpackChunkName: "Faq" , webpackMode:'lazy' */ '../../pages/faq/Faq'));
const Tourism = lazyWithPreloadToPath(linksMap.tourism, () => import(/* webpackChunkName: "Tourism" , webpackMode:'lazy' */ 'gui/pages/mainRouter/pages/tourism/Tourism'));
const Shopping = lazyWithPreloadToPath(linksMap.shopping, () => import(/* webpackChunkName: "Experiences" , webpackMode:'lazy' */ 'gui/pages/mainRouter/pages/shopping'));


interface IMainProps {
  pathName: string;
  signedIn?: boolean;
}

const Main = (props: IMainProps) => {
  return (
    <main data-page={props.pathName}
          className={`mainContainer ${props.signedIn ? "signedIn" : "signedOut"}`.trim()}
    >
      <Suspense fallback={null}>
        <Switch>
          <Route path={[linksMap.tourism, linksMap.tourismTypes]}>
            <Tourism/>
          </Route>
          <Route path={[linksMap.experiences, linksMap.experienceTypes]}>
            <Shopping/>
          </Route>
          <Route path={linksMap.login}>
            <SignInContainer/>
          </Route>
          <Route path={linksMap.forgotPassword}>
            <ForgotPasswordContainer/>
          </Route>
          <Route path={linksMap.verifyToken}>
            <VerifyTokenContainer/>
          </Route>
          <Route path={linksMap.register}>
            <SignUpContainer/>
          </Route>
          <Route path={linksMap.createProduct}>
            <CreateProductContainer/>
          </Route>
          <Route path={linksMap.control}>
            <ControlRouter/>
          </Route>
          <Route path={linksMap.admin}>
            <AdminRouter/>
          </Route>
          <Route path={linksMap.adIdSlug}>
            <AdContainer/>
          </Route>
          <Route path={linksMap.voucherId}>
            <VoucherContainer/>
          </Route>
          <Route path={linksMap.adEditIdSlug}>
            <AdContainer/>
          </Route>
          <Route path={linksMap.terms}>
            <Terms/>
          </Route>
          <Route path={linksMap.accessibility}>
            <Accessibility/>
          </Route>
          <Route path={linksMap.privacyPolicy}>
            <PrivacyPolicy/>
          </Route>
          <Route path={[linksMap.howItWorks, linksMap.howItWorksBuyer, linksMap.howItWorksSeller]}>
            <HowItWorks/>
          </Route>
          <Route path={linksMap.contactUs}>
            <Contact/>
          </Route>
          <Route path={linksMap.faq}>
            <Faq/>
          </Route>
          <Route path={linksMap.about}>
            <About/>
          </Route>
          <Route path={linksMap.home}>
            <Home/>
          </Route>
          <Route render={() => <Redirect to="/"/>}/>
        </Switch>
      </Suspense>
    </main>
  );
};

Main.defaultProps = {
  pathName: "",
  signedIn: false,
};

export default React.memo(Main);
