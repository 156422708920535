import {createReducer} from 'redux-starter-kit';
import {
  addPathToHistory,
  collapseToggle,
  toggleNavigation,
  toggleVacationTypeTab
} from 'data/redux/actions/navigationActions';
import {LOCATION_POP} from 'redux-location-state/lib/constants';
import {LOCATION_CHANGE} from "connected-react-router";

const initialNavigationReducerState = {
  menuCollapseToggle: false,
  lastChange: 0,
  activeVacationTypeTab: 1,
  allowNavigation: true,
  history: [],
};

const locationChange = (state, {payload}) => {
  state.history.push({
    pathname: `${payload.location.pathname}`,
    search: payload.location.search,
    timeStamp: new Date().getTime(),
  });
  if (state.history.length >= 10) {
    state.history.shift();
  }
};

const navigationReducer = createReducer(initialNavigationReducerState, {

  [collapseToggle.type]: (state, {payload}) => {
    state.menuCollapseToggle = (payload.open !== undefined && payload.open !== null) ? payload.open : !state.menuCollapseToggle;
  },
  [LOCATION_POP]: (state, action) => {
    state.lastChange = new Date().getTime();
    state.menuCollapseToggle = false;
    state.allowNavigation = true;
  },
  // [LOCATION_PUSH]: (state, action) => {
  //   state.lastChange = new Date().getTime();
  //   state.menuCollapseToggle = false;
  //   state.allowNavigation = true;
  // },
  [toggleNavigation.type]: (state, {payload}) => {
    state.allowNavigation = payload.allow;
  },
  [toggleVacationTypeTab.type]: (state, action) => {
    state.activeVacationTypeTab = action.payload;
  },
  [LOCATION_CHANGE]: locationChange,
  [addPathToHistory.type]: locationChange,

});
export default navigationReducer;
