export enum eProductTags {
  TOURISM = 'tourism',
  FLIGHT = 'flight',
  FLIGHTS = 'flights',
  HOTEL = 'hotel',
  HOTELS = 'hotels',
  VACATION = 'vacation',
  VACATIONS = 'vacations',
  VOUCHER = 'voucher',
  VOUCHERS = 'vouchers',
  CRUISE = 'cruise',
  AGENCY = 'agency',
  SHOPPING = 'shopping',
  RESTAURANT = 'restaurant',
  RESTAURANTS = 'restaurants',
  SHOP = 'shop',
  SHOPS = 'shops',
  EXPERIENCE = 'experience',
  EXPERIENCES = 'experiences',
  MULTI = 'multi',


}

export enum eProductCategoryType {
  TOURISM = 1,
  SHOPPING = 2,
  SPORT = 3,
}

export enum eProductSubCategoryType {
  FLIGHT = 1,
  HOTEL = 2,
  VACATION = 3,
  VOUCHER = 4,
  CRUISE = 5,
  AGENCY = 6,


  restaurant = 101,
  restaurants = 102,
  shop = 103,
  shops = 104,
  experience = 105,
  experiences = 106,
  multi = 111

}


export enum eCompanyClassification {
  books = 100,
  house = 101,
  electronics = 103,
  cellphones = 104,
  cafe = 105,
  art = 106,
  fashion = 107,
  groceries = 108,

  massage = 1000,
  movie = 1001,
  gym = 1002,
  pool = 1003,
  barber = 1004,
  nails = 1005,
  beauty = 1006,

}




export enum eVoucherTypes {
  none = 0,
  flightCompanies = eProductSubCategoryType.FLIGHT,
  agencies = eProductSubCategoryType.AGENCY,
  hotels = eProductSubCategoryType.HOTEL,
  cruise = eProductSubCategoryType.CRUISE,

  restaurant = eProductSubCategoryType.restaurant,
  restaurants = eProductSubCategoryType.restaurants,
  shop = eProductSubCategoryType.shop,
  shops = eProductSubCategoryType.shops,
  experience = eProductSubCategoryType.experience,
  experiences = eProductSubCategoryType.experiences,
  multi = eProductSubCategoryType.multi
};


export const voucherTypeToLabel = {
  [eVoucherTypes.flightCompanies]: "שובר לחברת תעופה",
  [eVoucherTypes.agencies]: "שובר לסוכנות נסיעות",
  [eVoucherTypes.hotels]: "שובר למלון",
  [eVoucherTypes.cruise]: "שובר לשייט",
  [eVoucherTypes.restaurant]: "שובר למסעדה",
  [eVoucherTypes.restaurants]: "שובר למגוון מסעדות",
  [eVoucherTypes.shop]: "שובר לחנות",
  [eVoucherTypes.shops]: "שובר למגוון חנויות",
  [eVoucherTypes.experience]: "שובר לחוויה",
  [eVoucherTypes.experiences]: "שובר למגוון חוויות",
  [eVoucherTypes.multi]: "מולטי שובר",
};
