import {createSelector} from 'redux-starter-kit';
import {linksMap} from 'data/utils/constants';
import {getLastPossibleNavigationExcept} from 'data/utils/shortcuts';

export const getPathName = (() => createSelector(["router.location.pathname"]))();
/**
 * Check if path name according to router is the same as the input
 * @param pathName
 */
export const isPathName = (pathName: string) => (() => createSelector(["router.location.pathname"], currentPathName => {
  if (currentPathName) {
    currentPathName = currentPathName.toLowerCase().split("/").join('').trim();
    pathName = pathName.toLowerCase().split("/").join('').trim();
    return currentPathName === pathName;
  }
  return false;
}))();

export const getAdToLoadType = (() => createSelector(["router.location.pathname"], (pathname) => {
  if (pathname) {
    pathname = pathname.split("/");
    return pathname[pathname.length - 1];
  }
  return "";
}))();

export const getSluglessPath = (() => createSelector(["router.location.pathname"], (pathname) => {
  pathname = pathname.split("/").filter(s => s);
  const indexOfLastNumber = pathname.findIndex(s => !isNaN(Number(s)));
  return `/${pathname.slice(0, indexOfLastNumber + 1).join("/")}`;
}))();

export const getSlug = (() => createSelector(["router.location.pathname"], (pathname) => {
  pathname = pathname.split("/").filter(s => s);
  return pathname.length > 2 ? pathname[pathname.length - 1] : "";
}))();

export const getNavigationAllowed = (() => createSelector(["navigation.allowNavigation"]))();
const checkAgainst = [linksMap.forgotPassword, linksMap.verifyToken, linksMap.login, linksMap.logout, linksMap.register].map(m => m.toLowerCase());
export const getLastPossibleNavigationLoggedIn = (() => createSelector(["navigation.history", "router.location.query"], (history, query) => {
  if (query.code && query.state) {
    return getLastPossibleNavigationExcept([...history, {
      pathname: decodeURIComponent(query.state),
      timeStamp: new Date().getTime()
    }], ...checkAgainst) || linksMap.dashboard;
  }
  return getLastPossibleNavigationExcept(history, ...checkAgainst) || linksMap.dashboard;
}))();


export const getQueryParamsSelector = ((parseValues = query => query) => createSelector(["router.location.query"], parseValues))();


export const getQueryParamSelector = (param) => (() => createSelector([`router.location.query.${param}`]))();

export const getHashParamSelector = (param) => (() => createSelector([`router.location.hash`], hash => {
  if (hash && hash.includes(`${param}=`)) {
    try {
      hash = hash.trim();
      while (hash.startsWith('#')) {
        hash = hash.slice(1);
      }
      const variables = hash.split('&');
      for (const variable of variables) {
        const variableSplit = variable.split('=');
        const key = variableSplit[0];
        const value = variableSplit[1];
        if (key === param) {
          return value;
        }
      }
    } catch (e) {
    }
  }
  return hash;
}))();

export const getQueryOrHashParamSelector = (param) => state => {
  return getQueryParamSelector(param)(state) || getHashParamSelector(param)(state);
};


export const getSearchTextSelector = (() => createSelector([`router.location.query.search`], searchText => searchText ? decodeURIComponent(searchText).trim() : ''))();
